import {env, IS_LOCALHOST_ENV} from '@shared/env_constants';
import {FrontendDomainConfig} from '@shared/frontends/frontend_constant';
import {splitOnce, splitOnceOrThrow} from '@shared/lib/array_utils';
import {asNumberOrThrow} from '@shared/lib/type_utils';
import {EnvType, LocalEnvConstants} from '@shared/model/env_constants';

interface BuildUrlOptions {
  protocol?: string;
  subdomain?: string;
  domain: string;
  port?: number;
}

export function hostToUrl(host: string): URL {
  const url = host.startsWith('//')
    ? `https:${host}`
    : host.startsWith('http://') || host.startsWith('https://')
      ? host
      : `https://${host}`;
  return new URL(url);
}

export function buildUrl({protocol, subdomain, domain, port}: BuildUrlOptions): string {
  return `${protocol}://${subdomain !== undefined ? `${subdomain}.` : ''}${domain}${
    port !== undefined ? `:${port}` : ''
  }`;
}

export function extractUrl(url: string): {protocol: string; host: string; port?: number} {
  const [protocol, hostWithPort] = splitOnceOrThrow(url, '://');
  const [host, port] = splitOnce(hostWithPort, ':');
  return {protocol, host, port: port === undefined ? undefined : asNumberOrThrow(port)};
}

export function getFrontendProtocol(isPrivate?: boolean, isLocalhostOverride?: boolean): string {
  return isPrivate || (isLocalhostOverride ?? IS_LOCALHOST_ENV) ? 'http' : 'https';
}

interface BuildFrontendHostOptions {
  domains: Record<EnvType, FrontendDomainConfig>;
  redirectToWww: boolean;
  isPrivate: boolean;
}

export function buildFrontendHost({
  domains,
  redirectToWww,
  isPrivate,
}: BuildFrontendHostOptions): string {
  return buildFrontendHostWithSubdomain({
    domains,
    subdomain: redirectToWww ? 'www' : undefined,
    isPrivate,
  });
}

interface BuildFrontendHostWithSubdomainOptions {
  domains: Record<EnvType, FrontendDomainConfig>;
  subdomain: string | undefined;
  isPrivate: boolean;
}

export function buildFrontendHostWithSubdomain({
  domains,
  subdomain,
  isPrivate,
}: BuildFrontendHostWithSubdomainOptions): string {
  const port = IS_LOCALHOST_ENV ? (env as LocalEnvConstants).devPort : undefined;
  const firstDomain = domains[env.type].domains[0] ?? 'no-domain';
  const protocol = getFrontendProtocol(isPrivate);
  return buildUrl({protocol, subdomain, domain: firstDomain, port});
}

export function buildHost(opts: {domain: string; subdomain?: string; isPrivate?: boolean}): string {
  const {domain, subdomain, isPrivate} = opts;
  const port = IS_LOCALHOST_ENV ? (env as LocalEnvConstants).devPort : undefined;
  const protocol = getFrontendProtocol(isPrivate);
  return buildUrl({protocol, subdomain, domain, port});
}
