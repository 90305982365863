import {FC, MouseEventHandler, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {removeUndefined} from '@shared/lib/type_utils';

import {Button} from '@shared-frontend/components/core/button';

import {useDataStore} from '@src/components/store';

interface CivPrefixFormProps {
  prefix: string;
  onChange: (prefix: string) => void;
}

export const CivPrefixForm: FC<CivPrefixFormProps> = ({prefix, onChange}) => {
  const civs = useDataStore().civs.useData();

  const visibleCivs = useMemo(
    () => civs.filter(c => c.name.toUpperCase().startsWith(prefix.toUpperCase())),
    [civs, prefix]
  );

  const pickableLetters = useMemo(() => {
    const letters = new Set<string | undefined>();
    for (const civ of visibleCivs) {
      letters.add(civ.name.toUpperCase()[prefix.length]);
    }
    return removeUndefined([...letters.values()]);
  }, [prefix.length, visibleCivs]);

  const handleLetterClick = useCallback<MouseEventHandler>(
    e => onChange(prefix + (e.currentTarget.getAttribute('data-letter') ?? '')),
    [onChange, prefix]
  );

  const handleDeleteLetter = useCallback(() => {
    onChange(prefix.slice(0, -1));
  }, [onChange, prefix]);

  return (
    <Wrapper>
      <PrefixLetters>
        {[...prefix].map((letter, i) => (
          <PrefixLetter key={i}>{letter}</PrefixLetter>
        ))}
      </PrefixLetters>
      <PickableLetters>
        {pickableLetters.map(letter => (
          <PickableLetter key={letter} data-letter={letter} onClick={handleLetterClick}>
            {letter}
          </PickableLetter>
        ))}
        {pickableLetters.length > 0 && (
          <DeleteLetterButton onClick={handleDeleteLetter}>DEL</DeleteLetterButton>
        )}
      </PickableLetters>
    </Wrapper>
  );
};

CivPrefixForm.displayName = 'CivPrefixForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PrefixLetters = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  background-color: #ffffff11;
  border-radius: 8px;
  padding: 0 4vmin;
`;

const PrefixLetter = styled.div`
  width: 3.5vmin;
  height: 10vmin;
  font-size: 6vmin;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PickableLetters = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10vmin, 1fr));
  gap: 4px;
`;

const PickableLetter = styled(Button)`
  width: 100%;
  padding: 1vmin 0;
  font-size: 6vmin;
  font-weight: 500;
`;

const DeleteLetterButton = styled(PickableLetter)`
  grid-column: span 2;
  background-color: #675151ba;
`;
