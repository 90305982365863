import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import styled from 'styled-components';

import {
  Aoe2Api,
  Aoe2Details,
  Aoe2DetailsSectionLine,
  Aoe2Image,
} from '@shared/api/definitions/public_api/aoe2_api';

import {apiCall} from '@shared-frontend/api';
import {notifyError} from '@shared-frontend/lib/notification';

import {LightText} from '@src/components/fragments';

function isImage(item: Aoe2DetailsSectionLine): item is Aoe2Image {
  return typeof item !== 'string';
}

export const DetailsPage: FC = () => {
  const navigate = useNavigate();
  const {encodedPath} = useParams();
  const [info, setInfo] = useState<Aoe2Details | undefined>();

  useEffect(() => {
    if (encodedPath === undefined || encodedPath.length === 0) {
      return;
    }
    apiCall(Aoe2Api, '/details', {path: decodeURIComponent(encodedPath)})
      .then(setInfo)
      .catch(notifyError);
  }, [encodedPath]);

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Wrapper>
      {info === undefined ? (
        <LightText>Loading...</LightText>
      ) : (
        <>
          <Title onClick={handleClose}>{info.title}</Title>
          {info.sections.map(section => (
            <Section key={section.title}>
              <SectionTitle>{section.title}</SectionTitle>
              {[...section.lines.entries()].map(([key, lines], index) => (
                <View key={key}>
                  <ContentLine $even={index % 2 === 0}>
                    <ContentLabel>{key}</ContentLabel>
                    <ContentValue>
                      {lines.map((line, lIndex) => (
                        <ContentValueLine key={String(lIndex)}>
                          {line.map((item, index) => {
                            if (isImage(item)) {
                              return (
                                <BeautifulImage
                                  key={key + item.src + String(index)}
                                  $width={item.width}
                                  $height={item.height}
                                  src={item.src}
                                />
                              );
                            }
                            return <Text key={key + item + String(index)}>{item}</Text>;
                          })}
                        </ContentValueLine>
                      ))}
                    </ContentValue>
                  </ContentLine>
                </View>
              ))}
            </Section>
          ))}
        </>
      )}
    </Wrapper>
  );
};
DetailsPage.displayName = 'DetailsPage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: calc(env(safe-area-inset-top) + 16px) 0 calc(env(safe-area-inset-bottom) + 8px) 0;
  overflow-y: scroll;
`;

const Section = styled.div``;

const SectionTitle = styled(LightText)`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 500;
  background-color: #ffffff33;
  padding: 4px 0;
`;

const Title = styled(LightText)`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContentLine = styled.div<{$even: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${p => (p.$even ? '#ffffff11' : '#ffffff22')};
  padding: 0 6px;
`;

const ContentLabel = styled(LightText)`
  margin-right: 10px;
  min-width: 143px;
  font-weight: 500;
  padding: 2px 0;
`;

const ContentValue = styled(LightText)`
  display: flex;
  flex-direction: column;
`;

const ContentValueLine = styled(LightText)`
  display: flex;
  align-items: center;
  color: #ffffffcc;
  gap: 5px;
  flex-wrap: wrap;
`;

const BeautifulImage = styled.img<{$width: number; $height: number}>`
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
`;

const View = styled.div``;
const Text = styled.span``;
