import {FC} from 'react';
import styled from 'styled-components';

import {Aoe2Civ} from '@shared/frontends/frontend_data_store';

import {NavLink} from '@shared-frontend/components/core/button';

import {LightText} from '@src/components/fragments';

interface CivViewerProps {
  civ: Aoe2Civ;
  onDismiss: () => void;
}

export const CivViewer: FC<CivViewerProps> = props => {
  const {civ, onDismiss} = props;
  const {name, imgUrl, specialty, uniqUnits, uniqTechs, uniqBuildings, bonuses, teamBonus} = civ;

  const handleBackPress = onDismiss;

  return (
    <Wrapper>
      <div onClick={handleBackPress}>
        <Header>
          <Icon src={imgUrl} />
          <Name>{name}</Name>
        </Header>
      </div>
      <Specialty>
        <Label>Specialty</Label>
        <Value>{specialty}</Value>
      </Specialty>
      <Bonuses>
        <Label>Bonuses</Label>
        {bonuses.map(bonus => (
          <Value key={bonus}>{bonus}</Value>
        ))}
        <Value>
          {teamBonus}
          <TeamBonus>team bonus</TeamBonus>
        </Value>
      </Bonuses>
      <UniqueTechs>
        <Label>Unique Techs</Label>
        {uniqTechs.map(({value, src, width, height}) => (
          <Value key={value}>
            {src === undefined || width === undefined || height === undefined ? (
              <></>
            ) : (
              <BeautifulImage src={src} $width={width} $height={height} />
            )}
            <Text>{value}</Text>
          </Value>
        ))}
      </UniqueTechs>
      <UniqueUnits>
        <Label>Unique Unit</Label>
        {uniqUnits.map(({value, link, src, width, height}) => (
          <Value key={value}>
            <NavLink to={`/details/${encodeURIComponent(link ?? '')}`}>
              {src === undefined || width === undefined || height === undefined ? (
                <></>
              ) : (
                <BeautifulImage src={src} $width={width} $height={height} />
              )}
              <Text>{value}</Text>
            </NavLink>
          </Value>
        ))}
      </UniqueUnits>
      <UniqueBuildings>
        <Label>Unique Buildings</Label>
        {uniqBuildings.map(({value, src, width, height}) => (
          <Value key={value}>
            {src === undefined || width === undefined || height === undefined ? (
              <></>
            ) : (
              <BeautifulImage src={src} $width={width} $height={height} />
            )}
            <Text>{value}</Text>
          </Value>
        ))}
      </UniqueBuildings>
    </Wrapper>
  );
};
CivViewer.displayName = 'CivViewer';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 8px;
`;
const Name = styled(LightText)`
  font-size: 24px;
`;

const LineText = styled(LightText)`
  padding: 2px 4px;
`;
const Label = styled(LineText)`
  font-weight: 500;
  background-color: #ffffff33;
`;
const Value = styled(LineText)`
  display: flex;
  align-items: baseline;
  background-color: #ffffff11;
  margin-top: 2px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const Specialty = styled(Section)`
  min-height: 75px;
`;
const UniqueUnits = styled(Section)`
  min-height: 142px;
`;
const UniqueTechs = styled(Section)`
  min-height: 220px;
`;
const UniqueBuildings = styled(Section)`
  min-height: 220px;
`;
const Bonuses = styled(Section)`
  min-height: 314px;
`;

const BeautifulImage = styled.img<{$width: number; $height: number}>`
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
  margin-right: 8px;
`;

const Text = styled.span``;

const TeamBonus = styled.div`
  padding: 2px 10px;
  background-color: #ffffff10;
  border-radius: 10000px;
  font-size: 12px;
  margin-left: 4px;
  flex-shrink: 0;
`;
