/* eslint-disable react/forbid-component-props, react/forbid-dom-props */
import {
  cloneElement,
  CSSProperties,
  JSX,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import {Custom, NULL_REF} from '@shared-frontend/lib/react';
import {ZINDEX} from '@shared-frontend/lib/zindex';

type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

interface TooltipProps {
  position?: TooltipPosition;
  // text?: string;
  content: ReactNode;
  maxWidth?: number;
  fontSize?: number;
  padding?: string;
  children: JSX.Element;
}

export const Tooltip: Custom<TooltipProps, 'div', 'content'> = ({
  position = 'top',
  content,
  maxWidth,
  fontSize,
  padding,
  children,
  ...rest
}) => {
  const [hidden, setHidden] = useState(true);
  const tooltipWrapperRef = useRef<HTMLDivElement>(NULL_REF);

  const handleMouseEnter = useCallback(() => {
    setHidden(false);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHidden(true);
  }, []);

  const ARROW_WIDTH = 8;
  const ARROW_HEIGHT = 4;
  const BACKGROUND_COLOR = '#333333dd';
  const TEXT_COLOR = '#ffffff';
  const TEXT_SIZE = 12;
  const BORDER_RADIUS = 3;
  const PADDING = '4px 8px';

  const [forceCompute, setForceCompute] = useState(Math.random());
  const handleResize = useCallback(() => setForceCompute(Math.random()), []);

  const {tooltipPosition, tooltipArrow} = useMemo(() => {
    let tooltipPosition: CSSProperties = {};
    let tooltipArrow: JSX.Element = <></>;

    const tooltipWrapper = tooltipWrapperRef.current;
    if (!tooltipWrapper || hidden) {
      return {tooltipPosition, tooltipArrow};
    }
    const viewport = {width: document.body.clientWidth, height: document.body.clientHeight};
    const tooltip = tooltipWrapper.children.item(0)?.getBoundingClientRect();
    const element = tooltipWrapper.children.item(1)?.getBoundingClientRect();

    if (tooltip && element) {
      // Compute how much space is available on all side of the element
      const topSpace = element.y;
      const bottomSpace = viewport.height - element.y - element.height;
      const leftSpace = element.x;
      const rightSpace = viewport.width - element.x - element.width;

      // Compute where the tooltip fits
      const fits: Record<TooltipPosition, boolean> = {
        top: topSpace - tooltip.height - ARROW_HEIGHT > 0,
        right: rightSpace - tooltip.width - ARROW_WIDTH > 0,
        bottom: bottomSpace - tooltip.height - ARROW_HEIGHT > 0,
        left: leftSpace - tooltip.width - ARROW_WIDTH > 0,
      };

      // Map between a preferred position and the list of all position ordered by most desirability
      const bestPositions: Record<TooltipPosition, TooltipPosition[]> = {
        top: ['top', 'bottom', 'right', 'left'],
        right: ['right', 'left', 'top', 'bottom'],
        bottom: ['bottom', 'top', 'right', 'left'],
        left: ['left', 'right', 'top', 'bottom'],
      };

      // Find the best position where the tooltip fits
      const bestPosition =
        bestPositions[position].reduce<TooltipPosition | undefined>(
          (agg, val) => agg ?? (fits[val] ? val : undefined),
          undefined
        ) ?? position;

      // Compute the position of the svg where we draw the arrow
      const tooltipArrowArea: CSSProperties = {};
      if (bestPosition === 'top') {
        tooltipArrowArea.width = tooltip.width;
        tooltipArrowArea.height = ARROW_HEIGHT;
        tooltipArrowArea.bottom = -ARROW_HEIGHT;
        tooltipArrowArea.left = 0;
      }
      if (bestPosition === 'right') {
        tooltipArrowArea.width = ARROW_HEIGHT;
        tooltipArrowArea.height = tooltip.height;
        tooltipArrowArea.top = 0;
        tooltipArrowArea.left = -ARROW_HEIGHT;
      }
      if (bestPosition === 'bottom') {
        tooltipArrowArea.width = tooltip.width;
        tooltipArrowArea.height = ARROW_HEIGHT;
        tooltipArrowArea.top = -ARROW_HEIGHT;
        tooltipArrowArea.left = 0;
      }
      if (bestPosition === 'left') {
        tooltipArrowArea.width = ARROW_HEIGHT;
        tooltipArrowArea.height = tooltip.height;
        tooltipArrowArea.top = 0;
        tooltipArrowArea.right = -ARROW_HEIGHT;
      }

      // Compute the transformation to place the arrow in the SVG.
      // The base arrow is drawn by default at the top left corner, facing down.
      let tooltipArrowTransforms: string[] = [];
      if (bestPosition === 'top') {
        tooltipArrowTransforms = [
          `translate(${(tooltip.width - ARROW_WIDTH) / 2}, 0)`,
          `rotate(0)`,
        ];
      }
      if (bestPosition === 'right') {
        tooltipArrowTransforms = [
          `translate(${ARROW_HEIGHT}, ${(tooltip.height - ARROW_WIDTH) / 2})`,
          `rotate(90)`,
        ];
      }
      if (bestPosition === 'bottom') {
        tooltipArrowTransforms = [
          `translate(${(tooltip.width + ARROW_WIDTH) / 2}, ${ARROW_HEIGHT})`,
          `rotate(180)`,
        ];
      }
      if (bestPosition === 'left') {
        tooltipArrowTransforms = [
          `translate(0, ${(tooltip.height + ARROW_WIDTH) / 2})`,
          `rotate(270)`,
        ];
      }

      // Compute the position of the tooltip itself
      if (bestPosition === 'top') {
        tooltipPosition = {
          top: -(tooltip.height + ARROW_HEIGHT),
          left: (element.width - tooltip.width) / 2,
        };
      }
      if (bestPosition === 'right') {
        tooltipPosition = {
          top: (element.height - tooltip.height) / 2,
          left: element.width + ARROW_HEIGHT,
        };
      }
      if (bestPosition === 'bottom') {
        tooltipPosition = {
          top: element.height + ARROW_HEIGHT,
          left: (element.width - tooltip.width) / 2,
        };
      }
      if (bestPosition === 'left') {
        tooltipPosition = {
          top: (element.height - tooltip.height) / 2,
          left: -(tooltip.width + ARROW_HEIGHT),
        };
      }

      // Rednering of the tooltip arrow SVG
      tooltipArrow = (
        <TooltipArrow style={{...tooltipArrowArea}}>
          <polygon
            style={{transformBox: 'fill-box', transformOrigin: '0 0'}}
            points={`0 0 ${ARROW_WIDTH / 2} ${ARROW_HEIGHT} ${ARROW_WIDTH} 0`}
            fill={BACKGROUND_COLOR}
            transform={tooltipArrowTransforms.join(' ')}
          />
        </TooltipArrow>
      );
    }
    return {tooltipPosition, tooltipArrow};

    // `children` needs to be included in the deps since we want to recompute the positions
    // if it changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden, position, children, forceCompute]);

  return (
    <TooltipWrapper ref={tooltipWrapperRef} {...rest}>
      <TooltipContent
        style={{
          ...tooltipPosition,
          backgroundColor: BACKGROUND_COLOR,
          fontSize: fontSize ?? TEXT_SIZE,
          color: TEXT_COLOR,
          borderRadius: BORDER_RADIUS,
          padding: padding ?? PADDING,
          opacity: hidden ? 0 : 1,
        }}
        $maxWidth={maxWidth}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onResize={handleResize}
      >
        <div>{content}</div>
        {tooltipArrow}
      </TooltipContent>
      {cloneElement(children, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
    </TooltipWrapper>
  );
};
Tooltip.displayName = 'Tooltip';

const TooltipWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: visible;
`;

const TooltipContent = styled.div<{$maxWidth?: number}>`
  position: absolute;
  top: -1000px;
  left: -1000px;
  transition-property: opacity;
  transition-duration: 120ms;
  transition-timing-function: ease-in-out;
  z-index: ${ZINDEX.Tooltip};
  width: max-content;
  ${p => (p.$maxWidth === undefined ? `white-space: nowrap;` : `max-width: ${p.$maxWidth}px;`)}
`;

const TooltipArrow = styled.svg`
  position: absolute;
`;
/* eslint-enable react/forbid-component-props, react/forbid-dom-props */
