import {FC, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {Aoe2Unit} from '@shared/frontends/frontend_data_store';
import {removeUndefined} from '@shared/lib/type_utils';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Tooltip} from '@shared-frontend/components/core/tooltip';

import {useDataStore} from '@src/components/store';

interface UnitsPageProps {}

type Aoe2UnitAndChildren = Aoe2Unit & {building: string; children: Aoe2UnitAndChildren[]};

const scoreGroup = (g: {building: string; units: Aoe2Unit[]}): number => {
  if (g.building.toLowerCase().includes('barrack')) {
    return 100 * 1000;
  } else if (g.building.toLowerCase().includes('archery')) {
    return 10 * 1000;
  } else if (g.building.toLowerCase().includes('stable')) {
    return 1000;
  } else if (g.building.toLowerCase().includes('siege')) {
    return 100;
  }
  return g.units.length;
};

export const UnitsPage: FC<UnitsPageProps> = () => {
  const units = useDataStore().units.useData();

  const unitLinesByBuilding = useMemo(() => {
    // Index units and track of their children
    const unitsByLabel = new Map<string, Aoe2UnitAndChildren>();
    for (const unitGroup of units) {
      for (const unit of unitGroup.units) {
        unitsByLabel.set(unit.label, {...unit, building: unitGroup.building, children: []});
      }
    }
    for (const unit of unitsByLabel.values()) {
      if (unit.parent !== undefined) {
        unitsByLabel.get(unit.parent)?.children.push(unit);
      }
    }

    // Sort the groups and keep the first units of each unit lines
    const sortedGroups = [...units].sort((g1, g2) => scoreGroup(g2) - scoreGroup(g1));
    return sortedGroups.map(({building, units}) => ({
      building,
      unitLines: removeUndefined(
        units.filter(u => u.parent === undefined).map(u => unitsByLabel.get(u.label))
      ),
    }));
  }, [units]);

  const generateUnitLineComponents = useCallback((unit: Aoe2UnitAndChildren) => {
    const hasExtra = unit.extra.length > 0;
    return (
      <UnitLine key={String(unit.label)}>
        <UnitWrapper key={unit.label} to={`/details/${encodeURIComponent(unit.url)}`}>
          {unit.images.map(img => (
            <UnitImage key={img} alt={unit.label} src={img} />
          ))}
          <UnitLabel data-url={unit.url} $light={hasExtra}>
            {unit.label}
          </UnitLabel>
          {hasExtra ? (
            <Tooltip content={unit.extra}>
              <Extra>?</Extra>
            </Tooltip>
          ) : (
            <></>
          )}
        </UnitWrapper>
        <UnitChildren>{unit.children.map(generateUnitLineComponents)}</UnitChildren>
      </UnitLine>
    );
  }, []);

  return (
    <Wrapper>
      {unitLinesByBuilding.map(({building, unitLines}) => (
        <Section key={building}>
          <Title>{building}</Title>
          <SectionUnits>{unitLines.map(generateUnitLineComponents)}</SectionUnits>
        </Section>
      ))}
    </Wrapper>
  );
};

UnitsPage.displayName = 'UnitsPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: env(safe-area-inset-top) 16px env(safe-area-inset-bottom) 16px;
  overflow-y: auto;
  background-color: ${p => p.theme.main.backgroundColor};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.div`
  font-size: 20px;
`;

const SectionUnits = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnitLine = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

const UnitWrapper = styled(UnthemedNavLink)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const UnitChildren = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnitImage = styled.img`
  flex-shrink: 0;
`;

const UnitLabel = styled.div<{$light: boolean}>`
  white-space: nowrap;
  color: ${p => (p.$light ? '#ffffffaa' : '#ffffff')};
`;

const Extra = styled.div`
  color: #ffffff66;
  cursor: pointer;
  padding: 0 4px;
`;
