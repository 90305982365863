// Generated by `build/lib/terraform/generator/dynamodb_model.ts`

import {PlaceGeometry} from '@shared/api/definitions/google_places_api';
import {DoNotUseData} from '@shared/dynamo_tables_for_tests';
import {
  ExpertTicketProductBasePayload,
  ExpertTicketProductPayload,
  ExpertTicketProviderPayload,
  ExpertTicketTicketPayload,
} from '@shared/lib/hoobiiz/hoobiiz_expert_ticket_db_models';
import {HoobiizPermissionName} from '@shared/lib/hoobiiz/hoobiiz_groups_and_users_permissions';
import {
  asArrayOrThrow,
  asBooleanOrThrow,
  asConstantOrThrow,
  asMapOrThrow,
  asNumberOrThrow,
  asStringOrThrow,
  Brand,
  errorAsString,
} from '@shared/lib/type_utils';

export type AccountId = Brand<string, 'AccountId'>;
export type DataLakeId = Brand<string, 'DataLakeId'>;
export type EmailString = Brand<string, 'EmailString'>;
export type FrontendUserId = Brand<string, 'FrontendUserId'>;
export type SessionDuration = Brand<number, 'SessionDuration'>;
export type StreamArn = Brand<string, 'StreamArn'>;
export type ShardId = Brand<string, 'ShardId'>;
export type SequenceNumber = Brand<string, 'SequenceNumber'>;
export type ServiceName = Brand<string, 'ServiceName'>;
export type ServiceNodeId = Brand<string, 'ServiceNodeId'>;
export type TargetString = Brand<string, 'TargetString'>;
export type BuildId = Brand<string, 'BuildId'>;
export type CommitHash = Brand<string, 'CommitHash'>;
export type DatalakeElementItemId = Brand<string, 'DatalakeElementItemId'>;
export type TaskId = Brand<string, 'TaskId'>;
export type ReportingId = Brand<string, 'ReportingId'>;
export type HoobiizOrderItemId = Brand<string, 'HoobiizOrderItemId'>;
export type InstanceRequestId = Brand<string, 'InstanceRequestId'>;
export type ParameterKey = Brand<string, 'ParameterKey'>;
export type InstanceTypeName = Brand<string, 'InstanceTypeName'>;
export type DomainName = Brand<string, 'DomainName'>;
export type DatalakeApiToken = Brand<string, 'DatalakeApiToken'>;
export type DashboardId = Brand<string, 'DashboardId'>;
export type ServiceAdArtisanId = Brand<string, 'ServiceAdArtisanId'>;
export type ServiceAdCustomerId = Brand<string, 'ServiceAdCustomerId'>;
export type ServiceAdRequestId = Brand<string, 'ServiceAdRequestId'>;
export type StripePaymentIntentId = Brand<string, 'StripePaymentIntentId'>;
export type HoobiizMediaId = Brand<string, 'HoobiizMediaId'>;
export type HoobiizCat1Id = Brand<string, 'HoobiizCat1Id'>;
export type HoobiizCat2Id = Brand<string, 'HoobiizCat2Id'>;
export type HoobiizCat3Id = Brand<string, 'HoobiizCat3Id'>;
export type HoobiizVendorId = Brand<string, 'HoobiizVendorId'>;
export type Markdown = Brand<string, 'Markdown'>;
export type HoobiizActivityId = Brand<string, 'HoobiizActivityId'>;
export type WebsiteUrl = Brand<string, 'WebsiteUrl'>;
export type HoobiizStockEntryId = Brand<string, 'HoobiizStockEntryId'>;
export type HoobiizStockId = Brand<string, 'HoobiizStockId'>;
export type HoobiizTicketFileId = Brand<string, 'HoobiizTicketFileId'>;
export type HoobiizStockBatchId = Brand<string, 'HoobiizStockBatchId'>;
export type HoobiizStockWeeklyTemplateId = Brand<string, 'HoobiizStockWeeklyTemplateId'>;
export type HoobiizExpertTicketStockId = Brand<string, 'HoobiizExpertTicketStockId'>;
export type HoobiizOfferId = Brand<string, 'HoobiizOfferId'>;
export type HoobiizCartItemId = Brand<string, 'HoobiizCartItemId'>;
export type HoobiizTicketInfoId = Brand<string, 'HoobiizTicketInfoId'>;
export type HoobiizTicketInfoOptionId = Brand<string, 'HoobiizTicketInfoOptionId'>;
export type HoobiizExpertTicketInfoId = Brand<string, 'HoobiizExpertTicketInfoId'>;
export type HoobiizExpertTicketProductId = Brand<string, 'HoobiizExpertTicketProductId'>;
export type HoobiizExpertTicketProviderId = Brand<string, 'HoobiizExpertTicketProviderId'>;
export type HoobiizExpertTicketProductBaseId = Brand<string, 'HoobiizExpertTicketProductBaseId'>;
export type HoobiizExpertTicketTicketId = Brand<string, 'HoobiizExpertTicketTicketId'>;
export type HoobiizExpertTicketMetadataKey = Brand<string, 'HoobiizExpertTicketMetadataKey'>;
export type HoobiizUserGroupId = Brand<string, 'HoobiizUserGroupId'>;
export type HoobiizRegistrationCode = Brand<string, 'HoobiizRegistrationCode'>;
export type DoNotUseId = Brand<string, 'DoNotUseId'>;

export enum DoNotUseType {
  Foo = 10,
  Bar = 20,
}

export const DoNotUseTypeDebugString: Record<DoNotUseType, string> = {
  [DoNotUseType.Foo]: 'Foo',
  [DoNotUseType.Bar]: 'Bar',
};

export enum BuildStatus {
  Created = 10,
  InProgress = 20,
  Success = 30,
  Failure = 40,
  Canceled = 50,
  Allocated = 60,
}

export const BuildStatusDebugString: Record<BuildStatus, string> = {
  [BuildStatus.Created]: 'Created',
  [BuildStatus.InProgress]: 'InProgress',
  [BuildStatus.Success]: 'Success',
  [BuildStatus.Failure]: 'Failure',
  [BuildStatus.Canceled]: 'Canceled',
  [BuildStatus.Allocated]: 'Allocated',
};

export enum ServiceAdCustomerStatus {
  PendingAccountValidation = 10,
  AccountValidated = 20,
}

export const ServiceAdCustomerStatusDebugString: Record<ServiceAdCustomerStatus, string> = {
  [ServiceAdCustomerStatus.PendingAccountValidation]: 'PendingAccountValidation',
  [ServiceAdCustomerStatus.AccountValidated]: 'AccountValidated',
};

export enum ServiceAdRequestStatus {
  PendingPhoneValidation = 10,
  PendingArtisanAcceptation = 20,
  ArtisanAccepted = 30,
  ArtisanDenied = 40,
}

export const ServiceAdRequestStatusDebugString: Record<ServiceAdRequestStatus, string> = {
  [ServiceAdRequestStatus.PendingPhoneValidation]: 'PendingPhoneValidation',
  [ServiceAdRequestStatus.PendingArtisanAcceptation]: 'PendingArtisanAcceptation',
  [ServiceAdRequestStatus.ArtisanAccepted]: 'ArtisanAccepted',
  [ServiceAdRequestStatus.ArtisanDenied]: 'ArtisanDenied',
};

export enum TaskStatus {
  Created = 10,
  Scheduled = 15,
  InFlight = 20,
  Success = 30,
  Error = 40,
  Interrupted = 50,
}

export const TaskStatusDebugString: Record<TaskStatus, string> = {
  [TaskStatus.Created]: 'Created',
  [TaskStatus.Scheduled]: 'Scheduled',
  [TaskStatus.InFlight]: 'InFlight',
  [TaskStatus.Success]: 'Success',
  [TaskStatus.Error]: 'Error',
  [TaskStatus.Interrupted]: 'Interrupted',
};

export enum TaskType {
  AnalyticsDedup = 10,
  AnalyticsAggregation = 20,
  Build = 30,
  DataLake = 40,
  QontoDataSource = 50,
  Reporting = 60,
  HoobiizTickets = 70,
  HoobiizMedia = 80,
  ExpertTicket = 90,
  HoobiizPdf = 100,
}

export const TaskTypeDebugString: Record<TaskType, string> = {
  [TaskType.AnalyticsDedup]: 'AnalyticsDedup',
  [TaskType.AnalyticsAggregation]: 'AnalyticsAggregation',
  [TaskType.Build]: 'Build',
  [TaskType.DataLake]: 'DataLake',
  [TaskType.QontoDataSource]: 'QontoDataSource',
  [TaskType.Reporting]: 'Reporting',
  [TaskType.HoobiizTickets]: 'HoobiizTickets',
  [TaskType.HoobiizMedia]: 'HoobiizMedia',
  [TaskType.ExpertTicket]: 'ExpertTicket',
  [TaskType.HoobiizPdf]: 'HoobiizPdf',
};

export enum RedisValueType {
  FrontendUserInvite = 15,
  FrontendUserSession = 25,
  FrontendMagicLink = 35,
  CaptchaToken = 40,
  RateLimiting = 50,
  FrontendUserEmailVerification = 60,
  FrontendUserResetPassword = 65,
}

export const RedisValueTypeDebugString: Record<RedisValueType, string> = {
  [RedisValueType.FrontendUserInvite]: 'FrontendUserInvite',
  [RedisValueType.FrontendUserSession]: 'FrontendUserSession',
  [RedisValueType.FrontendMagicLink]: 'FrontendMagicLink',
  [RedisValueType.CaptchaToken]: 'CaptchaToken',
  [RedisValueType.RateLimiting]: 'RateLimiting',
  [RedisValueType.FrontendUserEmailVerification]: 'FrontendUserEmailVerification',
  [RedisValueType.FrontendUserResetPassword]: 'FrontendUserResetPassword',
};

export enum InstanceRequestType {
  InstanceType = 10,
  MinVcpusMemory = 20,
  Termination = 30,
}

export const InstanceRequestTypeDebugString: Record<InstanceRequestType, string> = {
  [InstanceRequestType.InstanceType]: 'InstanceType',
  [InstanceRequestType.MinVcpusMemory]: 'MinVcpusMemory',
  [InstanceRequestType.Termination]: 'Termination',
};

export enum DatalakeElementTypeGroup {
  DataSource = 10,
  Transformation = 20,
  Aggregation = 30,
  Output = 40,
}

export const DatalakeElementTypeGroupDebugString: Record<DatalakeElementTypeGroup, string> = {
  [DatalakeElementTypeGroup.DataSource]: 'DataSource',
  [DatalakeElementTypeGroup.Transformation]: 'Transformation',
  [DatalakeElementTypeGroup.Aggregation]: 'Aggregation',
  [DatalakeElementTypeGroup.Output]: 'Output',
};

export enum DatalakeElementType {
  WebEventsDataSource = 10,
  FileDropDataSource = 20,
  QontoDataSource = 30,
  IpGeolocTransformation = 40,
  CalculationsTransformation = 50,
  ExtractStringTransformation = 60,
  SumsAggregation = 70,
  GraphOutput = 80,
  RestApiOutput = 90,
  LabelTransformation = 100,
}

export const DatalakeElementTypeDebugString: Record<DatalakeElementType, string> = {
  [DatalakeElementType.WebEventsDataSource]: 'WebEventsDataSource',
  [DatalakeElementType.FileDropDataSource]: 'FileDropDataSource',
  [DatalakeElementType.QontoDataSource]: 'QontoDataSource',
  [DatalakeElementType.IpGeolocTransformation]: 'IpGeolocTransformation',
  [DatalakeElementType.CalculationsTransformation]: 'CalculationsTransformation',
  [DatalakeElementType.ExtractStringTransformation]: 'ExtractStringTransformation',
  [DatalakeElementType.SumsAggregation]: 'SumsAggregation',
  [DatalakeElementType.GraphOutput]: 'GraphOutput',
  [DatalakeElementType.RestApiOutput]: 'RestApiOutput',
  [DatalakeElementType.LabelTransformation]: 'LabelTransformation',
};

export enum DatalakePartitionType {
  None = 10,
  Hourly = 20,
  Daily = 30,
}

export const DatalakePartitionTypeDebugString: Record<DatalakePartitionType, string> = {
  [DatalakePartitionType.None]: 'None',
  [DatalakePartitionType.Hourly]: 'Hourly',
  [DatalakePartitionType.Daily]: 'Daily',
};

export enum ReportingBreakdown {
  Minutely = 10,
  Hourly = 20,
}

export const ReportingBreakdownDebugString: Record<ReportingBreakdown, string> = {
  [ReportingBreakdown.Minutely]: 'Minutely',
  [ReportingBreakdown.Hourly]: 'Hourly',
};

export enum DatalakeOutputColumnType {
  String = 10,
  Number = 20,
  Boolean = 30,
}

export const DatalakeOutputColumnTypeDebugString: Record<DatalakeOutputColumnType, string> = {
  [DatalakeOutputColumnType.String]: 'String',
  [DatalakeOutputColumnType.Number]: 'Number',
  [DatalakeOutputColumnType.Boolean]: 'Boolean',
};

export enum DatalakeTimeBreakdown {
  Hourly = 10,
}

export const DatalakeTimeBreakdownDebugString: Record<DatalakeTimeBreakdown, string> = {
  [DatalakeTimeBreakdown.Hourly]: 'Hourly',
};

export enum DatalakeRestApiFormat {
  Json = 10,
  Csv = 20,
}

export const DatalakeRestApiFormatDebugString: Record<DatalakeRestApiFormat, string> = {
  [DatalakeRestApiFormat.Json]: 'Json',
  [DatalakeRestApiFormat.Csv]: 'Csv',
};

export enum DatalakeCalculationOperator {
  Multiply = 10,
}

export const DatalakeCalculationOperatorDebugString: Record<DatalakeCalculationOperator, string> = {
  [DatalakeCalculationOperator.Multiply]: 'Multiply',
};

export enum HoobiizCartItemType {
  Ticket = 10,
  ExpertTicket = 20,
}

export const HoobiizCartItemTypeDebugString: Record<HoobiizCartItemType, string> = {
  [HoobiizCartItemType.Ticket]: 'Ticket',
  [HoobiizCartItemType.ExpertTicket]: 'ExpertTicket',
};

export enum HoobiizOrderItemType {
  Ticket = 10,
  ExpertTicket = 20,
}

export const HoobiizOrderItemTypeDebugString: Record<HoobiizOrderItemType, string> = {
  [HoobiizOrderItemType.Ticket]: 'Ticket',
  [HoobiizOrderItemType.ExpertTicket]: 'ExpertTicket',
};

export enum HoobiizOrderStatus {
  Pending = 10,
  Canceled = 20,
  Success = 30,
  PendingConfirmation = 40,
  Rejected = 50,
}

export const HoobiizOrderStatusDebugString: Record<HoobiizOrderStatus, string> = {
  [HoobiizOrderStatus.Pending]: 'Pending',
  [HoobiizOrderStatus.Canceled]: 'Canceled',
  [HoobiizOrderStatus.Success]: 'Success',
  [HoobiizOrderStatus.PendingConfirmation]: 'PendingConfirmation',
  [HoobiizOrderStatus.Rejected]: 'Rejected',
};

export enum Currency {
  Euro = 'eur',
}

export const CurrencyDebugString: Record<Currency, string> = {
  [Currency.Euro]: 'Euro',
};

export enum HoobiizVisibility {
  Public = 'Public',
  Private = 'Private',
  PrivateHidden = 'PrivateHidden',
}

export const HoobiizVisibilityDebugString: Record<HoobiizVisibility, string> = {
  [HoobiizVisibility.Public]: 'Public',
  [HoobiizVisibility.Private]: 'Private',
  [HoobiizVisibility.PrivateHidden]: 'PrivateHidden',
};

export enum HoobiizWeekday {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export const HoobiizWeekdayDebugString: Record<HoobiizWeekday, string> = {
  [HoobiizWeekday.Monday]: 'Monday',
  [HoobiizWeekday.Tuesday]: 'Tuesday',
  [HoobiizWeekday.Wednesday]: 'Wednesday',
  [HoobiizWeekday.Thursday]: 'Thursday',
  [HoobiizWeekday.Friday]: 'Friday',
  [HoobiizWeekday.Saturday]: 'Saturday',
  [HoobiizWeekday.Sunday]: 'Sunday',
};

export enum HoobiizWhiteLabelingTheme {
  Youpiiz = 0,
  Cse = 1,
}

export const HoobiizWhiteLabelingThemeDebugString: Record<HoobiizWhiteLabelingTheme, string> = {
  [HoobiizWhiteLabelingTheme.Youpiiz]: 'Youpiiz',
  [HoobiizWhiteLabelingTheme.Cse]: 'Cse',
};

export enum FrontendName {
  Admin = 'admin',
  Onescale = 'www',
  Restaurant = 'restaurant',
  AgeOfEmpires = 'aoe2',
  Hoobiiz = 'hoobiiz',
}

export const FrontendNameDebugString: Record<FrontendName, string> = {
  [FrontendName.Admin]: 'Admin',
  [FrontendName.Onescale]: 'Onescale',
  [FrontendName.Restaurant]: 'Restaurant',
  [FrontendName.AgeOfEmpires]: 'AgeOfEmpires',
  [FrontendName.Hoobiiz]: 'Hoobiiz',
};

export enum HoobiizTicketFileOrigin {
  GeneratedByPdfTask = 'generated-by-pdf-task',
  UploadedByAdmin = 'uploaded-by-admin',
}

export const HoobiizTicketFileOriginDebugString: Record<HoobiizTicketFileOrigin, string> = {
  [HoobiizTicketFileOrigin.GeneratedByPdfTask]: 'GeneratedByPdfTask',
  [HoobiizTicketFileOrigin.UploadedByAdmin]: 'UploadedByAdmin',
};

export enum HoobiizStockEntryStatus {
  /**
   * The data behind this stock entry is not available yet and need to be
   * generated by the HoobiizPdfTask
   */
  AwaitingGeneration = 1,
  /**
   * The data behind this stock entry is not available yet and need to be
   * uploaded by an admin
   */
  AwaitingUpload = 2,
  /**
   * The data behind the stock entry is available to the end user
   */
  Ready = 3,
}

export const HoobiizStockEntryStatusDebugString: Record<HoobiizStockEntryStatus, string> = {
  [HoobiizStockEntryStatus.AwaitingGeneration]: 'AwaitingGeneration',
  [HoobiizStockEntryStatus.AwaitingUpload]: 'AwaitingUpload',
  [HoobiizStockEntryStatus.Ready]: 'Ready',
};

export enum FrontendUserAuthMethodType {
  MagicLink = 'MagicLink',
  MfaCode = 'MfaCode',
  Password = 'Password',
}

export const FrontendUserAuthMethodTypeDebugString: Record<FrontendUserAuthMethodType, string> = {
  [FrontendUserAuthMethodType.MagicLink]: 'MagicLink',
  [FrontendUserAuthMethodType.MfaCode]: 'MfaCode',
  [FrontendUserAuthMethodType.Password]: 'Password',
};

export enum HoobiizActivityAddressType {
  Vendor = 'Vendor',
  AtHome = 'AtHome',
  Website = 'Website',
  Manual = 'Manual',
}

export const HoobiizActivityAddressTypeDebugString: Record<HoobiizActivityAddressType, string> = {
  [HoobiizActivityAddressType.Vendor]: 'Vendor',
  [HoobiizActivityAddressType.AtHome]: 'AtHome',
  [HoobiizActivityAddressType.Website]: 'Website',
  [HoobiizActivityAddressType.Manual]: 'Manual',
};

export enum HoobiizStockEntryContentType {
  PdfFile = 'PdfFile',
}

export const HoobiizStockEntryContentTypeDebugString: Record<HoobiizStockEntryContentType, string> =
  {
    [HoobiizStockEntryContentType.PdfFile]: 'PdfFile',
  };

export enum HoobiizStockReservationType {
  /**
   * Tickets from the stock can be used at any time within a period.
   */
  Flexible = 'Flexible',
  /**
   * Tickets from the stock can only be used at a specific day and time.
   */
  Fixed = 'Fixed',
}

export const HoobiizStockReservationTypeDebugString: Record<HoobiizStockReservationType, string> = {
  [HoobiizStockReservationType.Flexible]: 'Flexible',
  [HoobiizStockReservationType.Fixed]: 'Fixed',
};

export enum HoobiizOfferTargetType {
  Global = 'Global',
  Vendor = 'Vendor',
  Activity = 'Activity',
}

export const HoobiizOfferTargetTypeDebugString: Record<HoobiizOfferTargetType, string> = {
  [HoobiizOfferTargetType.Global]: 'Global',
  [HoobiizOfferTargetType.Vendor]: 'Vendor',
  [HoobiizOfferTargetType.Activity]: 'Activity',
};

export enum HoobiizOfferFlashType {
  Disabled = 'Disabled',
  Period = 'Period',
}

export const HoobiizOfferFlashTypeDebugString: Record<HoobiizOfferFlashType, string> = {
  [HoobiizOfferFlashType.Disabled]: 'Disabled',
  [HoobiizOfferFlashType.Period]: 'Period',
};

export enum FrontendUserDataContentType {
  Onescale = 'Onescale',
  Hoobiiz = 'Hoobiiz',
}

export const FrontendUserDataContentTypeDebugString: Record<FrontendUserDataContentType, string> = {
  [FrontendUserDataContentType.Onescale]: 'Onescale',
  [FrontendUserDataContentType.Hoobiiz]: 'Hoobiiz',
};

export enum HoobiizDiscountType {
  Percent = 'Percent',
  Amount = 'Amount',
}

export const HoobiizDiscountTypeDebugString: Record<HoobiizDiscountType, string> = {
  [HoobiizDiscountType.Percent]: 'Percent',
  [HoobiizDiscountType.Amount]: 'Amount',
};

export enum HoobiizFlexibleStockScheduleType {
  Inherit = 'Inherit',
  Override = 'Override',
}

export const HoobiizFlexibleStockScheduleTypeDebugString: Record<
  HoobiizFlexibleStockScheduleType,
  string
> = {
  [HoobiizFlexibleStockScheduleType.Inherit]: 'Inherit',
  [HoobiizFlexibleStockScheduleType.Override]: 'Override',
};

export enum HoobiizStockModeType {
  /**
   * The ticket data was provided in the admin beforehand
   */
  Pregenerated = 'Pregenerated',
  /**
   * The ticket data is generated on the fly after the purchase
   */
  Automatic = 'Automatic',
  /**
   * The ticket data is generated on the fly once the vendor approve the
   * purchase
   */
  VendorConfirm = 'VendorConfirm',
  /**
   * The ticket data will be provided by the admin once they approve the
   * purchase
   */
  AdminConfirm = 'AdminConfirm',
}

export const HoobiizStockModeTypeDebugString: Record<HoobiizStockModeType, string> = {
  [HoobiizStockModeType.Pregenerated]: 'Pregenerated',
  [HoobiizStockModeType.Automatic]: 'Automatic',
  [HoobiizStockModeType.VendorConfirm]: 'VendorConfirm',
  [HoobiizStockModeType.AdminConfirm]: 'AdminConfirm',
};

export enum HoobiizWhiteLabelingAuthType {
  ModeDefault = 'ModeDefault',
  ModeHero = 'ModeHero',
}

export const HoobiizWhiteLabelingAuthTypeDebugString: Record<HoobiizWhiteLabelingAuthType, string> =
  {
    [HoobiizWhiteLabelingAuthType.ModeDefault]: 'ModeDefault',
    [HoobiizWhiteLabelingAuthType.ModeHero]: 'ModeHero',
  };

export function parseDoNotUseType(value: number): DoNotUseType {
  if (![DoNotUseType.Foo, DoNotUseType.Bar].includes(value as DoNotUseType)) {
    throw new Error(`Invalid value ${value} for enum DoNotUseType`);
  }
  return value as DoNotUseType;
}
export function parseBuildStatus(value: number): BuildStatus {
  if (
    ![
      BuildStatus.Created,
      BuildStatus.InProgress,
      BuildStatus.Success,
      BuildStatus.Failure,
      BuildStatus.Canceled,
      BuildStatus.Allocated,
    ].includes(value as BuildStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum BuildStatus`);
  }
  return value as BuildStatus;
}
export function parseServiceAdCustomerStatus(value: number): ServiceAdCustomerStatus {
  if (
    ![
      ServiceAdCustomerStatus.PendingAccountValidation,
      ServiceAdCustomerStatus.AccountValidated,
    ].includes(value as ServiceAdCustomerStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum ServiceAdCustomerStatus`);
  }
  return value as ServiceAdCustomerStatus;
}
export function parseServiceAdRequestStatus(value: number): ServiceAdRequestStatus {
  if (
    ![
      ServiceAdRequestStatus.PendingPhoneValidation,
      ServiceAdRequestStatus.PendingArtisanAcceptation,
      ServiceAdRequestStatus.ArtisanAccepted,
      ServiceAdRequestStatus.ArtisanDenied,
    ].includes(value as ServiceAdRequestStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum ServiceAdRequestStatus`);
  }
  return value as ServiceAdRequestStatus;
}
export function parseTaskStatus(value: number): TaskStatus {
  if (
    ![
      TaskStatus.Created,
      TaskStatus.Scheduled,
      TaskStatus.InFlight,
      TaskStatus.Success,
      TaskStatus.Error,
      TaskStatus.Interrupted,
    ].includes(value as TaskStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum TaskStatus`);
  }
  return value as TaskStatus;
}
export function parseTaskType(value: number): TaskType {
  if (
    ![
      TaskType.AnalyticsDedup,
      TaskType.AnalyticsAggregation,
      TaskType.Build,
      TaskType.DataLake,
      TaskType.QontoDataSource,
      TaskType.Reporting,
      TaskType.HoobiizTickets,
      TaskType.HoobiizMedia,
      TaskType.ExpertTicket,
      TaskType.HoobiizPdf,
    ].includes(value as TaskType)
  ) {
    throw new Error(`Invalid value ${value} for enum TaskType`);
  }
  return value as TaskType;
}
export function parseRedisValueType(value: number): RedisValueType {
  if (
    ![
      RedisValueType.FrontendUserInvite,
      RedisValueType.FrontendUserSession,
      RedisValueType.FrontendMagicLink,
      RedisValueType.CaptchaToken,
      RedisValueType.RateLimiting,
      RedisValueType.FrontendUserEmailVerification,
      RedisValueType.FrontendUserResetPassword,
    ].includes(value as RedisValueType)
  ) {
    throw new Error(`Invalid value ${value} for enum RedisValueType`);
  }
  return value as RedisValueType;
}
export function parseInstanceRequestType(value: number): InstanceRequestType {
  if (
    ![
      InstanceRequestType.InstanceType,
      InstanceRequestType.MinVcpusMemory,
      InstanceRequestType.Termination,
    ].includes(value as InstanceRequestType)
  ) {
    throw new Error(`Invalid value ${value} for enum InstanceRequestType`);
  }
  return value as InstanceRequestType;
}
export function parseDatalakeElementTypeGroup(value: number): DatalakeElementTypeGroup {
  if (
    ![
      DatalakeElementTypeGroup.DataSource,
      DatalakeElementTypeGroup.Transformation,
      DatalakeElementTypeGroup.Aggregation,
      DatalakeElementTypeGroup.Output,
    ].includes(value as DatalakeElementTypeGroup)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeElementTypeGroup`);
  }
  return value as DatalakeElementTypeGroup;
}
export function parseDatalakeElementType(value: number): DatalakeElementType {
  if (
    ![
      DatalakeElementType.WebEventsDataSource,
      DatalakeElementType.FileDropDataSource,
      DatalakeElementType.QontoDataSource,
      DatalakeElementType.IpGeolocTransformation,
      DatalakeElementType.CalculationsTransformation,
      DatalakeElementType.ExtractStringTransformation,
      DatalakeElementType.SumsAggregation,
      DatalakeElementType.GraphOutput,
      DatalakeElementType.RestApiOutput,
      DatalakeElementType.LabelTransformation,
    ].includes(value as DatalakeElementType)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeElementType`);
  }
  return value as DatalakeElementType;
}
export function parseDatalakePartitionType(value: number): DatalakePartitionType {
  if (
    ![
      DatalakePartitionType.None,
      DatalakePartitionType.Hourly,
      DatalakePartitionType.Daily,
    ].includes(value as DatalakePartitionType)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakePartitionType`);
  }
  return value as DatalakePartitionType;
}
export function parseReportingBreakdown(value: number): ReportingBreakdown {
  if (
    ![ReportingBreakdown.Minutely, ReportingBreakdown.Hourly].includes(value as ReportingBreakdown)
  ) {
    throw new Error(`Invalid value ${value} for enum ReportingBreakdown`);
  }
  return value as ReportingBreakdown;
}
export function parseDatalakeOutputColumnType(value: number): DatalakeOutputColumnType {
  if (
    ![
      DatalakeOutputColumnType.String,
      DatalakeOutputColumnType.Number,
      DatalakeOutputColumnType.Boolean,
    ].includes(value as DatalakeOutputColumnType)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeOutputColumnType`);
  }
  return value as DatalakeOutputColumnType;
}
export function parseDatalakeTimeBreakdown(value: number): DatalakeTimeBreakdown {
  if (![DatalakeTimeBreakdown.Hourly].includes(value as DatalakeTimeBreakdown)) {
    throw new Error(`Invalid value ${value} for enum DatalakeTimeBreakdown`);
  }
  return value as DatalakeTimeBreakdown;
}
export function parseDatalakeRestApiFormat(value: number): DatalakeRestApiFormat {
  if (
    ![DatalakeRestApiFormat.Json, DatalakeRestApiFormat.Csv].includes(
      value as DatalakeRestApiFormat
    )
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeRestApiFormat`);
  }
  return value as DatalakeRestApiFormat;
}
export function parseDatalakeCalculationOperator(value: number): DatalakeCalculationOperator {
  if (![DatalakeCalculationOperator.Multiply].includes(value as DatalakeCalculationOperator)) {
    throw new Error(`Invalid value ${value} for enum DatalakeCalculationOperator`);
  }
  return value as DatalakeCalculationOperator;
}
export function parseHoobiizCartItemType(value: number): HoobiizCartItemType {
  if (
    ![HoobiizCartItemType.Ticket, HoobiizCartItemType.ExpertTicket].includes(
      value as HoobiizCartItemType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizCartItemType`);
  }
  return value as HoobiizCartItemType;
}
export function parseHoobiizOrderItemType(value: number): HoobiizOrderItemType {
  if (
    ![HoobiizOrderItemType.Ticket, HoobiizOrderItemType.ExpertTicket].includes(
      value as HoobiizOrderItemType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOrderItemType`);
  }
  return value as HoobiizOrderItemType;
}
export function parseHoobiizOrderStatus(value: number): HoobiizOrderStatus {
  if (
    ![
      HoobiizOrderStatus.Pending,
      HoobiizOrderStatus.Canceled,
      HoobiizOrderStatus.Success,
      HoobiizOrderStatus.PendingConfirmation,
      HoobiizOrderStatus.Rejected,
    ].includes(value as HoobiizOrderStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOrderStatus`);
  }
  return value as HoobiizOrderStatus;
}
export function parseCurrency(value: string): Currency {
  if (![Currency.Euro].includes(value as Currency)) {
    throw new Error(`Invalid value ${value} for enum Currency`);
  }
  return value as Currency;
}
export function parseHoobiizVisibility(value: string): HoobiizVisibility {
  if (
    ![
      HoobiizVisibility.Public,
      HoobiizVisibility.Private,
      HoobiizVisibility.PrivateHidden,
    ].includes(value as HoobiizVisibility)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizVisibility`);
  }
  return value as HoobiizVisibility;
}
export function parseHoobiizWeekday(value: string): HoobiizWeekday {
  if (
    ![
      HoobiizWeekday.Monday,
      HoobiizWeekday.Tuesday,
      HoobiizWeekday.Wednesday,
      HoobiizWeekday.Thursday,
      HoobiizWeekday.Friday,
      HoobiizWeekday.Saturday,
      HoobiizWeekday.Sunday,
    ].includes(value as HoobiizWeekday)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizWeekday`);
  }
  return value as HoobiizWeekday;
}
export function parseHoobiizWhiteLabelingTheme(value: number): HoobiizWhiteLabelingTheme {
  if (
    ![HoobiizWhiteLabelingTheme.Youpiiz, HoobiizWhiteLabelingTheme.Cse].includes(
      value as HoobiizWhiteLabelingTheme
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizWhiteLabelingTheme`);
  }
  return value as HoobiizWhiteLabelingTheme;
}
export function parseFrontendName(value: string): FrontendName {
  if (
    ![
      FrontendName.Admin,
      FrontendName.Onescale,
      FrontendName.Restaurant,
      FrontendName.AgeOfEmpires,
      FrontendName.Hoobiiz,
    ].includes(value as FrontendName)
  ) {
    throw new Error(`Invalid value ${value} for enum FrontendName`);
  }
  return value as FrontendName;
}
export function parseHoobiizTicketFileOrigin(value: string): HoobiizTicketFileOrigin {
  if (
    ![HoobiizTicketFileOrigin.GeneratedByPdfTask, HoobiizTicketFileOrigin.UploadedByAdmin].includes(
      value as HoobiizTicketFileOrigin
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizTicketFileOrigin`);
  }
  return value as HoobiizTicketFileOrigin;
}
export function parseHoobiizStockEntryStatus(value: number): HoobiizStockEntryStatus {
  if (
    ![
      HoobiizStockEntryStatus.AwaitingGeneration,
      HoobiizStockEntryStatus.AwaitingUpload,
      HoobiizStockEntryStatus.Ready,
    ].includes(value as HoobiizStockEntryStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizStockEntryStatus`);
  }
  return value as HoobiizStockEntryStatus;
}
export function parseFrontendUserAuthMethodType(value: string): FrontendUserAuthMethodType {
  if (
    ![
      FrontendUserAuthMethodType.MagicLink,
      FrontendUserAuthMethodType.MfaCode,
      FrontendUserAuthMethodType.Password,
    ].includes(value as FrontendUserAuthMethodType)
  ) {
    throw new Error(`Invalid value ${value} for enum FrontendUserAuthMethodType`);
  }
  return value as FrontendUserAuthMethodType;
}
export function parseHoobiizActivityAddressType(value: string): HoobiizActivityAddressType {
  if (
    ![
      HoobiizActivityAddressType.Vendor,
      HoobiizActivityAddressType.AtHome,
      HoobiizActivityAddressType.Website,
      HoobiizActivityAddressType.Manual,
    ].includes(value as HoobiizActivityAddressType)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizActivityAddressType`);
  }
  return value as HoobiizActivityAddressType;
}
export function parseHoobiizStockEntryContentType(value: string): HoobiizStockEntryContentType {
  if (![HoobiizStockEntryContentType.PdfFile].includes(value as HoobiizStockEntryContentType)) {
    throw new Error(`Invalid value ${value} for enum HoobiizStockEntryContentType`);
  }
  return value as HoobiizStockEntryContentType;
}
export function parseHoobiizStockReservationType(value: string): HoobiizStockReservationType {
  if (
    ![HoobiizStockReservationType.Flexible, HoobiizStockReservationType.Fixed].includes(
      value as HoobiizStockReservationType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizStockReservationType`);
  }
  return value as HoobiizStockReservationType;
}
export function parseHoobiizOfferTargetType(value: string): HoobiizOfferTargetType {
  if (
    ![
      HoobiizOfferTargetType.Global,
      HoobiizOfferTargetType.Vendor,
      HoobiizOfferTargetType.Activity,
    ].includes(value as HoobiizOfferTargetType)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOfferTargetType`);
  }
  return value as HoobiizOfferTargetType;
}
export function parseHoobiizOfferFlashType(value: string): HoobiizOfferFlashType {
  if (
    ![HoobiizOfferFlashType.Disabled, HoobiizOfferFlashType.Period].includes(
      value as HoobiizOfferFlashType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOfferFlashType`);
  }
  return value as HoobiizOfferFlashType;
}
export function parseFrontendUserDataContentType(value: string): FrontendUserDataContentType {
  if (
    ![FrontendUserDataContentType.Onescale, FrontendUserDataContentType.Hoobiiz].includes(
      value as FrontendUserDataContentType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum FrontendUserDataContentType`);
  }
  return value as FrontendUserDataContentType;
}
export function parseHoobiizDiscountType(value: string): HoobiizDiscountType {
  if (
    ![HoobiizDiscountType.Percent, HoobiizDiscountType.Amount].includes(
      value as HoobiizDiscountType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizDiscountType`);
  }
  return value as HoobiizDiscountType;
}
export function parseHoobiizFlexibleStockScheduleType(
  value: string
): HoobiizFlexibleStockScheduleType {
  if (
    ![HoobiizFlexibleStockScheduleType.Inherit, HoobiizFlexibleStockScheduleType.Override].includes(
      value as HoobiizFlexibleStockScheduleType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizFlexibleStockScheduleType`);
  }
  return value as HoobiizFlexibleStockScheduleType;
}
export function parseHoobiizStockModeType(value: string): HoobiizStockModeType {
  if (
    ![
      HoobiizStockModeType.Pregenerated,
      HoobiizStockModeType.Automatic,
      HoobiizStockModeType.VendorConfirm,
      HoobiizStockModeType.AdminConfirm,
    ].includes(value as HoobiizStockModeType)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizStockModeType`);
  }
  return value as HoobiizStockModeType;
}
export function parseHoobiizWhiteLabelingAuthType(value: string): HoobiizWhiteLabelingAuthType {
  if (
    ![HoobiizWhiteLabelingAuthType.ModeDefault, HoobiizWhiteLabelingAuthType.ModeHero].includes(
      value as HoobiizWhiteLabelingAuthType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizWhiteLabelingAuthType`);
  }
  return value as HoobiizWhiteLabelingAuthType;
}

export const enumDebug = {
  DoNotUseType: DoNotUseTypeDebugString,
  BuildStatus: BuildStatusDebugString,
  ServiceAdCustomerStatus: ServiceAdCustomerStatusDebugString,
  ServiceAdRequestStatus: ServiceAdRequestStatusDebugString,
  TaskStatus: TaskStatusDebugString,
  TaskType: TaskTypeDebugString,
  RedisValueType: RedisValueTypeDebugString,
  InstanceRequestType: InstanceRequestTypeDebugString,
  DatalakeElementTypeGroup: DatalakeElementTypeGroupDebugString,
  DatalakeElementType: DatalakeElementTypeDebugString,
  DatalakePartitionType: DatalakePartitionTypeDebugString,
  ReportingBreakdown: ReportingBreakdownDebugString,
  DatalakeOutputColumnType: DatalakeOutputColumnTypeDebugString,
  DatalakeTimeBreakdown: DatalakeTimeBreakdownDebugString,
  DatalakeRestApiFormat: DatalakeRestApiFormatDebugString,
  DatalakeCalculationOperator: DatalakeCalculationOperatorDebugString,
  HoobiizCartItemType: HoobiizCartItemTypeDebugString,
  HoobiizOrderItemType: HoobiizOrderItemTypeDebugString,
  HoobiizOrderStatus: HoobiizOrderStatusDebugString,
  Currency: CurrencyDebugString,
  HoobiizVisibility: HoobiizVisibilityDebugString,
  HoobiizWeekday: HoobiizWeekdayDebugString,
  HoobiizWhiteLabelingTheme: HoobiizWhiteLabelingThemeDebugString,
  FrontendName: FrontendNameDebugString,
  HoobiizTicketFileOrigin: HoobiizTicketFileOriginDebugString,
  HoobiizStockEntryStatus: HoobiizStockEntryStatusDebugString,
  FrontendUserAuthMethodType: FrontendUserAuthMethodTypeDebugString,
  HoobiizActivityAddressType: HoobiizActivityAddressTypeDebugString,
  HoobiizStockEntryContentType: HoobiizStockEntryContentTypeDebugString,
  HoobiizStockReservationType: HoobiizStockReservationTypeDebugString,
  HoobiizOfferTargetType: HoobiizOfferTargetTypeDebugString,
  HoobiizOfferFlashType: HoobiizOfferFlashTypeDebugString,
  FrontendUserDataContentType: FrontendUserDataContentTypeDebugString,
  HoobiizDiscountType: HoobiizDiscountTypeDebugString,
  HoobiizFlexibleStockScheduleType: HoobiizFlexibleStockScheduleTypeDebugString,
  HoobiizStockModeType: HoobiizStockModeTypeDebugString,
  HoobiizWhiteLabelingAuthType: HoobiizWhiteLabelingAuthTypeDebugString,
};

export interface FrontendUserDataContentOnescale {
  type: FrontendUserDataContentType.Onescale;
  accountId: AccountId;
  isRoot: boolean;
}

export function parseFrontendUserDataContentOnescale(
  json: Record<string, unknown>
): Omit<FrontendUserDataContentOnescale, 'type'> {
  try {
    const parsedFrontendUserDataContentOnescaleMap = asMapOrThrow(json, {
      msg: `FrontendUserDataContentOnescale`,
    });
    const parsedFrontendUserDataContentOnescaleAccountId = asStringOrThrow<AccountId>(
      parsedFrontendUserDataContentOnescaleMap['accountId'],
      {msg: `FrontendUserDataContentOnescale.accountId`}
    );
    const parsedFrontendUserDataContentOnescaleIsRoot = asBooleanOrThrow(
      parsedFrontendUserDataContentOnescaleMap['isRoot'],
      {msg: `FrontendUserDataContentOnescale.isRoot`}
    );
    const parsedFrontendUserDataContentOnescale = {
      accountId: parsedFrontendUserDataContentOnescaleAccountId,
      isRoot: parsedFrontendUserDataContentOnescaleIsRoot,
    };
    return parsedFrontendUserDataContentOnescale;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserDataContentOnescale: ${errorAsString(err)}`);
  }
}

export interface FrontendUserDataContentHoobiiz {
  type: FrontendUserDataContentType.Hoobiiz;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  registrationCode?: HoobiizRegistrationCode;
  groups?: HoobiizUserGroupId[];
  adminGroups?: HoobiizUserGroupId[];
}

export function parseFrontendUserDataContentHoobiiz(
  json: Record<string, unknown>
): Omit<FrontendUserDataContentHoobiiz, 'type'> {
  try {
    const parsedFrontendUserDataContentHoobiizMap = asMapOrThrow(json, {
      msg: `FrontendUserDataContentHoobiiz`,
    });
    const parsedFrontendUserDataContentHoobiizFirstName = asStringOrThrow(
      parsedFrontendUserDataContentHoobiizMap['firstName'],
      {msg: `FrontendUserDataContentHoobiiz.firstName`}
    );
    const parsedFrontendUserDataContentHoobiizLastName = asStringOrThrow(
      parsedFrontendUserDataContentHoobiizMap['lastName'],
      {msg: `FrontendUserDataContentHoobiiz.lastName`}
    );
    const parsedFrontendUserDataContentHoobiizPhoneNumber =
      parsedFrontendUserDataContentHoobiizMap['phoneNumber'] === undefined
        ? undefined
        : asStringOrThrow(parsedFrontendUserDataContentHoobiizMap['phoneNumber'], {
            msg: `FrontendUserDataContentHoobiiz.phoneNumber`,
          });
    const parsedFrontendUserDataContentHoobiizRegistrationCode =
      parsedFrontendUserDataContentHoobiizMap['registrationCode'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizRegistrationCode>(
            parsedFrontendUserDataContentHoobiizMap['registrationCode'],
            {msg: `FrontendUserDataContentHoobiiz.registrationCode`}
          );
    const parsedFrontendUserDataContentHoobiizGroups =
      parsedFrontendUserDataContentHoobiizMap['groups'] === undefined
        ? undefined
        : asArrayOrThrow(parsedFrontendUserDataContentHoobiizMap['groups'], {
            msg: `FrontendUserDataContentHoobiiz.groups`,
          }).map((element, i) => {
            const parsedElement = asStringOrThrow<HoobiizUserGroupId>(element, {
              msg: `FrontendUserDataContentHoobiiz.groups[${i}]`,
            });
            return parsedElement;
          });
    const parsedFrontendUserDataContentHoobiizAdminGroups =
      parsedFrontendUserDataContentHoobiizMap['adminGroups'] === undefined
        ? undefined
        : asArrayOrThrow(parsedFrontendUserDataContentHoobiizMap['adminGroups'], {
            msg: `FrontendUserDataContentHoobiiz.adminGroups`,
          }).map((element, i) => {
            const parsedElement = asStringOrThrow<HoobiizUserGroupId>(element, {
              msg: `FrontendUserDataContentHoobiiz.adminGroups[${i}]`,
            });
            return parsedElement;
          });
    const parsedFrontendUserDataContentHoobiiz = {
      firstName: parsedFrontendUserDataContentHoobiizFirstName,
      lastName: parsedFrontendUserDataContentHoobiizLastName,
      phoneNumber: parsedFrontendUserDataContentHoobiizPhoneNumber,
      registrationCode: parsedFrontendUserDataContentHoobiizRegistrationCode,
      groups: parsedFrontendUserDataContentHoobiizGroups,
      adminGroups: parsedFrontendUserDataContentHoobiizAdminGroups,
    };
    return parsedFrontendUserDataContentHoobiiz;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserDataContentHoobiiz: ${errorAsString(err)}`);
  }
}

export type FrontendUserDataContent =
  | FrontendUserDataContentOnescale
  | FrontendUserDataContentHoobiiz;

export function parseFrontendUserDataContent(
  json: Record<string, unknown>
): FrontendUserDataContent {
  try {
    const type = asStringOrThrow(json.type, {msg: 'FrontendUserDataContent.type'});
    if (type === FrontendUserDataContentType.Onescale) {
      return {type, ...parseFrontendUserDataContentOnescale(json)};
    } else if (type === FrontendUserDataContentType.Hoobiiz) {
      return {type, ...parseFrontendUserDataContentHoobiiz(json)};
    }
    throw new Error(`Failure to parse FrontendUserDataContent: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserDataContent: ${errorAsString(err)}.`);
  }
}

export interface AddressLookupResult {
  placeId: string;
  address: string;
  addressDetails: {
    streetNumber?: string;
    route?: string;
    city?: string;
    country?: string;
    postalCode?: string;
  };
  main: string;
  secondary: string;
  geometry: PlaceGeometry;
}

export function parseAddressLookupResult(json: Record<string, unknown>): AddressLookupResult {
  try {
    const parsedAddressLookupResultMap = asMapOrThrow(json, {msg: `AddressLookupResult`});
    const parsedAddressLookupResultPlaceId = asStringOrThrow(
      parsedAddressLookupResultMap['placeId'],
      {msg: `AddressLookupResult.placeId`}
    );
    const parsedAddressLookupResultAddress = asStringOrThrow(
      parsedAddressLookupResultMap['address'],
      {msg: `AddressLookupResult.address`}
    );
    const parsedAddressLookupResultAddressDetailsMap = asMapOrThrow(
      parsedAddressLookupResultMap['addressDetails'],
      {msg: `AddressLookupResult.addressDetails`}
    );
    const parsedAddressLookupResultAddressDetailsStreetNumber =
      parsedAddressLookupResultAddressDetailsMap['streetNumber'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['streetNumber'], {
            msg: `AddressLookupResult.addressDetails.streetNumber`,
          });
    const parsedAddressLookupResultAddressDetailsRoute =
      parsedAddressLookupResultAddressDetailsMap['route'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['route'], {
            msg: `AddressLookupResult.addressDetails.route`,
          });
    const parsedAddressLookupResultAddressDetailsCity =
      parsedAddressLookupResultAddressDetailsMap['city'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['city'], {
            msg: `AddressLookupResult.addressDetails.city`,
          });
    const parsedAddressLookupResultAddressDetailsCountry =
      parsedAddressLookupResultAddressDetailsMap['country'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['country'], {
            msg: `AddressLookupResult.addressDetails.country`,
          });
    const parsedAddressLookupResultAddressDetailsPostalCode =
      parsedAddressLookupResultAddressDetailsMap['postalCode'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['postalCode'], {
            msg: `AddressLookupResult.addressDetails.postalCode`,
          });
    const parsedAddressLookupResultAddressDetails = {
      streetNumber: parsedAddressLookupResultAddressDetailsStreetNumber,
      route: parsedAddressLookupResultAddressDetailsRoute,
      city: parsedAddressLookupResultAddressDetailsCity,
      country: parsedAddressLookupResultAddressDetailsCountry,
      postalCode: parsedAddressLookupResultAddressDetailsPostalCode,
    };
    const parsedAddressLookupResultMain = asStringOrThrow(parsedAddressLookupResultMap['main'], {
      msg: `AddressLookupResult.main`,
    });
    const parsedAddressLookupResultSecondary = asStringOrThrow(
      parsedAddressLookupResultMap['secondary'],
      {msg: `AddressLookupResult.secondary`}
    );
    const parsedAddressLookupResultGeometry = parsedAddressLookupResultMap[
      'geometry'
    ] as PlaceGeometry;
    const parsedAddressLookupResult = {
      placeId: parsedAddressLookupResultPlaceId,
      address: parsedAddressLookupResultAddress,
      addressDetails: parsedAddressLookupResultAddressDetails,
      main: parsedAddressLookupResultMain,
      secondary: parsedAddressLookupResultSecondary,
      geometry: parsedAddressLookupResultGeometry,
    };
    return parsedAddressLookupResult;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse AddressLookupResult payload: ${errorAsString(
        err
      )}. Payload for AddressLookupResult was ${JSON.stringify(json)}`
    );
  }
}

export interface CurrencyAmount {
  currency: Currency;
  cents: number;
}

export function parseCurrencyAmount(json: Record<string, unknown>): CurrencyAmount {
  try {
    const parsedCurrencyAmountMap = asMapOrThrow(json, {msg: `CurrencyAmount`});
    const parsedCurrencyAmountCurrency = parseCurrency(
      asStringOrThrow(parsedCurrencyAmountMap['currency'], {msg: `CurrencyAmount.currency`})
    );
    const parsedCurrencyAmountCents = asNumberOrThrow(parsedCurrencyAmountMap['cents'], {
      msg: `CurrencyAmount.cents`,
    });
    const parsedCurrencyAmount = {
      currency: parsedCurrencyAmountCurrency,
      cents: parsedCurrencyAmountCents,
    };
    return parsedCurrencyAmount;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse CurrencyAmount payload: ${errorAsString(
        err
      )}. Payload for CurrencyAmount was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTimePeriod {
  startTs: number;
  endTs: number;
}

export function parseHoobiizTimePeriod(json: Record<string, unknown>): HoobiizTimePeriod {
  try {
    const parsedHoobiizTimePeriodMap = asMapOrThrow(json, {msg: `HoobiizTimePeriod`});
    const parsedHoobiizTimePeriodStartTs = asNumberOrThrow(parsedHoobiizTimePeriodMap['startTs'], {
      msg: `HoobiizTimePeriod.startTs`,
    });
    const parsedHoobiizTimePeriodEndTs = asNumberOrThrow(parsedHoobiizTimePeriodMap['endTs'], {
      msg: `HoobiizTimePeriod.endTs`,
    });
    const parsedHoobiizTimePeriod = {
      startTs: parsedHoobiizTimePeriodStartTs,
      endTs: parsedHoobiizTimePeriodEndTs,
    };
    return parsedHoobiizTimePeriod;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTimePeriod payload: ${errorAsString(
        err
      )}. Payload for HoobiizTimePeriod was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTimeOfDay {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}

export function parseHoobiizTimeOfDay(json: Record<string, unknown>): HoobiizTimeOfDay {
  try {
    const parsedHoobiizTimeOfDayMap = asMapOrThrow(json, {msg: `HoobiizTimeOfDay`});
    const parsedHoobiizTimeOfDayStartHour = asNumberOrThrow(
      parsedHoobiizTimeOfDayMap['startHour'],
      {msg: `HoobiizTimeOfDay.startHour`}
    );
    const parsedHoobiizTimeOfDayStartMinute = asNumberOrThrow(
      parsedHoobiizTimeOfDayMap['startMinute'],
      {msg: `HoobiizTimeOfDay.startMinute`}
    );
    const parsedHoobiizTimeOfDayEndHour = asNumberOrThrow(parsedHoobiizTimeOfDayMap['endHour'], {
      msg: `HoobiizTimeOfDay.endHour`,
    });
    const parsedHoobiizTimeOfDayEndMinute = asNumberOrThrow(
      parsedHoobiizTimeOfDayMap['endMinute'],
      {msg: `HoobiizTimeOfDay.endMinute`}
    );
    const parsedHoobiizTimeOfDay = {
      startHour: parsedHoobiizTimeOfDayStartHour,
      startMinute: parsedHoobiizTimeOfDayStartMinute,
      endHour: parsedHoobiizTimeOfDayEndHour,
      endMinute: parsedHoobiizTimeOfDayEndMinute,
    };
    return parsedHoobiizTimeOfDay;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTimeOfDay payload: ${errorAsString(
        err
      )}. Payload for HoobiizTimeOfDay was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizOpeningHoursOverride {
  day: number;
  reason: string;
}

export function parseHoobiizOpeningHoursOverride(
  json: Record<string, unknown>
): HoobiizOpeningHoursOverride {
  try {
    const parsedHoobiizOpeningHoursOverrideMap = asMapOrThrow(json, {
      msg: `HoobiizOpeningHoursOverride`,
    });
    const parsedHoobiizOpeningHoursOverrideDay = asNumberOrThrow(
      parsedHoobiizOpeningHoursOverrideMap['day'],
      {msg: `HoobiizOpeningHoursOverride.day`}
    );
    const parsedHoobiizOpeningHoursOverrideReason = asStringOrThrow(
      parsedHoobiizOpeningHoursOverrideMap['reason'],
      {msg: `HoobiizOpeningHoursOverride.reason`}
    );
    const parsedHoobiizOpeningHoursOverride = {
      day: parsedHoobiizOpeningHoursOverrideDay,
      reason: parsedHoobiizOpeningHoursOverrideReason,
    };
    return parsedHoobiizOpeningHoursOverride;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOpeningHoursOverride payload: ${errorAsString(
        err
      )}. Payload for HoobiizOpeningHoursOverride was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizVendorContact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export function parseHoobiizVendorContact(json: Record<string, unknown>): HoobiizVendorContact {
  try {
    const parsedHoobiizVendorContactMap = asMapOrThrow(json, {msg: `HoobiizVendorContact`});
    const parsedHoobiizVendorContactFirstName = asStringOrThrow(
      parsedHoobiizVendorContactMap['firstName'],
      {msg: `HoobiizVendorContact.firstName`}
    );
    const parsedHoobiizVendorContactLastName = asStringOrThrow(
      parsedHoobiizVendorContactMap['lastName'],
      {msg: `HoobiizVendorContact.lastName`}
    );
    const parsedHoobiizVendorContactEmail = asStringOrThrow(
      parsedHoobiizVendorContactMap['email'],
      {msg: `HoobiizVendorContact.email`}
    );
    const parsedHoobiizVendorContactPhone = asStringOrThrow(
      parsedHoobiizVendorContactMap['phone'],
      {msg: `HoobiizVendorContact.phone`}
    );
    const parsedHoobiizVendorContact = {
      firstName: parsedHoobiizVendorContactFirstName,
      lastName: parsedHoobiizVendorContactLastName,
      email: parsedHoobiizVendorContactEmail,
      phone: parsedHoobiizVendorContactPhone,
    };
    return parsedHoobiizVendorContact;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorContact payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorContact was ${JSON.stringify(json)}`
    );
  }
}

/**
 * The ticket data was provided in the admin beforehand
 */
export interface HoobiizStockModePregenerated {
  type: HoobiizStockModeType.Pregenerated;
  unlimited?: boolean;
}

export function parseHoobiizStockModePregenerated(
  json: Record<string, unknown>
): Omit<HoobiizStockModePregenerated, 'type'> {
  try {
    const parsedHoobiizStockModePregeneratedMap = asMapOrThrow(json, {
      msg: `HoobiizStockModePregenerated`,
    });
    const parsedHoobiizStockModePregeneratedUnlimited =
      parsedHoobiizStockModePregeneratedMap['unlimited'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizStockModePregeneratedMap['unlimited'], {
            msg: `HoobiizStockModePregenerated.unlimited`,
          });
    const parsedHoobiizStockModePregenerated = {
      unlimited: parsedHoobiizStockModePregeneratedUnlimited,
    };
    return parsedHoobiizStockModePregenerated;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockModePregenerated: ${errorAsString(err)}`);
  }
}

/**
 * The ticket data is generated on the fly after the purchase
 */
export interface HoobiizStockModeAutomatic {
  type: HoobiizStockModeType.Automatic;
  sendEmail: boolean;
}

export function parseHoobiizStockModeAutomatic(
  json: Record<string, unknown>
): Omit<HoobiizStockModeAutomatic, 'type'> {
  try {
    const parsedHoobiizStockModeAutomaticMap = asMapOrThrow(json, {
      msg: `HoobiizStockModeAutomatic`,
    });
    const parsedHoobiizStockModeAutomaticSendEmail = asBooleanOrThrow(
      parsedHoobiizStockModeAutomaticMap['sendEmail'],
      {msg: `HoobiizStockModeAutomatic.sendEmail`}
    );
    const parsedHoobiizStockModeAutomatic = {
      sendEmail: parsedHoobiizStockModeAutomaticSendEmail,
    };
    return parsedHoobiizStockModeAutomatic;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockModeAutomatic: ${errorAsString(err)}`);
  }
}

/**
 * The ticket data is generated on the fly once the vendor approve the
 * purchase
 */
export interface HoobiizStockModeVendorConfirm {
  type: HoobiizStockModeType.VendorConfirm;
  email?: string;
}

export function parseHoobiizStockModeVendorConfirm(
  json: Record<string, unknown>
): Omit<HoobiizStockModeVendorConfirm, 'type'> {
  try {
    const parsedHoobiizStockModeVendorConfirmMap = asMapOrThrow(json, {
      msg: `HoobiizStockModeVendorConfirm`,
    });
    const parsedHoobiizStockModeVendorConfirmEmail =
      parsedHoobiizStockModeVendorConfirmMap['email'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizStockModeVendorConfirmMap['email'], {
            msg: `HoobiizStockModeVendorConfirm.email`,
          });
    const parsedHoobiizStockModeVendorConfirm = {
      email: parsedHoobiizStockModeVendorConfirmEmail,
    };
    return parsedHoobiizStockModeVendorConfirm;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockModeVendorConfirm: ${errorAsString(err)}`);
  }
}

/**
 * The ticket data will be provided by the admin once they approve the
 * purchase
 */
export interface HoobiizStockModeAdminConfirm {
  type: HoobiizStockModeType.AdminConfirm;
}

export function parseHoobiizStockModeAdminConfirm(): Omit<HoobiizStockModeAdminConfirm, 'type'> {
  return {};
}

export type HoobiizStockMode =
  | HoobiizStockModePregenerated
  | HoobiizStockModeAutomatic
  | HoobiizStockModeVendorConfirm
  | HoobiizStockModeAdminConfirm;

export function parseHoobiizStockMode(json: Record<string, unknown>): HoobiizStockMode {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizStockMode.type'});
    if (type === HoobiizStockModeType.Pregenerated) {
      return {type, ...parseHoobiizStockModePregenerated(json)};
    } else if (type === HoobiizStockModeType.Automatic) {
      return {type, ...parseHoobiizStockModeAutomatic(json)};
    } else if (type === HoobiizStockModeType.VendorConfirm) {
      return {type, ...parseHoobiizStockModeVendorConfirm(json)};
    } else if (type === HoobiizStockModeType.AdminConfirm) {
      return {type, ...parseHoobiizStockModeAdminConfirm()};
    }
    throw new Error(`Failure to parse HoobiizStockMode: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockMode: ${errorAsString(err)}.`);
  }
}

export interface HoobiizExpertTicketInfo {
  id: HoobiizExpertTicketInfoId;
  label: string;
  description?: string;
  productId: HoobiizExpertTicketProductId;
}

export function parseHoobiizExpertTicketInfo(
  json: Record<string, unknown>
): HoobiizExpertTicketInfo {
  try {
    const parsedHoobiizExpertTicketInfoMap = asMapOrThrow(json, {msg: `HoobiizExpertTicketInfo`});
    const parsedHoobiizExpertTicketInfoId = asStringOrThrow<HoobiizExpertTicketInfoId>(
      parsedHoobiizExpertTicketInfoMap['id'],
      {msg: `HoobiizExpertTicketInfo.id`}
    );
    const parsedHoobiizExpertTicketInfoLabel = asStringOrThrow(
      parsedHoobiizExpertTicketInfoMap['label'],
      {msg: `HoobiizExpertTicketInfo.label`}
    );
    const parsedHoobiizExpertTicketInfoDescription =
      parsedHoobiizExpertTicketInfoMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizExpertTicketInfoMap['description'], {
            msg: `HoobiizExpertTicketInfo.description`,
          });
    const parsedHoobiizExpertTicketInfoProductId = asStringOrThrow<HoobiizExpertTicketProductId>(
      parsedHoobiizExpertTicketInfoMap['productId'],
      {msg: `HoobiizExpertTicketInfo.productId`}
    );
    const parsedHoobiizExpertTicketInfo = {
      id: parsedHoobiizExpertTicketInfoId,
      label: parsedHoobiizExpertTicketInfoLabel,
      description: parsedHoobiizExpertTicketInfoDescription,
      productId: parsedHoobiizExpertTicketInfoProductId,
    };
    return parsedHoobiizExpertTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizExpertTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizExpertTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizPermission {
  name: HoobiizPermissionName;
  value: boolean;
}

export function parseHoobiizPermission(json: Record<string, unknown>): HoobiizPermission {
  try {
    const parsedHoobiizPermissionMap = asMapOrThrow(json, {msg: `HoobiizPermission`});
    const parsedHoobiizPermissionName = parsedHoobiizPermissionMap['name'] as HoobiizPermissionName;
    const parsedHoobiizPermissionValue = asBooleanOrThrow(parsedHoobiizPermissionMap['value'], {
      msg: `HoobiizPermission.value`,
    });
    const parsedHoobiizPermission = {
      name: parsedHoobiizPermissionName,
      value: parsedHoobiizPermissionValue,
    };
    return parsedHoobiizPermission;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizPermission payload: ${errorAsString(
        err
      )}. Payload for HoobiizPermission was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizWhiteLabelingAuthModeDefault {
  type: HoobiizWhiteLabelingAuthType.ModeDefault;
}

export function parseHoobiizWhiteLabelingAuthModeDefault(): Omit<
  HoobiizWhiteLabelingAuthModeDefault,
  'type'
> {
  return {};
}

export interface HoobiizWhiteLabelingAuthModeHero {
  type: HoobiizWhiteLabelingAuthType.ModeHero;
  logo?: HoobiizMediaId;
  hero?: HoobiizMediaId;
  title?: string;
  bgGradient1?: string;
  bgGradient2?: string;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
  inputBackgroundColor?: string;
  inputTextColor?: string;
  inputBorderColor?: string;
  titleColor?: string;
  textColor?: string;
  linkTextColor?: string;
}

export function parseHoobiizWhiteLabelingAuthModeHero(
  json: Record<string, unknown>
): Omit<HoobiizWhiteLabelingAuthModeHero, 'type'> {
  try {
    const parsedHoobiizWhiteLabelingAuthModeHeroMap = asMapOrThrow(json, {
      msg: `HoobiizWhiteLabelingAuthModeHero`,
    });
    const parsedHoobiizWhiteLabelingAuthModeHeroLogo =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['logo'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizMediaId>(parsedHoobiizWhiteLabelingAuthModeHeroMap['logo'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.logo`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroHero =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['hero'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizMediaId>(parsedHoobiizWhiteLabelingAuthModeHeroMap['hero'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.hero`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroTitle =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['title'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['title'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.title`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroBgGradient1 =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['bgGradient1'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['bgGradient1'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.bgGradient1`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroBgGradient2 =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['bgGradient2'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['bgGradient2'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.bgGradient2`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroButtonBackgroundColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['buttonBackgroundColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['buttonBackgroundColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.buttonBackgroundColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroButtonTextColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['buttonTextColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['buttonTextColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.buttonTextColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroInputBackgroundColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['inputBackgroundColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['inputBackgroundColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.inputBackgroundColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroInputTextColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['inputTextColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['inputTextColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.inputTextColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroInputBorderColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['inputBorderColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['inputBorderColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.inputBorderColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroTitleColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['titleColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['titleColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.titleColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroTextColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['textColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['textColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.textColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHeroLinkTextColor =
      parsedHoobiizWhiteLabelingAuthModeHeroMap['linkTextColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingAuthModeHeroMap['linkTextColor'], {
            msg: `HoobiizWhiteLabelingAuthModeHero.linkTextColor`,
          });
    const parsedHoobiizWhiteLabelingAuthModeHero = {
      logo: parsedHoobiizWhiteLabelingAuthModeHeroLogo,
      hero: parsedHoobiizWhiteLabelingAuthModeHeroHero,
      title: parsedHoobiizWhiteLabelingAuthModeHeroTitle,
      bgGradient1: parsedHoobiizWhiteLabelingAuthModeHeroBgGradient1,
      bgGradient2: parsedHoobiizWhiteLabelingAuthModeHeroBgGradient2,
      buttonBackgroundColor: parsedHoobiizWhiteLabelingAuthModeHeroButtonBackgroundColor,
      buttonTextColor: parsedHoobiizWhiteLabelingAuthModeHeroButtonTextColor,
      inputBackgroundColor: parsedHoobiizWhiteLabelingAuthModeHeroInputBackgroundColor,
      inputTextColor: parsedHoobiizWhiteLabelingAuthModeHeroInputTextColor,
      inputBorderColor: parsedHoobiizWhiteLabelingAuthModeHeroInputBorderColor,
      titleColor: parsedHoobiizWhiteLabelingAuthModeHeroTitleColor,
      textColor: parsedHoobiizWhiteLabelingAuthModeHeroTextColor,
      linkTextColor: parsedHoobiizWhiteLabelingAuthModeHeroLinkTextColor,
    };
    return parsedHoobiizWhiteLabelingAuthModeHero;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizWhiteLabelingAuthModeHero: ${errorAsString(err)}`);
  }
}

export type HoobiizWhiteLabelingAuth =
  | HoobiizWhiteLabelingAuthModeDefault
  | HoobiizWhiteLabelingAuthModeHero;

export function parseHoobiizWhiteLabelingAuth(
  json: Record<string, unknown>
): HoobiizWhiteLabelingAuth {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizWhiteLabelingAuth.type'});
    if (type === HoobiizWhiteLabelingAuthType.ModeDefault) {
      return {type, ...parseHoobiizWhiteLabelingAuthModeDefault()};
    } else if (type === HoobiizWhiteLabelingAuthType.ModeHero) {
      return {type, ...parseHoobiizWhiteLabelingAuthModeHero(json)};
    }
    throw new Error(`Failure to parse HoobiizWhiteLabelingAuth: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizWhiteLabelingAuth: ${errorAsString(err)}.`);
  }
}

export interface HoobiizWhiteLabeling {
  logo?: HoobiizMediaId;
  title?: string;
  pageTitle?: string;
  theme?: HoobiizWhiteLabelingTheme;
  mainColor?: string;
  accentTextColor?: string;
  linkTextColor?: string;
  disabledButtonColor?: string;
  outlineColor?: string;
  auth?: HoobiizWhiteLabelingAuth;
}

export function parseHoobiizWhiteLabeling(json: Record<string, unknown>): HoobiizWhiteLabeling {
  try {
    const parsedHoobiizWhiteLabelingMap = asMapOrThrow(json, {msg: `HoobiizWhiteLabeling`});
    const parsedHoobiizWhiteLabelingLogo =
      parsedHoobiizWhiteLabelingMap['logo'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizMediaId>(parsedHoobiizWhiteLabelingMap['logo'], {
            msg: `HoobiizWhiteLabeling.logo`,
          });
    const parsedHoobiizWhiteLabelingTitle =
      parsedHoobiizWhiteLabelingMap['title'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingMap['title'], {
            msg: `HoobiizWhiteLabeling.title`,
          });
    const parsedHoobiizWhiteLabelingPageTitle =
      parsedHoobiizWhiteLabelingMap['pageTitle'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingMap['pageTitle'], {
            msg: `HoobiizWhiteLabeling.pageTitle`,
          });
    const parsedHoobiizWhiteLabelingTheme =
      parsedHoobiizWhiteLabelingMap['theme'] === undefined
        ? undefined
        : parseHoobiizWhiteLabelingTheme(
            asNumberOrThrow(parsedHoobiizWhiteLabelingMap['theme'], {
              msg: `HoobiizWhiteLabeling.theme`,
            })
          );
    const parsedHoobiizWhiteLabelingMainColor =
      parsedHoobiizWhiteLabelingMap['mainColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingMap['mainColor'], {
            msg: `HoobiizWhiteLabeling.mainColor`,
          });
    const parsedHoobiizWhiteLabelingAccentTextColor =
      parsedHoobiizWhiteLabelingMap['accentTextColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingMap['accentTextColor'], {
            msg: `HoobiizWhiteLabeling.accentTextColor`,
          });
    const parsedHoobiizWhiteLabelingLinkTextColor =
      parsedHoobiizWhiteLabelingMap['linkTextColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingMap['linkTextColor'], {
            msg: `HoobiizWhiteLabeling.linkTextColor`,
          });
    const parsedHoobiizWhiteLabelingDisabledButtonColor =
      parsedHoobiizWhiteLabelingMap['disabledButtonColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingMap['disabledButtonColor'], {
            msg: `HoobiizWhiteLabeling.disabledButtonColor`,
          });
    const parsedHoobiizWhiteLabelingOutlineColor =
      parsedHoobiizWhiteLabelingMap['outlineColor'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizWhiteLabelingMap['outlineColor'], {
            msg: `HoobiizWhiteLabeling.outlineColor`,
          });
    const parsedHoobiizWhiteLabelingAuth =
      parsedHoobiizWhiteLabelingMap['auth'] === undefined
        ? undefined
        : parseHoobiizWhiteLabelingAuth(
            asMapOrThrow(parsedHoobiizWhiteLabelingMap['auth'], {msg: `HoobiizWhiteLabeling.auth`})
          );
    const parsedHoobiizWhiteLabeling = {
      logo: parsedHoobiizWhiteLabelingLogo,
      title: parsedHoobiizWhiteLabelingTitle,
      pageTitle: parsedHoobiizWhiteLabelingPageTitle,
      theme: parsedHoobiizWhiteLabelingTheme,
      mainColor: parsedHoobiizWhiteLabelingMainColor,
      accentTextColor: parsedHoobiizWhiteLabelingAccentTextColor,
      linkTextColor: parsedHoobiizWhiteLabelingLinkTextColor,
      disabledButtonColor: parsedHoobiizWhiteLabelingDisabledButtonColor,
      outlineColor: parsedHoobiizWhiteLabelingOutlineColor,
      auth: parsedHoobiizWhiteLabelingAuth,
    };
    return parsedHoobiizWhiteLabeling;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizWhiteLabeling payload: ${errorAsString(
        err
      )}. Payload for HoobiizWhiteLabeling was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizPromoCodeStock {
  discount: string;
  description: string;
  content?: string;
  link?: string;
}

export function parseHoobiizPromoCodeStock(json: Record<string, unknown>): HoobiizPromoCodeStock {
  try {
    const parsedHoobiizPromoCodeStockMap = asMapOrThrow(json, {msg: `HoobiizPromoCodeStock`});
    const parsedHoobiizPromoCodeStockDiscount = asStringOrThrow(
      parsedHoobiizPromoCodeStockMap['discount'],
      {msg: `HoobiizPromoCodeStock.discount`}
    );
    const parsedHoobiizPromoCodeStockDescription = asStringOrThrow(
      parsedHoobiizPromoCodeStockMap['description'],
      {msg: `HoobiizPromoCodeStock.description`}
    );
    const parsedHoobiizPromoCodeStockContent =
      parsedHoobiizPromoCodeStockMap['content'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizPromoCodeStockMap['content'], {
            msg: `HoobiizPromoCodeStock.content`,
          });
    const parsedHoobiizPromoCodeStockLink =
      parsedHoobiizPromoCodeStockMap['link'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizPromoCodeStockMap['link'], {
            msg: `HoobiizPromoCodeStock.link`,
          });
    const parsedHoobiizPromoCodeStock = {
      discount: parsedHoobiizPromoCodeStockDiscount,
      description: parsedHoobiizPromoCodeStockDescription,
      content: parsedHoobiizPromoCodeStockContent,
      link: parsedHoobiizPromoCodeStockLink,
    };
    return parsedHoobiizPromoCodeStock;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizPromoCodeStock payload: ${errorAsString(
        err
      )}. Payload for HoobiizPromoCodeStock was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizDiscountPercent {
  type: HoobiizDiscountType.Percent;
  percent: number;
}

export function parseHoobiizDiscountPercent(
  json: Record<string, unknown>
): Omit<HoobiizDiscountPercent, 'type'> {
  try {
    const parsedHoobiizDiscountPercentMap = asMapOrThrow(json, {msg: `HoobiizDiscountPercent`});
    const parsedHoobiizDiscountPercentPercent = asNumberOrThrow(
      parsedHoobiizDiscountPercentMap['percent'],
      {msg: `HoobiizDiscountPercent.percent`}
    );
    const parsedHoobiizDiscountPercent = {
      percent: parsedHoobiizDiscountPercentPercent,
    };
    return parsedHoobiizDiscountPercent;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizDiscountPercent: ${errorAsString(err)}`);
  }
}

export interface HoobiizDiscountAmount {
  type: HoobiizDiscountType.Amount;
  amount: CurrencyAmount;
}

export function parseHoobiizDiscountAmount(
  json: Record<string, unknown>
): Omit<HoobiizDiscountAmount, 'type'> {
  try {
    const parsedHoobiizDiscountAmountMap = asMapOrThrow(json, {msg: `HoobiizDiscountAmount`});
    const parsedHoobiizDiscountAmountAmount = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizDiscountAmountMap['amount'], {msg: `HoobiizDiscountAmount.amount`})
    );
    const parsedHoobiizDiscountAmount = {
      amount: parsedHoobiizDiscountAmountAmount,
    };
    return parsedHoobiizDiscountAmount;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizDiscountAmount: ${errorAsString(err)}`);
  }
}

export type HoobiizDiscount = HoobiizDiscountPercent | HoobiizDiscountAmount;

export function parseHoobiizDiscount(json: Record<string, unknown>): HoobiizDiscount {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizDiscount.type'});
    if (type === HoobiizDiscountType.Percent) {
      return {type, ...parseHoobiizDiscountPercent(json)};
    } else if (type === HoobiizDiscountType.Amount) {
      return {type, ...parseHoobiizDiscountAmount(json)};
    }
    throw new Error(`Failure to parse HoobiizDiscount: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizDiscount: ${errorAsString(err)}.`);
  }
}

export interface HoobiizWeekPeriods {
  Monday: HoobiizTimeOfDay[];
  Tuesday: HoobiizTimeOfDay[];
  Wednesday: HoobiizTimeOfDay[];
  Thursday: HoobiizTimeOfDay[];
  Friday: HoobiizTimeOfDay[];
  Saturday: HoobiizTimeOfDay[];
  Sunday: HoobiizTimeOfDay[];
}

export function parseHoobiizWeekPeriods(json: Record<string, unknown>): HoobiizWeekPeriods {
  try {
    const parsedHoobiizWeekPeriodsMap = asMapOrThrow(json, {msg: `HoobiizWeekPeriods`});
    const parsedHoobiizWeekPeriodsMonday = asArrayOrThrow(parsedHoobiizWeekPeriodsMap['Monday'], {
      msg: `HoobiizWeekPeriods.Monday`,
    }).map((element, i) => {
      const parsedElement = parseHoobiizTimeOfDay(
        asMapOrThrow(element, {msg: `HoobiizWeekPeriods.Monday[${i}]`})
      );
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsTuesday = asArrayOrThrow(parsedHoobiizWeekPeriodsMap['Tuesday'], {
      msg: `HoobiizWeekPeriods.Tuesday`,
    }).map((element, i) => {
      const parsedElement = parseHoobiizTimeOfDay(
        asMapOrThrow(element, {msg: `HoobiizWeekPeriods.Tuesday[${i}]`})
      );
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsWednesday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Wednesday'],
      {msg: `HoobiizWeekPeriods.Wednesday`}
    ).map((element, i) => {
      const parsedElement = parseHoobiizTimeOfDay(
        asMapOrThrow(element, {msg: `HoobiizWeekPeriods.Wednesday[${i}]`})
      );
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsThursday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Thursday'],
      {msg: `HoobiizWeekPeriods.Thursday`}
    ).map((element, i) => {
      const parsedElement = parseHoobiizTimeOfDay(
        asMapOrThrow(element, {msg: `HoobiizWeekPeriods.Thursday[${i}]`})
      );
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsFriday = asArrayOrThrow(parsedHoobiizWeekPeriodsMap['Friday'], {
      msg: `HoobiizWeekPeriods.Friday`,
    }).map((element, i) => {
      const parsedElement = parseHoobiizTimeOfDay(
        asMapOrThrow(element, {msg: `HoobiizWeekPeriods.Friday[${i}]`})
      );
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsSaturday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Saturday'],
      {msg: `HoobiizWeekPeriods.Saturday`}
    ).map((element, i) => {
      const parsedElement = parseHoobiizTimeOfDay(
        asMapOrThrow(element, {msg: `HoobiizWeekPeriods.Saturday[${i}]`})
      );
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsSunday = asArrayOrThrow(parsedHoobiizWeekPeriodsMap['Sunday'], {
      msg: `HoobiizWeekPeriods.Sunday`,
    }).map((element, i) => {
      const parsedElement = parseHoobiizTimeOfDay(
        asMapOrThrow(element, {msg: `HoobiizWeekPeriods.Sunday[${i}]`})
      );
      return parsedElement;
    });
    const parsedHoobiizWeekPeriods = {
      Monday: parsedHoobiizWeekPeriodsMonday,
      Tuesday: parsedHoobiizWeekPeriodsTuesday,
      Wednesday: parsedHoobiizWeekPeriodsWednesday,
      Thursday: parsedHoobiizWeekPeriodsThursday,
      Friday: parsedHoobiizWeekPeriodsFriday,
      Saturday: parsedHoobiizWeekPeriodsSaturday,
      Sunday: parsedHoobiizWeekPeriodsSunday,
    };
    return parsedHoobiizWeekPeriods;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizWeekPeriods payload: ${errorAsString(
        err
      )}. Payload for HoobiizWeekPeriods was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTicketInfoOption {
  id: HoobiizTicketInfoOptionId;
  label: string;
  description?: string;
  buyingPrice: CurrencyAmount;
  publicPrice: CurrencyAmount;
  youpiizPrice: CurrencyAmount;
  minQuantity?: number;
  maxQuantity?: number;
}

export function parseHoobiizTicketInfoOption(
  json: Record<string, unknown>
): HoobiizTicketInfoOption {
  try {
    const parsedHoobiizTicketInfoOptionMap = asMapOrThrow(json, {msg: `HoobiizTicketInfoOption`});
    const parsedHoobiizTicketInfoOptionId = asStringOrThrow<HoobiizTicketInfoOptionId>(
      parsedHoobiizTicketInfoOptionMap['id'],
      {msg: `HoobiizTicketInfoOption.id`}
    );
    const parsedHoobiizTicketInfoOptionLabel = asStringOrThrow(
      parsedHoobiizTicketInfoOptionMap['label'],
      {msg: `HoobiizTicketInfoOption.label`}
    );
    const parsedHoobiizTicketInfoOptionDescription =
      parsedHoobiizTicketInfoOptionMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizTicketInfoOptionMap['description'], {
            msg: `HoobiizTicketInfoOption.description`,
          });
    const parsedHoobiizTicketInfoOptionBuyingPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoOptionMap['buyingPrice'], {
        msg: `HoobiizTicketInfoOption.buyingPrice`,
      })
    );
    const parsedHoobiizTicketInfoOptionPublicPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoOptionMap['publicPrice'], {
        msg: `HoobiizTicketInfoOption.publicPrice`,
      })
    );
    const parsedHoobiizTicketInfoOptionYoupiizPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoOptionMap['youpiizPrice'], {
        msg: `HoobiizTicketInfoOption.youpiizPrice`,
      })
    );
    const parsedHoobiizTicketInfoOptionMinQuantity =
      parsedHoobiizTicketInfoOptionMap['minQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoOptionMap['minQuantity'], {
            msg: `HoobiizTicketInfoOption.minQuantity`,
          });
    const parsedHoobiizTicketInfoOptionMaxQuantity =
      parsedHoobiizTicketInfoOptionMap['maxQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoOptionMap['maxQuantity'], {
            msg: `HoobiizTicketInfoOption.maxQuantity`,
          });
    const parsedHoobiizTicketInfoOption = {
      id: parsedHoobiizTicketInfoOptionId,
      label: parsedHoobiizTicketInfoOptionLabel,
      description: parsedHoobiizTicketInfoOptionDescription,
      buyingPrice: parsedHoobiizTicketInfoOptionBuyingPrice,
      publicPrice: parsedHoobiizTicketInfoOptionPublicPrice,
      youpiizPrice: parsedHoobiizTicketInfoOptionYoupiizPrice,
      minQuantity: parsedHoobiizTicketInfoOptionMinQuantity,
      maxQuantity: parsedHoobiizTicketInfoOptionMaxQuantity,
    };
    return parsedHoobiizTicketInfoOption;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTicketInfoOption payload: ${errorAsString(
        err
      )}. Payload for HoobiizTicketInfoOption was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizPrices {
  price1: CurrencyAmount;
  price2: CurrencyAmount;
  price3: CurrencyAmount;
}

export function parseHoobiizPrices(json: Record<string, unknown>): HoobiizPrices {
  try {
    const parsedHoobiizPricesMap = asMapOrThrow(json, {msg: `HoobiizPrices`});
    const parsedHoobiizPricesPrice1 = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizPricesMap['price1'], {msg: `HoobiizPrices.price1`})
    );
    const parsedHoobiizPricesPrice2 = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizPricesMap['price2'], {msg: `HoobiizPrices.price2`})
    );
    const parsedHoobiizPricesPrice3 = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizPricesMap['price3'], {msg: `HoobiizPrices.price3`})
    );
    const parsedHoobiizPrices = {
      price1: parsedHoobiizPricesPrice1,
      price2: parsedHoobiizPricesPrice2,
      price3: parsedHoobiizPricesPrice3,
    };
    return parsedHoobiizPrices;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizPrices payload: ${errorAsString(
        err
      )}. Payload for HoobiizPrices was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizUserProfile {
  permissions?: HoobiizPermission[];
  whiteLabeling?: HoobiizWhiteLabeling;
}

export function parseHoobiizUserProfile(json: Record<string, unknown>): HoobiizUserProfile {
  try {
    const parsedHoobiizUserProfileMap = asMapOrThrow(json, {msg: `HoobiizUserProfile`});
    const parsedHoobiizUserProfilePermissions =
      parsedHoobiizUserProfileMap['permissions'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizUserProfileMap['permissions'], {
            msg: `HoobiizUserProfile.permissions`,
          }).map((element, i) => {
            const parsedElement = parseHoobiizPermission(
              asMapOrThrow(element, {msg: `HoobiizUserProfile.permissions[${i}]`})
            );
            return parsedElement;
          });
    const parsedHoobiizUserProfileWhiteLabeling =
      parsedHoobiizUserProfileMap['whiteLabeling'] === undefined
        ? undefined
        : parseHoobiizWhiteLabeling(
            asMapOrThrow(parsedHoobiizUserProfileMap['whiteLabeling'], {
              msg: `HoobiizUserProfile.whiteLabeling`,
            })
          );
    const parsedHoobiizUserProfile = {
      permissions: parsedHoobiizUserProfilePermissions,
      whiteLabeling: parsedHoobiizUserProfileWhiteLabeling,
    };
    return parsedHoobiizUserProfile;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizUserProfile payload: ${errorAsString(
        err
      )}. Payload for HoobiizUserProfile was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizOpeningHours {
  weekdays: HoobiizWeekPeriods;
  overrides?: HoobiizOpeningHoursOverride[];
}

export function parseHoobiizOpeningHours(json: Record<string, unknown>): HoobiizOpeningHours {
  try {
    const parsedHoobiizOpeningHoursMap = asMapOrThrow(json, {msg: `HoobiizOpeningHours`});
    const parsedHoobiizOpeningHoursWeekdays = parseHoobiizWeekPeriods(
      asMapOrThrow(parsedHoobiizOpeningHoursMap['weekdays'], {msg: `HoobiizOpeningHours.weekdays`})
    );
    const parsedHoobiizOpeningHoursOverrides =
      parsedHoobiizOpeningHoursMap['overrides'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizOpeningHoursMap['overrides'], {
            msg: `HoobiizOpeningHours.overrides`,
          }).map((element, i) => {
            const parsedElement = parseHoobiizOpeningHoursOverride(
              asMapOrThrow(element, {msg: `HoobiizOpeningHours.overrides[${i}]`})
            );
            return parsedElement;
          });
    const parsedHoobiizOpeningHours = {
      weekdays: parsedHoobiizOpeningHoursWeekdays,
      overrides: parsedHoobiizOpeningHoursOverrides,
    };
    return parsedHoobiizOpeningHours;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOpeningHours payload: ${errorAsString(
        err
      )}. Payload for HoobiizOpeningHours was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTicketInfo {
  id: HoobiizTicketInfoId;
  label: string;
  description?: string;
  buyingPrice: CurrencyAmount;
  publicPrice: CurrencyAmount;
  youpiizPrice: CurrencyAmount;
  fees?: CurrencyAmount;
  span?: number;
  visibility: HoobiizVisibility;
  minQuantity?: number;
  maxQuantity?: number;
  options?: HoobiizTicketInfoOption[];
}

export function parseHoobiizTicketInfo(json: Record<string, unknown>): HoobiizTicketInfo {
  try {
    const parsedHoobiizTicketInfoMap = asMapOrThrow(json, {msg: `HoobiizTicketInfo`});
    const parsedHoobiizTicketInfoId = asStringOrThrow<HoobiizTicketInfoId>(
      parsedHoobiizTicketInfoMap['id'],
      {msg: `HoobiizTicketInfo.id`}
    );
    const parsedHoobiizTicketInfoLabel = asStringOrThrow(parsedHoobiizTicketInfoMap['label'], {
      msg: `HoobiizTicketInfo.label`,
    });
    const parsedHoobiizTicketInfoDescription =
      parsedHoobiizTicketInfoMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizTicketInfoMap['description'], {
            msg: `HoobiizTicketInfo.description`,
          });
    const parsedHoobiizTicketInfoBuyingPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoMap['buyingPrice'], {
        msg: `HoobiizTicketInfo.buyingPrice`,
      })
    );
    const parsedHoobiizTicketInfoPublicPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoMap['publicPrice'], {
        msg: `HoobiizTicketInfo.publicPrice`,
      })
    );
    const parsedHoobiizTicketInfoYoupiizPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoMap['youpiizPrice'], {
        msg: `HoobiizTicketInfo.youpiizPrice`,
      })
    );
    const parsedHoobiizTicketInfoFees =
      parsedHoobiizTicketInfoMap['fees'] === undefined
        ? undefined
        : parseCurrencyAmount(
            asMapOrThrow(parsedHoobiizTicketInfoMap['fees'], {msg: `HoobiizTicketInfo.fees`})
          );
    const parsedHoobiizTicketInfoSpan =
      parsedHoobiizTicketInfoMap['span'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoMap['span'], {msg: `HoobiizTicketInfo.span`});
    const parsedHoobiizTicketInfoVisibility = parseHoobiizVisibility(
      asStringOrThrow(parsedHoobiizTicketInfoMap['visibility'], {
        msg: `HoobiizTicketInfo.visibility`,
      })
    );
    const parsedHoobiizTicketInfoMinQuantity =
      parsedHoobiizTicketInfoMap['minQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoMap['minQuantity'], {
            msg: `HoobiizTicketInfo.minQuantity`,
          });
    const parsedHoobiizTicketInfoMaxQuantity =
      parsedHoobiizTicketInfoMap['maxQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoMap['maxQuantity'], {
            msg: `HoobiizTicketInfo.maxQuantity`,
          });
    const parsedHoobiizTicketInfoOptions =
      parsedHoobiizTicketInfoMap['options'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizTicketInfoMap['options'], {
            msg: `HoobiizTicketInfo.options`,
          }).map((element, i) => {
            const parsedElement = parseHoobiizTicketInfoOption(
              asMapOrThrow(element, {msg: `HoobiizTicketInfo.options[${i}]`})
            );
            return parsedElement;
          });
    const parsedHoobiizTicketInfo = {
      id: parsedHoobiizTicketInfoId,
      label: parsedHoobiizTicketInfoLabel,
      description: parsedHoobiizTicketInfoDescription,
      buyingPrice: parsedHoobiizTicketInfoBuyingPrice,
      publicPrice: parsedHoobiizTicketInfoPublicPrice,
      youpiizPrice: parsedHoobiizTicketInfoYoupiizPrice,
      fees: parsedHoobiizTicketInfoFees,
      span: parsedHoobiizTicketInfoSpan,
      visibility: parsedHoobiizTicketInfoVisibility,
      minQuantity: parsedHoobiizTicketInfoMinQuantity,
      maxQuantity: parsedHoobiizTicketInfoMaxQuantity,
      options: parsedHoobiizTicketInfoOptions,
    };
    return parsedHoobiizTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizFlexibleStockScheduleInherit {
  type: HoobiizFlexibleStockScheduleType.Inherit;
}

export function parseHoobiizFlexibleStockScheduleInherit(): Omit<
  HoobiizFlexibleStockScheduleInherit,
  'type'
> {
  return {};
}

export interface HoobiizFlexibleStockScheduleOverride {
  type: HoobiizFlexibleStockScheduleType.Override;
  schedule: HoobiizOpeningHours;
}

export function parseHoobiizFlexibleStockScheduleOverride(
  json: Record<string, unknown>
): Omit<HoobiizFlexibleStockScheduleOverride, 'type'> {
  try {
    const parsedHoobiizFlexibleStockScheduleOverrideMap = asMapOrThrow(json, {
      msg: `HoobiizFlexibleStockScheduleOverride`,
    });
    const parsedHoobiizFlexibleStockScheduleOverrideSchedule = parseHoobiizOpeningHours(
      asMapOrThrow(parsedHoobiizFlexibleStockScheduleOverrideMap['schedule'], {
        msg: `HoobiizFlexibleStockScheduleOverride.schedule`,
      })
    );
    const parsedHoobiizFlexibleStockScheduleOverride = {
      schedule: parsedHoobiizFlexibleStockScheduleOverrideSchedule,
    };
    return parsedHoobiizFlexibleStockScheduleOverride;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizFlexibleStockScheduleOverride: ${errorAsString(err)}`);
  }
}

export type HoobiizFlexibleStockSchedule =
  | HoobiizFlexibleStockScheduleInherit
  | HoobiizFlexibleStockScheduleOverride;

export function parseHoobiizFlexibleStockSchedule(
  json: Record<string, unknown>
): HoobiizFlexibleStockSchedule {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizFlexibleStockSchedule.type'});
    if (type === HoobiizFlexibleStockScheduleType.Inherit) {
      return {type, ...parseHoobiizFlexibleStockScheduleInherit()};
    } else if (type === HoobiizFlexibleStockScheduleType.Override) {
      return {type, ...parseHoobiizFlexibleStockScheduleOverride(json)};
    }
    throw new Error(`Failure to parse HoobiizFlexibleStockSchedule: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizFlexibleStockSchedule: ${errorAsString(err)}.`);
  }
}

export interface HoobiizVendorLocation {
  name: string;
  hideName?: boolean;
  address: AddressLookupResult;
  hideAddress?: boolean;
  phone: string;
  hidePhone?: boolean;
  openingHours: HoobiizOpeningHours;
  hideOpeningHours?: boolean;
  rangeInKm?: number;
}

export function parseHoobiizVendorLocation(json: Record<string, unknown>): HoobiizVendorLocation {
  try {
    const parsedHoobiizVendorLocationMap = asMapOrThrow(json, {msg: `HoobiizVendorLocation`});
    const parsedHoobiizVendorLocationName = asStringOrThrow(
      parsedHoobiizVendorLocationMap['name'],
      {msg: `HoobiizVendorLocation.name`}
    );
    const parsedHoobiizVendorLocationHideName =
      parsedHoobiizVendorLocationMap['hideName'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hideName'], {
            msg: `HoobiizVendorLocation.hideName`,
          });
    const parsedHoobiizVendorLocationAddress = parseAddressLookupResult(
      asMapOrThrow(parsedHoobiizVendorLocationMap['address'], {
        msg: `HoobiizVendorLocation.address`,
      })
    );
    const parsedHoobiizVendorLocationHideAddress =
      parsedHoobiizVendorLocationMap['hideAddress'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hideAddress'], {
            msg: `HoobiizVendorLocation.hideAddress`,
          });
    const parsedHoobiizVendorLocationPhone = asStringOrThrow(
      parsedHoobiizVendorLocationMap['phone'],
      {msg: `HoobiizVendorLocation.phone`}
    );
    const parsedHoobiizVendorLocationHidePhone =
      parsedHoobiizVendorLocationMap['hidePhone'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hidePhone'], {
            msg: `HoobiizVendorLocation.hidePhone`,
          });
    const parsedHoobiizVendorLocationOpeningHours = parseHoobiizOpeningHours(
      asMapOrThrow(parsedHoobiizVendorLocationMap['openingHours'], {
        msg: `HoobiizVendorLocation.openingHours`,
      })
    );
    const parsedHoobiizVendorLocationHideOpeningHours =
      parsedHoobiizVendorLocationMap['hideOpeningHours'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hideOpeningHours'], {
            msg: `HoobiizVendorLocation.hideOpeningHours`,
          });
    const parsedHoobiizVendorLocationRangeInKm =
      parsedHoobiizVendorLocationMap['rangeInKm'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizVendorLocationMap['rangeInKm'], {
            msg: `HoobiizVendorLocation.rangeInKm`,
          });
    const parsedHoobiizVendorLocation = {
      name: parsedHoobiizVendorLocationName,
      hideName: parsedHoobiizVendorLocationHideName,
      address: parsedHoobiizVendorLocationAddress,
      hideAddress: parsedHoobiizVendorLocationHideAddress,
      phone: parsedHoobiizVendorLocationPhone,
      hidePhone: parsedHoobiizVendorLocationHidePhone,
      openingHours: parsedHoobiizVendorLocationOpeningHours,
      hideOpeningHours: parsedHoobiizVendorLocationHideOpeningHours,
      rangeInKm: parsedHoobiizVendorLocationRangeInKm,
    };
    return parsedHoobiizVendorLocation;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorLocation payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorLocation was ${JSON.stringify(json)}`
    );
  }
}

export interface AccountDatalakes {
  ids: DataLakeId[];
}

export function parseAccountDatalakes(
  json: Record<string, unknown> | undefined
): AccountDatalakes | undefined {
  if (json === undefined) {
    return undefined;
  }
  try {
    const parsedAccountDatalakesMap = asMapOrThrow(json, {msg: `AccountDatalakes`});
    const parsedAccountDatalakesIds = asArrayOrThrow(parsedAccountDatalakesMap['ids'], {
      msg: `AccountDatalakes.ids`,
    }).map((element, i) => {
      const parsedElement = asStringOrThrow<DataLakeId>(element, {
        msg: `AccountDatalakes.ids[${i}]`,
      });
      return parsedElement;
    });
    const parsedAccountDatalakes = {
      ids: parsedAccountDatalakesIds,
    };
    return parsedAccountDatalakes;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse AccountDatalakes payload: ${errorAsString(
        err
      )}. Payload for AccountDatalakes was ${JSON.stringify(json)}`
    );
  }
}

export interface AccountItem {
  accountId: AccountId; // a
  createdAt: string; // b
  rootAccountAssociated: boolean; // c
  comment?: string; // d
  datalakes?: AccountDatalakes; // e
}

export interface FrontendUserInviteRedisPayload {
  email: EmailString;
  frontendName: FrontendName;
  host: string;
  isAdmin: boolean;
  authMethods: FrontendUserAuthMethodType[];
  userData?: FrontendUserDataContent;
}

export const FrontendUserInviteRedisPayloadMetadata = {
  email: {type: 'string', brand: 'EmailString'},
  frontendName: {
    type: 'enum',
    enum: [
      {label: 'Admin', value: 'admin'},
      {label: 'Onescale', value: 'www'},
      {label: 'Restaurant', value: 'restaurant'},
      {label: 'AgeOfEmpires', value: 'aoe2'},
      {label: 'Hoobiiz', value: 'hoobiiz'},
    ],
  },
  host: {type: 'string'},
  isAdmin: {type: 'boolean'},
  authMethods: {
    type: 'array',
    schema: {
      type: 'enum',
      enum: [
        {label: 'MagicLink', value: 'MagicLink'},
        {label: 'MfaCode', value: 'MfaCode'},
        {label: 'Password', value: 'Password'},
      ],
    },
  },
  userData: {type: 'reference', name: 'FrontendUserDataContent', optional: true},
};

export function parseFrontendUserInviteRedisPayload(
  json: Record<string, unknown>
): FrontendUserInviteRedisPayload {
  try {
    const parsedFrontendUserInviteRedisPayloadMap = asMapOrThrow(json, {
      msg: `FrontendUserInviteRedisPayload`,
    });
    const parsedFrontendUserInviteRedisPayloadEmail = asStringOrThrow<EmailString>(
      parsedFrontendUserInviteRedisPayloadMap['email'],
      {msg: `FrontendUserInviteRedisPayload.email`}
    );
    const parsedFrontendUserInviteRedisPayloadFrontendName = parseFrontendName(
      asStringOrThrow(parsedFrontendUserInviteRedisPayloadMap['frontendName'], {
        msg: `FrontendUserInviteRedisPayload.frontendName`,
      })
    );
    const parsedFrontendUserInviteRedisPayloadHost = asStringOrThrow(
      parsedFrontendUserInviteRedisPayloadMap['host'],
      {msg: `FrontendUserInviteRedisPayload.host`}
    );
    const parsedFrontendUserInviteRedisPayloadIsAdmin = asBooleanOrThrow(
      parsedFrontendUserInviteRedisPayloadMap['isAdmin'],
      {msg: `FrontendUserInviteRedisPayload.isAdmin`}
    );
    const parsedFrontendUserInviteRedisPayloadAuthMethods = asArrayOrThrow(
      parsedFrontendUserInviteRedisPayloadMap['authMethods'],
      {msg: `FrontendUserInviteRedisPayload.authMethods`}
    ).map((element, i) => {
      const parsedElement = parseFrontendUserAuthMethodType(
        asStringOrThrow(element, {msg: `FrontendUserInviteRedisPayload.authMethods[${i}]`})
      );
      return parsedElement;
    });
    const parsedFrontendUserInviteRedisPayloadUserData =
      parsedFrontendUserInviteRedisPayloadMap['userData'] === undefined
        ? undefined
        : parseFrontendUserDataContent(
            asMapOrThrow(parsedFrontendUserInviteRedisPayloadMap['userData'], {
              msg: `FrontendUserInviteRedisPayload.userData`,
            })
          );
    const parsedFrontendUserInviteRedisPayload = {
      email: parsedFrontendUserInviteRedisPayloadEmail,
      frontendName: parsedFrontendUserInviteRedisPayloadFrontendName,
      host: parsedFrontendUserInviteRedisPayloadHost,
      isAdmin: parsedFrontendUserInviteRedisPayloadIsAdmin,
      authMethods: parsedFrontendUserInviteRedisPayloadAuthMethods,
      userData: parsedFrontendUserInviteRedisPayloadUserData,
    };
    return parsedFrontendUserInviteRedisPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface FrontendUserSessionRedisPayload {
  userId: FrontendUserId;
}

export const FrontendUserSessionRedisPayloadMetadata = {
  userId: {type: 'string', brand: 'FrontendUserId'},
};

export function parseFrontendUserSessionRedisPayload(
  json: Record<string, unknown>
): FrontendUserSessionRedisPayload {
  try {
    const parsedFrontendUserSessionRedisPayloadMap = asMapOrThrow(json, {
      msg: `FrontendUserSessionRedisPayload`,
    });
    const parsedFrontendUserSessionRedisPayloadUserId = asStringOrThrow<FrontendUserId>(
      parsedFrontendUserSessionRedisPayloadMap['userId'],
      {msg: `FrontendUserSessionRedisPayload.userId`}
    );
    const parsedFrontendUserSessionRedisPayload = {
      userId: parsedFrontendUserSessionRedisPayloadUserId,
    };
    return parsedFrontendUserSessionRedisPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface FrontendMagicLinkRedisPayload {
  userId: FrontendUserId;
}

export const FrontendMagicLinkRedisPayloadMetadata = {
  userId: {type: 'string', brand: 'FrontendUserId'},
};

export function parseFrontendMagicLinkRedisPayload(
  json: Record<string, unknown>
): FrontendMagicLinkRedisPayload {
  try {
    const parsedFrontendMagicLinkRedisPayloadMap = asMapOrThrow(json, {
      msg: `FrontendMagicLinkRedisPayload`,
    });
    const parsedFrontendMagicLinkRedisPayloadUserId = asStringOrThrow<FrontendUserId>(
      parsedFrontendMagicLinkRedisPayloadMap['userId'],
      {msg: `FrontendMagicLinkRedisPayload.userId`}
    );
    const parsedFrontendMagicLinkRedisPayload = {
      userId: parsedFrontendMagicLinkRedisPayloadUserId,
    };
    return parsedFrontendMagicLinkRedisPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface CaptchaTokenRedisPayload {
  createdAt: string;
  validAt?: string;
}

export const CaptchaTokenRedisPayloadMetadata = {
  createdAt: {type: 'string'},
  validAt: {type: 'string', optional: true},
};

export function parseCaptchaTokenRedisPayload(
  json: Record<string, unknown>
): CaptchaTokenRedisPayload {
  try {
    const parsedCaptchaTokenRedisPayloadMap = asMapOrThrow(json, {msg: `CaptchaTokenRedisPayload`});
    const parsedCaptchaTokenRedisPayloadCreatedAt = asStringOrThrow(
      parsedCaptchaTokenRedisPayloadMap['createdAt'],
      {msg: `CaptchaTokenRedisPayload.createdAt`}
    );
    const parsedCaptchaTokenRedisPayloadValidAt =
      parsedCaptchaTokenRedisPayloadMap['validAt'] === undefined
        ? undefined
        : asStringOrThrow(parsedCaptchaTokenRedisPayloadMap['validAt'], {
            msg: `CaptchaTokenRedisPayload.validAt`,
          });
    const parsedCaptchaTokenRedisPayload = {
      createdAt: parsedCaptchaTokenRedisPayloadCreatedAt,
      validAt: parsedCaptchaTokenRedisPayloadValidAt,
    };
    return parsedCaptchaTokenRedisPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface RateLimitingRedisPayload {
  amount: number;
}

export const RateLimitingRedisPayloadMetadata = {amount: {type: 'number'}};

export function parseRateLimitingRedisPayload(
  json: Record<string, unknown>
): RateLimitingRedisPayload {
  try {
    const parsedRateLimitingRedisPayloadMap = asMapOrThrow(json, {msg: `RateLimitingRedisPayload`});
    const parsedRateLimitingRedisPayloadAmount = asNumberOrThrow(
      parsedRateLimitingRedisPayloadMap['amount'],
      {msg: `RateLimitingRedisPayload.amount`}
    );
    const parsedRateLimitingRedisPayload = {
      amount: parsedRateLimitingRedisPayloadAmount,
    };
    return parsedRateLimitingRedisPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface FrontendUserEmailVerificationRedisPayload {
  frontendName: FrontendName;
  email: EmailString;
  hash: string;
  salt: string;
  userData?: FrontendUserDataContent;
}

export const FrontendUserEmailVerificationRedisPayloadMetadata = {
  frontendName: {
    type: 'enum',
    enum: [
      {label: 'Admin', value: 'admin'},
      {label: 'Onescale', value: 'www'},
      {label: 'Restaurant', value: 'restaurant'},
      {label: 'AgeOfEmpires', value: 'aoe2'},
      {label: 'Hoobiiz', value: 'hoobiiz'},
    ],
  },
  email: {type: 'string', brand: 'EmailString'},
  hash: {type: 'string'},
  salt: {type: 'string'},
  userData: {type: 'reference', name: 'FrontendUserDataContent', optional: true},
};

export function parseFrontendUserEmailVerificationRedisPayload(
  json: Record<string, unknown>
): FrontendUserEmailVerificationRedisPayload {
  try {
    const parsedFrontendUserEmailVerificationRedisPayloadMap = asMapOrThrow(json, {
      msg: `FrontendUserEmailVerificationRedisPayload`,
    });
    const parsedFrontendUserEmailVerificationRedisPayloadFrontendName = parseFrontendName(
      asStringOrThrow(parsedFrontendUserEmailVerificationRedisPayloadMap['frontendName'], {
        msg: `FrontendUserEmailVerificationRedisPayload.frontendName`,
      })
    );
    const parsedFrontendUserEmailVerificationRedisPayloadEmail = asStringOrThrow<EmailString>(
      parsedFrontendUserEmailVerificationRedisPayloadMap['email'],
      {msg: `FrontendUserEmailVerificationRedisPayload.email`}
    );
    const parsedFrontendUserEmailVerificationRedisPayloadHash = asStringOrThrow(
      parsedFrontendUserEmailVerificationRedisPayloadMap['hash'],
      {msg: `FrontendUserEmailVerificationRedisPayload.hash`}
    );
    const parsedFrontendUserEmailVerificationRedisPayloadSalt = asStringOrThrow(
      parsedFrontendUserEmailVerificationRedisPayloadMap['salt'],
      {msg: `FrontendUserEmailVerificationRedisPayload.salt`}
    );
    const parsedFrontendUserEmailVerificationRedisPayloadUserData =
      parsedFrontendUserEmailVerificationRedisPayloadMap['userData'] === undefined
        ? undefined
        : parseFrontendUserDataContent(
            asMapOrThrow(parsedFrontendUserEmailVerificationRedisPayloadMap['userData'], {
              msg: `FrontendUserEmailVerificationRedisPayload.userData`,
            })
          );
    const parsedFrontendUserEmailVerificationRedisPayload = {
      frontendName: parsedFrontendUserEmailVerificationRedisPayloadFrontendName,
      email: parsedFrontendUserEmailVerificationRedisPayloadEmail,
      hash: parsedFrontendUserEmailVerificationRedisPayloadHash,
      salt: parsedFrontendUserEmailVerificationRedisPayloadSalt,
      userData: parsedFrontendUserEmailVerificationRedisPayloadUserData,
    };
    return parsedFrontendUserEmailVerificationRedisPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface FrontendUserResetPasswordRedisPayload {
  userId: FrontendUserId;
}

export const FrontendUserResetPasswordRedisPayloadMetadata = {
  userId: {type: 'string', brand: 'FrontendUserId'},
};

export function parseFrontendUserResetPasswordRedisPayload(
  json: Record<string, unknown>
): FrontendUserResetPasswordRedisPayload {
  try {
    const parsedFrontendUserResetPasswordRedisPayloadMap = asMapOrThrow(json, {
      msg: `FrontendUserResetPasswordRedisPayload`,
    });
    const parsedFrontendUserResetPasswordRedisPayloadUserId = asStringOrThrow<FrontendUserId>(
      parsedFrontendUserResetPasswordRedisPayloadMap['userId'],
      {msg: `FrontendUserResetPasswordRedisPayload.userId`}
    );
    const parsedFrontendUserResetPasswordRedisPayload = {
      userId: parsedFrontendUserResetPasswordRedisPayloadUserId,
    };
    return parsedFrontendUserResetPasswordRedisPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type RedisPayload<T extends RedisValueType> = T extends RedisValueType.FrontendUserInvite
  ? FrontendUserInviteRedisPayload
  : T extends RedisValueType.FrontendUserSession
    ? FrontendUserSessionRedisPayload
    : T extends RedisValueType.FrontendMagicLink
      ? FrontendMagicLinkRedisPayload
      : T extends RedisValueType.CaptchaToken
        ? CaptchaTokenRedisPayload
        : T extends RedisValueType.RateLimiting
          ? RateLimitingRedisPayload
          : T extends RedisValueType.FrontendUserEmailVerification
            ? FrontendUserEmailVerificationRedisPayload
            : T extends RedisValueType.FrontendUserResetPassword
              ? FrontendUserResetPasswordRedisPayload
              : never;

const redisPayloadParsers = {
  [RedisValueType.FrontendUserInvite]: parseFrontendUserInviteRedisPayload,
  [RedisValueType.FrontendUserSession]: parseFrontendUserSessionRedisPayload,
  [RedisValueType.FrontendMagicLink]: parseFrontendMagicLinkRedisPayload,
  [RedisValueType.CaptchaToken]: parseCaptchaTokenRedisPayload,
  [RedisValueType.RateLimiting]: parseRateLimitingRedisPayload,
  [RedisValueType.FrontendUserEmailVerification]: parseFrontendUserEmailVerificationRedisPayload,
  [RedisValueType.FrontendUserResetPassword]: parseFrontendUserResetPasswordRedisPayload,
};

export function parseRedisPayload(
  type: RedisValueType,
  json: Record<string, unknown>
): RedisPayload<RedisValueType> {
  return redisPayloadParsers[type](json);
}

export const redisPayloadMetadata = {
  [RedisValueType.FrontendUserInvite]: FrontendUserInviteRedisPayloadMetadata,
  [RedisValueType.FrontendUserSession]: FrontendUserSessionRedisPayloadMetadata,
  [RedisValueType.FrontendMagicLink]: FrontendMagicLinkRedisPayloadMetadata,
  [RedisValueType.CaptchaToken]: CaptchaTokenRedisPayloadMetadata,
  [RedisValueType.RateLimiting]: RateLimitingRedisPayloadMetadata,
  [RedisValueType.FrontendUserEmailVerification]: FrontendUserEmailVerificationRedisPayloadMetadata,
  [RedisValueType.FrontendUserResetPassword]: FrontendUserResetPasswordRedisPayloadMetadata,
};

export interface RedisItemGeneric<T extends RedisValueType> {
  redisKey: string;
  redisValueType: T;
  expiresAt: number;
  domainId: string;
  redisValue: RedisPayload<T>;
}

export type FrontendUserInviteRedisItem = RedisItemGeneric<RedisValueType.FrontendUserInvite>;
export type FrontendUserSessionRedisItem = RedisItemGeneric<RedisValueType.FrontendUserSession>;
export type FrontendMagicLinkRedisItem = RedisItemGeneric<RedisValueType.FrontendMagicLink>;
export type CaptchaTokenRedisItem = RedisItemGeneric<RedisValueType.CaptchaToken>;
export type RateLimitingRedisItem = RedisItemGeneric<RedisValueType.RateLimiting>;
export type FrontendUserEmailVerificationRedisItem =
  RedisItemGeneric<RedisValueType.FrontendUserEmailVerification>;
export type FrontendUserResetPasswordRedisItem =
  RedisItemGeneric<RedisValueType.FrontendUserResetPassword>;

export type RedisItem =
  | FrontendUserInviteRedisItem
  | FrontendUserSessionRedisItem
  | FrontendMagicLinkRedisItem
  | CaptchaTokenRedisItem
  | RateLimitingRedisItem
  | FrontendUserEmailVerificationRedisItem
  | FrontendUserResetPasswordRedisItem;

export interface FrontendUserAuthMethodMagicLink {
  type: FrontendUserAuthMethodType.MagicLink;
}

export function parseFrontendUserAuthMethodMagicLink(): Omit<
  FrontendUserAuthMethodMagicLink,
  'type'
> {
  return {};
}

export interface FrontendUserAuthMethodMfaCode {
  type: FrontendUserAuthMethodType.MfaCode;
  totpSecret: string;
  keyVersion?: number;
}

export function parseFrontendUserAuthMethodMfaCode(
  json: Record<string, unknown>
): Omit<FrontendUserAuthMethodMfaCode, 'type'> {
  try {
    const parsedFrontendUserAuthMethodMfaCodeMap = asMapOrThrow(json, {
      msg: `FrontendUserAuthMethodMfaCode`,
    });
    const parsedFrontendUserAuthMethodMfaCodeTotpSecret = asStringOrThrow(
      parsedFrontendUserAuthMethodMfaCodeMap['totpSecret'],
      {msg: `FrontendUserAuthMethodMfaCode.totpSecret`}
    );
    const parsedFrontendUserAuthMethodMfaCodeKeyVersion =
      parsedFrontendUserAuthMethodMfaCodeMap['keyVersion'] === undefined
        ? undefined
        : asNumberOrThrow(parsedFrontendUserAuthMethodMfaCodeMap['keyVersion'], {
            msg: `FrontendUserAuthMethodMfaCode.keyVersion`,
          });
    const parsedFrontendUserAuthMethodMfaCode = {
      totpSecret: parsedFrontendUserAuthMethodMfaCodeTotpSecret,
      keyVersion: parsedFrontendUserAuthMethodMfaCodeKeyVersion,
    };
    return parsedFrontendUserAuthMethodMfaCode;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserAuthMethodMfaCode: ${errorAsString(err)}`);
  }
}

export interface FrontendUserAuthMethodPassword {
  type: FrontendUserAuthMethodType.Password;
  hash: string;
  salt: string;
}

export function parseFrontendUserAuthMethodPassword(
  json: Record<string, unknown>
): Omit<FrontendUserAuthMethodPassword, 'type'> {
  try {
    const parsedFrontendUserAuthMethodPasswordMap = asMapOrThrow(json, {
      msg: `FrontendUserAuthMethodPassword`,
    });
    const parsedFrontendUserAuthMethodPasswordHash = asStringOrThrow(
      parsedFrontendUserAuthMethodPasswordMap['hash'],
      {msg: `FrontendUserAuthMethodPassword.hash`}
    );
    const parsedFrontendUserAuthMethodPasswordSalt = asStringOrThrow(
      parsedFrontendUserAuthMethodPasswordMap['salt'],
      {msg: `FrontendUserAuthMethodPassword.salt`}
    );
    const parsedFrontendUserAuthMethodPassword = {
      hash: parsedFrontendUserAuthMethodPasswordHash,
      salt: parsedFrontendUserAuthMethodPasswordSalt,
    };
    return parsedFrontendUserAuthMethodPassword;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserAuthMethodPassword: ${errorAsString(err)}`);
  }
}

export type FrontendUserAuthMethod =
  | FrontendUserAuthMethodMagicLink
  | FrontendUserAuthMethodMfaCode
  | FrontendUserAuthMethodPassword;

export function parseFrontendUserAuthMethod(json: Record<string, unknown>): FrontendUserAuthMethod {
  try {
    const type = asStringOrThrow(json.type, {msg: 'FrontendUserAuthMethod.type'});
    if (type === FrontendUserAuthMethodType.MagicLink) {
      return {type, ...parseFrontendUserAuthMethodMagicLink()};
    } else if (type === FrontendUserAuthMethodType.MfaCode) {
      return {type, ...parseFrontendUserAuthMethodMfaCode(json)};
    } else if (type === FrontendUserAuthMethodType.Password) {
      return {type, ...parseFrontendUserAuthMethodPassword(json)};
    }
    throw new Error(`Failure to parse FrontendUserAuthMethod: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserAuthMethod: ${errorAsString(err)}.`);
  }
}

export interface FrontendUserAuth {
  methods: FrontendUserAuthMethod[];
}

export function parseFrontendUserAuth(json: Record<string, unknown>): FrontendUserAuth {
  try {
    const parsedFrontendUserAuthMap = asMapOrThrow(json, {msg: `FrontendUserAuth`});
    const parsedFrontendUserAuthMethods = asArrayOrThrow(parsedFrontendUserAuthMap['methods'], {
      msg: `FrontendUserAuth.methods`,
    }).map((element, i) => {
      const parsedElement = parseFrontendUserAuthMethod(
        asMapOrThrow(element, {msg: `FrontendUserAuth.methods[${i}]`})
      );
      return parsedElement;
    });
    const parsedFrontendUserAuth = {
      methods: parsedFrontendUserAuthMethods,
    };
    return parsedFrontendUserAuth;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse FrontendUserAuth payload: ${errorAsString(
        err
      )}. Payload for FrontendUserAuth was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendUserData {
  content?: FrontendUserDataContent;
}

export function parseFrontendUserData(json: Record<string, unknown>): FrontendUserData {
  try {
    const parsedFrontendUserDataMap = asMapOrThrow(json, {msg: `FrontendUserData`});
    const parsedFrontendUserDataContent =
      parsedFrontendUserDataMap['content'] === undefined
        ? undefined
        : parseFrontendUserDataContent(
            asMapOrThrow(parsedFrontendUserDataMap['content'], {msg: `FrontendUserData.content`})
          );
    const parsedFrontendUserData = {
      content: parsedFrontendUserDataContent,
    };
    return parsedFrontendUserData;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse FrontendUserData payload: ${errorAsString(
        err
      )}. Payload for FrontendUserData was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendUserItem {
  userId: FrontendUserId; // a
  frontendName: FrontendName; // b
  email: EmailString; // c
  createdAt: string; // d
  isAdmin: boolean; // e
  sessionDuration: SessionDuration; // f
  auth: FrontendUserAuth; // g
  data: FrontendUserData; // h
}

export interface LockItem {
  lockName: string; // a
  expiresAt: number; // b
  uid: string; // c
  ttl?: number; // d
}

export interface MaestroDataItem {
  serviceName: string; // a
  targetInfoPayload: string; // b
  adminUpdatePayload?: string; // c
  frozen?: boolean; // d
  slowStartOverride?: number; // e
}

export interface MonitoringItem {
  serviceName: string; // a
  breakdownStartTarget: string; // b
  ttl: number; // c
  count: number; // d
  cpuSum: number; // e
  memorySum: number; // f
  cpuCreditBalanceSum: number; // g
  diskSum: number; // h
  requestsSum: number; // i
}

export interface StreamShardItem {
  streamArn: StreamArn; // a
  shardId: ShardId; // b
  sequenceNumber: SequenceNumber; // c
}

export interface DetailedMonitoringItem {
  version: string; // a
  timeSecondsWithRandomSuffix: string; // b
  ttl: number; // c
  serviceName: ServiceName; // d
  serviceNodeId: ServiceNodeId; // e
  targetString: TargetString; // f
  timeSeconds: number; // g
  cpu: number; // h
  memory: number; // i
  disk: number; // j
  requests: number; // k
  cpuCreditBalance: number; // l
  startTime: string; // m
  drainingTime?: string; // n
  terminationNotice?: string; // o
}

export interface BuildItem {
  buildId: BuildId; // a
  commitHash: CommitHash; // b
  status: BuildStatus; // c
  initiatedAt: number; // d
  lastUpdate: number; // e
  payload?: string; // f
}

export interface AnalyticsDataItem {
  datalakeElementId: DatalakeElementItemId; // a
  uniqueUserCount: number; // b
  topCountries: string; // c
  topLocales: string; // d
  topReferers: string; // e
  from: number; // f
  to: number; // g
  range: string; // h
  pageViews: number; // i
}

export interface TaskHistory {
  statuses: {ts: number; from?: TaskStatus; to: TaskStatus}[];
}

export function parseTaskHistory(
  json: Record<string, unknown> | undefined
): TaskHistory | undefined {
  if (json === undefined) {
    return undefined;
  }
  try {
    const parsedTaskHistoryMap = asMapOrThrow(json, {msg: `TaskHistory`});
    const parsedTaskHistoryStatuses = asArrayOrThrow(parsedTaskHistoryMap['statuses'], {
      msg: `TaskHistory.statuses`,
    }).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {msg: `TaskHistory.statuses[${i}]`});
      const parsedElementTs = asNumberOrThrow(parsedElementMap['ts'], {
        msg: `TaskHistory.statuses[${i}].ts`,
      });
      const parsedElementFrom =
        parsedElementMap['from'] === undefined
          ? undefined
          : parseTaskStatus(
              asNumberOrThrow(parsedElementMap['from'], {msg: `TaskHistory.statuses[${i}].from`})
            );
      const parsedElementTo = parseTaskStatus(
        asNumberOrThrow(parsedElementMap['to'], {msg: `TaskHistory.statuses[${i}].to`})
      );
      const parsedElement = {
        ts: parsedElementTs,
        from: parsedElementFrom,
        to: parsedElementTo,
      };
      return parsedElement;
    });
    const parsedTaskHistory = {
      statuses: parsedTaskHistoryStatuses,
    };
    return parsedTaskHistory;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse TaskHistory payload: ${errorAsString(
        err
      )}. Payload for TaskHistory was ${JSON.stringify(json)}`
    );
  }
}

export interface AnalyticsDedupTaskPayload {
  datalakeElementId: DatalakeElementItemId;
  from: number;
  to: number;
}

export const AnalyticsDedupTaskPayloadMetadata = {
  datalakeElementId: {type: 'string', brand: 'DatalakeElementItemId'},
  from: {type: 'number'},
  to: {type: 'number'},
};

export function parseAnalyticsDedupTaskPayload(
  json: Record<string, unknown>
): AnalyticsDedupTaskPayload {
  try {
    const parsedAnalyticsDedupTaskPayloadMap = asMapOrThrow(json, {
      msg: `AnalyticsDedupTaskPayload`,
    });
    const parsedAnalyticsDedupTaskPayloadDatalakeElementId = asStringOrThrow<DatalakeElementItemId>(
      parsedAnalyticsDedupTaskPayloadMap['datalakeElementId'],
      {msg: `AnalyticsDedupTaskPayload.datalakeElementId`}
    );
    const parsedAnalyticsDedupTaskPayloadFrom = asNumberOrThrow(
      parsedAnalyticsDedupTaskPayloadMap['from'],
      {msg: `AnalyticsDedupTaskPayload.from`}
    );
    const parsedAnalyticsDedupTaskPayloadTo = asNumberOrThrow(
      parsedAnalyticsDedupTaskPayloadMap['to'],
      {msg: `AnalyticsDedupTaskPayload.to`}
    );
    const parsedAnalyticsDedupTaskPayload = {
      datalakeElementId: parsedAnalyticsDedupTaskPayloadDatalakeElementId,
      from: parsedAnalyticsDedupTaskPayloadFrom,
      to: parsedAnalyticsDedupTaskPayloadTo,
    };
    return parsedAnalyticsDedupTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface AnalyticsAggregationTaskPayload {
  datalakeElementId: DatalakeElementItemId;
  from: number;
  to: number;
}

export const AnalyticsAggregationTaskPayloadMetadata = {
  datalakeElementId: {type: 'string', brand: 'DatalakeElementItemId'},
  from: {type: 'number'},
  to: {type: 'number'},
};

export function parseAnalyticsAggregationTaskPayload(
  json: Record<string, unknown>
): AnalyticsAggregationTaskPayload {
  try {
    const parsedAnalyticsAggregationTaskPayloadMap = asMapOrThrow(json, {
      msg: `AnalyticsAggregationTaskPayload`,
    });
    const parsedAnalyticsAggregationTaskPayloadDatalakeElementId =
      asStringOrThrow<DatalakeElementItemId>(
        parsedAnalyticsAggregationTaskPayloadMap['datalakeElementId'],
        {msg: `AnalyticsAggregationTaskPayload.datalakeElementId`}
      );
    const parsedAnalyticsAggregationTaskPayloadFrom = asNumberOrThrow(
      parsedAnalyticsAggregationTaskPayloadMap['from'],
      {msg: `AnalyticsAggregationTaskPayload.from`}
    );
    const parsedAnalyticsAggregationTaskPayloadTo = asNumberOrThrow(
      parsedAnalyticsAggregationTaskPayloadMap['to'],
      {msg: `AnalyticsAggregationTaskPayload.to`}
    );
    const parsedAnalyticsAggregationTaskPayload = {
      datalakeElementId: parsedAnalyticsAggregationTaskPayloadDatalakeElementId,
      from: parsedAnalyticsAggregationTaskPayloadFrom,
      to: parsedAnalyticsAggregationTaskPayloadTo,
    };
    return parsedAnalyticsAggregationTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface BuildTaskPayload {
  buildId: BuildId;
  envOverride?: string;
}

export const BuildTaskPayloadMetadata = {
  buildId: {type: 'string', brand: 'BuildId'},
  envOverride: {type: 'string', optional: true},
};

export function parseBuildTaskPayload(json: Record<string, unknown>): BuildTaskPayload {
  try {
    const parsedBuildTaskPayloadMap = asMapOrThrow(json, {msg: `BuildTaskPayload`});
    const parsedBuildTaskPayloadBuildId = asStringOrThrow<BuildId>(
      parsedBuildTaskPayloadMap['buildId'],
      {msg: `BuildTaskPayload.buildId`}
    );
    const parsedBuildTaskPayloadEnvOverride =
      parsedBuildTaskPayloadMap['envOverride'] === undefined
        ? undefined
        : asStringOrThrow(parsedBuildTaskPayloadMap['envOverride'], {
            msg: `BuildTaskPayload.envOverride`,
          });
    const parsedBuildTaskPayload = {
      buildId: parsedBuildTaskPayloadBuildId,
      envOverride: parsedBuildTaskPayloadEnvOverride,
    };
    return parsedBuildTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface DataLakeTaskPayload {
  elementId: DatalakeElementItemId;
  partitionType: DatalakePartitionType;
  start?: number;
}

export const DataLakeTaskPayloadMetadata = {
  elementId: {type: 'string', brand: 'DatalakeElementItemId'},
  partitionType: {
    type: 'enum',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
  },
  start: {type: 'number', optional: true},
};

export function parseDataLakeTaskPayload(json: Record<string, unknown>): DataLakeTaskPayload {
  try {
    const parsedDataLakeTaskPayloadMap = asMapOrThrow(json, {msg: `DataLakeTaskPayload`});
    const parsedDataLakeTaskPayloadElementId = asStringOrThrow<DatalakeElementItemId>(
      parsedDataLakeTaskPayloadMap['elementId'],
      {msg: `DataLakeTaskPayload.elementId`}
    );
    const parsedDataLakeTaskPayloadPartitionType = parseDatalakePartitionType(
      asNumberOrThrow(parsedDataLakeTaskPayloadMap['partitionType'], {
        msg: `DataLakeTaskPayload.partitionType`,
      })
    );
    const parsedDataLakeTaskPayloadStart =
      parsedDataLakeTaskPayloadMap['start'] === undefined
        ? undefined
        : asNumberOrThrow(parsedDataLakeTaskPayloadMap['start'], {
            msg: `DataLakeTaskPayload.start`,
          });
    const parsedDataLakeTaskPayload = {
      elementId: parsedDataLakeTaskPayloadElementId,
      partitionType: parsedDataLakeTaskPayloadPartitionType,
      start: parsedDataLakeTaskPayloadStart,
    };
    return parsedDataLakeTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface QontoDataSourceTaskPayload {
  datalakeId: DataLakeId;
  dataSourceId: DatalakeElementItemId;
}

export const QontoDataSourceTaskPayloadMetadata = {
  datalakeId: {type: 'string', brand: 'DataLakeId'},
  dataSourceId: {type: 'string', brand: 'DatalakeElementItemId'},
};

export function parseQontoDataSourceTaskPayload(
  json: Record<string, unknown>
): QontoDataSourceTaskPayload {
  try {
    const parsedQontoDataSourceTaskPayloadMap = asMapOrThrow(json, {
      msg: `QontoDataSourceTaskPayload`,
    });
    const parsedQontoDataSourceTaskPayloadDatalakeId = asStringOrThrow<DataLakeId>(
      parsedQontoDataSourceTaskPayloadMap['datalakeId'],
      {msg: `QontoDataSourceTaskPayload.datalakeId`}
    );
    const parsedQontoDataSourceTaskPayloadDataSourceId = asStringOrThrow<DatalakeElementItemId>(
      parsedQontoDataSourceTaskPayloadMap['dataSourceId'],
      {msg: `QontoDataSourceTaskPayload.dataSourceId`}
    );
    const parsedQontoDataSourceTaskPayload = {
      datalakeId: parsedQontoDataSourceTaskPayloadDatalakeId,
      dataSourceId: parsedQontoDataSourceTaskPayloadDataSourceId,
    };
    return parsedQontoDataSourceTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface ReportingTaskPayload {
  reportingId: ReportingId;
  breakdown: ReportingBreakdown;
  date: string;
}

export const ReportingTaskPayloadMetadata = {
  reportingId: {type: 'string', brand: 'ReportingId'},
  breakdown: {
    type: 'enum',
    enum: [
      {label: 'Minutely', value: 10},
      {label: 'Hourly', value: 20},
    ],
  },
  date: {type: 'string'},
};

export function parseReportingTaskPayload(json: Record<string, unknown>): ReportingTaskPayload {
  try {
    const parsedReportingTaskPayloadMap = asMapOrThrow(json, {msg: `ReportingTaskPayload`});
    const parsedReportingTaskPayloadReportingId = asStringOrThrow<ReportingId>(
      parsedReportingTaskPayloadMap['reportingId'],
      {msg: `ReportingTaskPayload.reportingId`}
    );
    const parsedReportingTaskPayloadBreakdown = parseReportingBreakdown(
      asNumberOrThrow(parsedReportingTaskPayloadMap['breakdown'], {
        msg: `ReportingTaskPayload.breakdown`,
      })
    );
    const parsedReportingTaskPayloadDate = asStringOrThrow(parsedReportingTaskPayloadMap['date'], {
      msg: `ReportingTaskPayload.date`,
    });
    const parsedReportingTaskPayload = {
      reportingId: parsedReportingTaskPayloadReportingId,
      breakdown: parsedReportingTaskPayloadBreakdown,
      date: parsedReportingTaskPayloadDate,
    };
    return parsedReportingTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface HoobiizTicketsTaskPayload {}

export const HoobiizTicketsTaskPayloadMetadata = {};

export function parseHoobiizTicketsTaskPayload(
  json: Record<string, unknown>
): HoobiizTicketsTaskPayload {
  try {
    const parsedHoobiizTicketsTaskPayload = {};
    return parsedHoobiizTicketsTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface HoobiizMediaTaskPayload {
  bucket: string;
  objectKey: string;
}

export const HoobiizMediaTaskPayloadMetadata = {
  bucket: {type: 'string'},
  objectKey: {type: 'string'},
};

export function parseHoobiizMediaTaskPayload(
  json: Record<string, unknown>
): HoobiizMediaTaskPayload {
  try {
    const parsedHoobiizMediaTaskPayloadMap = asMapOrThrow(json, {msg: `HoobiizMediaTaskPayload`});
    const parsedHoobiizMediaTaskPayloadBucket = asStringOrThrow(
      parsedHoobiizMediaTaskPayloadMap['bucket'],
      {msg: `HoobiizMediaTaskPayload.bucket`}
    );
    const parsedHoobiizMediaTaskPayloadObjectKey = asStringOrThrow(
      parsedHoobiizMediaTaskPayloadMap['objectKey'],
      {msg: `HoobiizMediaTaskPayload.objectKey`}
    );
    const parsedHoobiizMediaTaskPayload = {
      bucket: parsedHoobiizMediaTaskPayloadBucket,
      objectKey: parsedHoobiizMediaTaskPayloadObjectKey,
    };
    return parsedHoobiizMediaTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface ExpertTicketTaskPayload {}

export const ExpertTicketTaskPayloadMetadata = {};

export function parseExpertTicketTaskPayload(
  json: Record<string, unknown>
): ExpertTicketTaskPayload {
  try {
    const parsedExpertTicketTaskPayload = {};
    return parsedExpertTicketTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface HoobiizPdfTaskPayload {
  orderId: HoobiizOrderItemId;
}

export const HoobiizPdfTaskPayloadMetadata = {
  orderId: {type: 'string', brand: 'HoobiizOrderItemId'},
};

export function parseHoobiizPdfTaskPayload(json: Record<string, unknown>): HoobiizPdfTaskPayload {
  try {
    const parsedHoobiizPdfTaskPayloadMap = asMapOrThrow(json, {msg: `HoobiizPdfTaskPayload`});
    const parsedHoobiizPdfTaskPayloadOrderId = asStringOrThrow<HoobiizOrderItemId>(
      parsedHoobiizPdfTaskPayloadMap['orderId'],
      {msg: `HoobiizPdfTaskPayload.orderId`}
    );
    const parsedHoobiizPdfTaskPayload = {
      orderId: parsedHoobiizPdfTaskPayloadOrderId,
    };
    return parsedHoobiizPdfTaskPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type TaskPayload<T extends TaskType> = T extends TaskType.AnalyticsDedup
  ? AnalyticsDedupTaskPayload
  : T extends TaskType.AnalyticsAggregation
    ? AnalyticsAggregationTaskPayload
    : T extends TaskType.Build
      ? BuildTaskPayload
      : T extends TaskType.DataLake
        ? DataLakeTaskPayload
        : T extends TaskType.QontoDataSource
          ? QontoDataSourceTaskPayload
          : T extends TaskType.Reporting
            ? ReportingTaskPayload
            : T extends TaskType.HoobiizTickets
              ? HoobiizTicketsTaskPayload
              : T extends TaskType.HoobiizMedia
                ? HoobiizMediaTaskPayload
                : T extends TaskType.ExpertTicket
                  ? ExpertTicketTaskPayload
                  : T extends TaskType.HoobiizPdf
                    ? HoobiizPdfTaskPayload
                    : never;

const taskPayloadParsers = {
  [TaskType.AnalyticsDedup]: parseAnalyticsDedupTaskPayload,
  [TaskType.AnalyticsAggregation]: parseAnalyticsAggregationTaskPayload,
  [TaskType.Build]: parseBuildTaskPayload,
  [TaskType.DataLake]: parseDataLakeTaskPayload,
  [TaskType.QontoDataSource]: parseQontoDataSourceTaskPayload,
  [TaskType.Reporting]: parseReportingTaskPayload,
  [TaskType.HoobiizTickets]: parseHoobiizTicketsTaskPayload,
  [TaskType.HoobiizMedia]: parseHoobiizMediaTaskPayload,
  [TaskType.ExpertTicket]: parseExpertTicketTaskPayload,
  [TaskType.HoobiizPdf]: parseHoobiizPdfTaskPayload,
};

export function parseTaskPayload(
  type: TaskType,
  json: Record<string, unknown>
): TaskPayload<TaskType> {
  return taskPayloadParsers[type](json);
}

export const taskPayloadMetadata = {
  [TaskType.AnalyticsDedup]: AnalyticsDedupTaskPayloadMetadata,
  [TaskType.AnalyticsAggregation]: AnalyticsAggregationTaskPayloadMetadata,
  [TaskType.Build]: BuildTaskPayloadMetadata,
  [TaskType.DataLake]: DataLakeTaskPayloadMetadata,
  [TaskType.QontoDataSource]: QontoDataSourceTaskPayloadMetadata,
  [TaskType.Reporting]: ReportingTaskPayloadMetadata,
  [TaskType.HoobiizTickets]: HoobiizTicketsTaskPayloadMetadata,
  [TaskType.HoobiizMedia]: HoobiizMediaTaskPayloadMetadata,
  [TaskType.ExpertTicket]: ExpertTicketTaskPayloadMetadata,
  [TaskType.HoobiizPdf]: HoobiizPdfTaskPayloadMetadata,
};

export interface TaskItemGeneric<T extends TaskType> {
  taskId: TaskId;
  domainId: string;
  status: TaskStatus;
  creationTime: number;
  startTime?: number;
  endTime?: number;
  heartBeat?: number;
  taskType: T;
  doNotProcessBefore: number;
  version: number;
  hasChild: boolean;
  reason?: string;
  instanceInfo?: string;
  payload: TaskPayload<T>;
  instanceRequestId?: InstanceRequestId;
  history?: TaskHistory;
  results?: string;
}

export type AnalyticsDedupTaskItem = TaskItemGeneric<TaskType.AnalyticsDedup>;
export type AnalyticsAggregationTaskItem = TaskItemGeneric<TaskType.AnalyticsAggregation>;
export type BuildTaskItem = TaskItemGeneric<TaskType.Build>;
export type DataLakeTaskItem = TaskItemGeneric<TaskType.DataLake>;
export type QontoDataSourceTaskItem = TaskItemGeneric<TaskType.QontoDataSource>;
export type ReportingTaskItem = TaskItemGeneric<TaskType.Reporting>;
export type HoobiizTicketsTaskItem = TaskItemGeneric<TaskType.HoobiizTickets>;
export type HoobiizMediaTaskItem = TaskItemGeneric<TaskType.HoobiizMedia>;
export type ExpertTicketTaskItem = TaskItemGeneric<TaskType.ExpertTicket>;
export type HoobiizPdfTaskItem = TaskItemGeneric<TaskType.HoobiizPdf>;

export type TaskItem =
  | AnalyticsDedupTaskItem
  | AnalyticsAggregationTaskItem
  | BuildTaskItem
  | DataLakeTaskItem
  | QontoDataSourceTaskItem
  | ReportingTaskItem
  | HoobiizTicketsTaskItem
  | HoobiizMediaTaskItem
  | ExpertTicketTaskItem
  | HoobiizPdfTaskItem;

export interface TaskPolicyItem {
  taskType: TaskType; // a
  maxParallelTasks: number; // b
  heartBeatPeriodMs: number; // c
  maxHeartBeatAgeMs: number; // d
  maxInFlightTimeMs: number; // e
  minVcpus: number; // f
  minMemory: number; // g
  commitHash: CommitHash; // h
  maxScheduledTimeMs: number; // i
  maxRescheduleCount: number; // j
  autoSleepPeriodMs: number; // k
  autoSleepDurationMs: number; // l
  minTimeBeforeTaskCheckMs: number; // m
  burstable?: boolean; // n
  minReleaseYear?: number; // o
  architecture?: string; // p
}

export interface ParameterItem {
  parameterKey: ParameterKey; // a
  stringValue?: string; // b
  numberValue?: number; // c
  booleanValue?: boolean; // d
  isSecret?: boolean; // e
}

export interface InstanceTypeInstanceRequestPayload {
  serviceName: string;
  instanceTypeName: InstanceTypeName;
  subnetIds: string[];
  commitHash: CommitHash;
  maxRunningDurationMs?: number;
  envVars: {BUILD_ID?: BuildId; TASK_ID?: TaskId; TASK_VERSION?: string};
}

export const InstanceTypeInstanceRequestPayloadMetadata = {
  serviceName: {type: 'string'},
  instanceTypeName: {type: 'string', brand: 'InstanceTypeName'},
  subnetIds: {type: 'array', schema: {type: 'string'}},
  commitHash: {type: 'string', brand: 'CommitHash'},
  maxRunningDurationMs: {type: 'number', optional: true},
  envVars: {
    type: 'object',
    schema: {
      BUILD_ID: {type: 'string', brand: 'BuildId', optional: true},
      TASK_ID: {type: 'string', brand: 'TaskId', optional: true},
      TASK_VERSION: {type: 'string', optional: true},
    },
  },
};

export function parseInstanceTypeInstanceRequestPayload(
  json: Record<string, unknown>
): InstanceTypeInstanceRequestPayload {
  try {
    const parsedInstanceTypeInstanceRequestPayloadMap = asMapOrThrow(json, {
      msg: `InstanceTypeInstanceRequestPayload`,
    });
    const parsedInstanceTypeInstanceRequestPayloadServiceName = asStringOrThrow(
      parsedInstanceTypeInstanceRequestPayloadMap['serviceName'],
      {msg: `InstanceTypeInstanceRequestPayload.serviceName`}
    );
    const parsedInstanceTypeInstanceRequestPayloadInstanceTypeName =
      asStringOrThrow<InstanceTypeName>(
        parsedInstanceTypeInstanceRequestPayloadMap['instanceTypeName'],
        {msg: `InstanceTypeInstanceRequestPayload.instanceTypeName`}
      );
    const parsedInstanceTypeInstanceRequestPayloadSubnetIds = asArrayOrThrow(
      parsedInstanceTypeInstanceRequestPayloadMap['subnetIds'],
      {msg: `InstanceTypeInstanceRequestPayload.subnetIds`}
    ).map((element, i) => {
      const parsedElement = asStringOrThrow(element, {
        msg: `InstanceTypeInstanceRequestPayload.subnetIds[${i}]`,
      });
      return parsedElement;
    });
    const parsedInstanceTypeInstanceRequestPayloadCommitHash = asStringOrThrow<CommitHash>(
      parsedInstanceTypeInstanceRequestPayloadMap['commitHash'],
      {msg: `InstanceTypeInstanceRequestPayload.commitHash`}
    );
    const parsedInstanceTypeInstanceRequestPayloadMaxRunningDurationMs =
      parsedInstanceTypeInstanceRequestPayloadMap['maxRunningDurationMs'] === undefined
        ? undefined
        : asNumberOrThrow(parsedInstanceTypeInstanceRequestPayloadMap['maxRunningDurationMs'], {
            msg: `InstanceTypeInstanceRequestPayload.maxRunningDurationMs`,
          });
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsMap = asMapOrThrow(
      parsedInstanceTypeInstanceRequestPayloadMap['envVars'],
      {msg: `InstanceTypeInstanceRequestPayload.envVars`}
    );
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsBUILD_ID =
      parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['BUILD_ID'] === undefined
        ? undefined
        : asStringOrThrow<BuildId>(parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['BUILD_ID'], {
            msg: `InstanceTypeInstanceRequestPayload.envVars.BUILD_ID`,
          });
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_ID =
      parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_ID'] === undefined
        ? undefined
        : asStringOrThrow<TaskId>(parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_ID'], {
            msg: `InstanceTypeInstanceRequestPayload.envVars.TASK_ID`,
          });
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_VERSION =
      parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_VERSION'] === undefined
        ? undefined
        : asStringOrThrow(parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_VERSION'], {
            msg: `InstanceTypeInstanceRequestPayload.envVars.TASK_VERSION`,
          });
    const parsedInstanceTypeInstanceRequestPayloadEnvVars = {
      BUILD_ID: parsedInstanceTypeInstanceRequestPayloadEnvVarsBUILD_ID,
      TASK_ID: parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_ID,
      TASK_VERSION: parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_VERSION,
    };
    const parsedInstanceTypeInstanceRequestPayload = {
      serviceName: parsedInstanceTypeInstanceRequestPayloadServiceName,
      instanceTypeName: parsedInstanceTypeInstanceRequestPayloadInstanceTypeName,
      subnetIds: parsedInstanceTypeInstanceRequestPayloadSubnetIds,
      commitHash: parsedInstanceTypeInstanceRequestPayloadCommitHash,
      maxRunningDurationMs: parsedInstanceTypeInstanceRequestPayloadMaxRunningDurationMs,
      envVars: parsedInstanceTypeInstanceRequestPayloadEnvVars,
    };
    return parsedInstanceTypeInstanceRequestPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface MinVcpusMemoryInstanceRequestPayload {
  serviceName: string;
  minVcpus: number;
  minMemory: number;
  burstable?: boolean;
  minReleaseYear?: number;
  architecture?: string;
  subnetIds: string[];
  commitHash: CommitHash;
  maxRunningDurationMs?: number;
  envVars: {BUILD_ID?: BuildId; TASK_ID?: TaskId; TASK_VERSION?: string};
}

export const MinVcpusMemoryInstanceRequestPayloadMetadata = {
  serviceName: {type: 'string'},
  minVcpus: {type: 'number'},
  minMemory: {type: 'number'},
  burstable: {type: 'boolean', optional: true},
  minReleaseYear: {type: 'number', optional: true},
  architecture: {type: 'string', optional: true},
  subnetIds: {type: 'array', schema: {type: 'string'}},
  commitHash: {type: 'string', brand: 'CommitHash'},
  maxRunningDurationMs: {type: 'number', optional: true},
  envVars: {
    type: 'object',
    schema: {
      BUILD_ID: {type: 'string', brand: 'BuildId', optional: true},
      TASK_ID: {type: 'string', brand: 'TaskId', optional: true},
      TASK_VERSION: {type: 'string', optional: true},
    },
  },
};

export function parseMinVcpusMemoryInstanceRequestPayload(
  json: Record<string, unknown>
): MinVcpusMemoryInstanceRequestPayload {
  try {
    const parsedMinVcpusMemoryInstanceRequestPayloadMap = asMapOrThrow(json, {
      msg: `MinVcpusMemoryInstanceRequestPayload`,
    });
    const parsedMinVcpusMemoryInstanceRequestPayloadServiceName = asStringOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['serviceName'],
      {msg: `MinVcpusMemoryInstanceRequestPayload.serviceName`}
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadMinVcpus = asNumberOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['minVcpus'],
      {msg: `MinVcpusMemoryInstanceRequestPayload.minVcpus`}
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadMinMemory = asNumberOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['minMemory'],
      {msg: `MinVcpusMemoryInstanceRequestPayload.minMemory`}
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadBurstable =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['burstable'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['burstable'], {
            msg: `MinVcpusMemoryInstanceRequestPayload.burstable`,
          });
    const parsedMinVcpusMemoryInstanceRequestPayloadMinReleaseYear =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['minReleaseYear'] === undefined
        ? undefined
        : asNumberOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['minReleaseYear'], {
            msg: `MinVcpusMemoryInstanceRequestPayload.minReleaseYear`,
          });
    const parsedMinVcpusMemoryInstanceRequestPayloadArchitecture =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['architecture'] === undefined
        ? undefined
        : asStringOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['architecture'], {
            msg: `MinVcpusMemoryInstanceRequestPayload.architecture`,
          });
    const parsedMinVcpusMemoryInstanceRequestPayloadSubnetIds = asArrayOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['subnetIds'],
      {msg: `MinVcpusMemoryInstanceRequestPayload.subnetIds`}
    ).map((element, i) => {
      const parsedElement = asStringOrThrow(element, {
        msg: `MinVcpusMemoryInstanceRequestPayload.subnetIds[${i}]`,
      });
      return parsedElement;
    });
    const parsedMinVcpusMemoryInstanceRequestPayloadCommitHash = asStringOrThrow<CommitHash>(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['commitHash'],
      {msg: `MinVcpusMemoryInstanceRequestPayload.commitHash`}
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadMaxRunningDurationMs =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['maxRunningDurationMs'] === undefined
        ? undefined
        : asNumberOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['maxRunningDurationMs'], {
            msg: `MinVcpusMemoryInstanceRequestPayload.maxRunningDurationMs`,
          });
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap = asMapOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['envVars'],
      {msg: `MinVcpusMemoryInstanceRequestPayload.envVars`}
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsBUILD_ID =
      parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['BUILD_ID'] === undefined
        ? undefined
        : asStringOrThrow<BuildId>(
            parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['BUILD_ID'],
            {msg: `MinVcpusMemoryInstanceRequestPayload.envVars.BUILD_ID`}
          );
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_ID =
      parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_ID'] === undefined
        ? undefined
        : asStringOrThrow<TaskId>(parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_ID'], {
            msg: `MinVcpusMemoryInstanceRequestPayload.envVars.TASK_ID`,
          });
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_VERSION =
      parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_VERSION'] === undefined
        ? undefined
        : asStringOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_VERSION'], {
            msg: `MinVcpusMemoryInstanceRequestPayload.envVars.TASK_VERSION`,
          });
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVars = {
      BUILD_ID: parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsBUILD_ID,
      TASK_ID: parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_ID,
      TASK_VERSION: parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_VERSION,
    };
    const parsedMinVcpusMemoryInstanceRequestPayload = {
      serviceName: parsedMinVcpusMemoryInstanceRequestPayloadServiceName,
      minVcpus: parsedMinVcpusMemoryInstanceRequestPayloadMinVcpus,
      minMemory: parsedMinVcpusMemoryInstanceRequestPayloadMinMemory,
      burstable: parsedMinVcpusMemoryInstanceRequestPayloadBurstable,
      minReleaseYear: parsedMinVcpusMemoryInstanceRequestPayloadMinReleaseYear,
      architecture: parsedMinVcpusMemoryInstanceRequestPayloadArchitecture,
      subnetIds: parsedMinVcpusMemoryInstanceRequestPayloadSubnetIds,
      commitHash: parsedMinVcpusMemoryInstanceRequestPayloadCommitHash,
      maxRunningDurationMs: parsedMinVcpusMemoryInstanceRequestPayloadMaxRunningDurationMs,
      envVars: parsedMinVcpusMemoryInstanceRequestPayloadEnvVars,
    };
    return parsedMinVcpusMemoryInstanceRequestPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface TerminationInstanceRequestPayload {
  instanceId: string;
}

export const TerminationInstanceRequestPayloadMetadata = {instanceId: {type: 'string'}};

export function parseTerminationInstanceRequestPayload(
  json: Record<string, unknown>
): TerminationInstanceRequestPayload {
  try {
    const parsedTerminationInstanceRequestPayloadMap = asMapOrThrow(json, {
      msg: `TerminationInstanceRequestPayload`,
    });
    const parsedTerminationInstanceRequestPayloadInstanceId = asStringOrThrow(
      parsedTerminationInstanceRequestPayloadMap['instanceId'],
      {msg: `TerminationInstanceRequestPayload.instanceId`}
    );
    const parsedTerminationInstanceRequestPayload = {
      instanceId: parsedTerminationInstanceRequestPayloadInstanceId,
    };
    return parsedTerminationInstanceRequestPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type InstanceRequestPayload<T extends InstanceRequestType> =
  T extends InstanceRequestType.InstanceType
    ? InstanceTypeInstanceRequestPayload
    : T extends InstanceRequestType.MinVcpusMemory
      ? MinVcpusMemoryInstanceRequestPayload
      : T extends InstanceRequestType.Termination
        ? TerminationInstanceRequestPayload
        : never;

const instanceRequestPayloadParsers = {
  [InstanceRequestType.InstanceType]: parseInstanceTypeInstanceRequestPayload,
  [InstanceRequestType.MinVcpusMemory]: parseMinVcpusMemoryInstanceRequestPayload,
  [InstanceRequestType.Termination]: parseTerminationInstanceRequestPayload,
};

export function parseInstanceRequestPayload(
  type: InstanceRequestType,
  json: Record<string, unknown>
): InstanceRequestPayload<InstanceRequestType> {
  return instanceRequestPayloadParsers[type](json);
}

export const instanceRequestPayloadMetadata = {
  [InstanceRequestType.InstanceType]: InstanceTypeInstanceRequestPayloadMetadata,
  [InstanceRequestType.MinVcpusMemory]: MinVcpusMemoryInstanceRequestPayloadMetadata,
  [InstanceRequestType.Termination]: TerminationInstanceRequestPayloadMetadata,
};

export interface InstanceRequestItemGeneric<T extends InstanceRequestType> {
  instanceRequestId: InstanceRequestId;
  creationTime: number;
  instanceRequestType: T;
  payload: InstanceRequestPayload<T>;
}

export type InstanceTypeInstanceRequestItem =
  InstanceRequestItemGeneric<InstanceRequestType.InstanceType>;
export type MinVcpusMemoryInstanceRequestItem =
  InstanceRequestItemGeneric<InstanceRequestType.MinVcpusMemory>;
export type TerminationInstanceRequestItem =
  InstanceRequestItemGeneric<InstanceRequestType.Termination>;

export type InstanceRequestItem =
  | InstanceTypeInstanceRequestItem
  | MinVcpusMemoryInstanceRequestItem
  | TerminationInstanceRequestItem;

export interface ReportWithFailuresItem {
  reportingId: ReportingId; // a
  taskId: TaskId; // b
  breakdown: ReportingBreakdown; // c
  date: string; // d
  alertsCount: number; // e
  errorsCount: number; // f
  totalCount: number; // g
  dateMonth: string; // h
}

export interface DatalakeElementOutputs {
  columns: {name: string; type: DatalakeOutputColumnType}[];
}

export function parseDatalakeElementOutputs(json: Record<string, unknown>): DatalakeElementOutputs {
  try {
    const parsedDatalakeElementOutputsMap = asMapOrThrow(json, {msg: `DatalakeElementOutputs`});
    const parsedDatalakeElementOutputsColumns = asArrayOrThrow(
      parsedDatalakeElementOutputsMap['columns'],
      {msg: `DatalakeElementOutputs.columns`}
    ).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {msg: `DatalakeElementOutputs.columns[${i}]`});
      const parsedElementName = asStringOrThrow(parsedElementMap['name'], {
        msg: `DatalakeElementOutputs.columns[${i}].name`,
      });
      const parsedElementType = parseDatalakeOutputColumnType(
        asNumberOrThrow(parsedElementMap['type'], {
          msg: `DatalakeElementOutputs.columns[${i}].type`,
        })
      );
      const parsedElement = {
        name: parsedElementName,
        type: parsedElementType,
      };
      return parsedElement;
    });
    const parsedDatalakeElementOutputs = {
      columns: parsedDatalakeElementOutputsColumns,
    };
    return parsedDatalakeElementOutputs;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse DatalakeElementOutputs payload: ${errorAsString(
        err
      )}. Payload for DatalakeElementOutputs was ${JSON.stringify(json)}`
    );
  }
}

export interface DatalakeElementLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function parseDatalakeElementLayout(json: Record<string, unknown>): DatalakeElementLayout {
  try {
    const parsedDatalakeElementLayoutMap = asMapOrThrow(json, {msg: `DatalakeElementLayout`});
    const parsedDatalakeElementLayoutX = asNumberOrThrow(parsedDatalakeElementLayoutMap['x'], {
      msg: `DatalakeElementLayout.x`,
    });
    const parsedDatalakeElementLayoutY = asNumberOrThrow(parsedDatalakeElementLayoutMap['y'], {
      msg: `DatalakeElementLayout.y`,
    });
    const parsedDatalakeElementLayoutWidth = asNumberOrThrow(
      parsedDatalakeElementLayoutMap['width'],
      {msg: `DatalakeElementLayout.width`}
    );
    const parsedDatalakeElementLayoutHeight = asNumberOrThrow(
      parsedDatalakeElementLayoutMap['height'],
      {msg: `DatalakeElementLayout.height`}
    );
    const parsedDatalakeElementLayout = {
      x: parsedDatalakeElementLayoutX,
      y: parsedDatalakeElementLayoutY,
      width: parsedDatalakeElementLayoutWidth,
      height: parsedDatalakeElementLayoutHeight,
    };
    return parsedDatalakeElementLayout;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse DatalakeElementLayout payload: ${errorAsString(
        err
      )}. Payload for DatalakeElementLayout was ${JSON.stringify(json)}`
    );
  }
}

export interface WebEventsDataSourceDatalakeElementPayload {
  group: DatalakeElementTypeGroup.DataSource;
  partitionType: DatalakePartitionType.Hourly;
  domainName: DomainName;
  domainNames?: DomainName[];
  analyticsBufferAddresses: {ip: string; port: number}[];
}

export const WebEventsDataSourceDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'DataSource',
  },
  partitionType: {
    type: 'enum-constant',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
    enumValue: 'Hourly',
  },
  domainName: {type: 'string', brand: 'DomainName'},
  domainNames: {type: 'array', optional: true, schema: {type: 'string', brand: 'DomainName'}},
  analyticsBufferAddresses: {
    type: 'array',
    schema: {type: 'object', schema: {ip: {type: 'string'}, port: {type: 'number'}}},
  },
};

export function parseWebEventsDataSourceDatalakeElementPayload(
  json: Record<string, unknown>
): WebEventsDataSourceDatalakeElementPayload {
  try {
    const parsedWebEventsDataSourceDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `WebEventsDataSourceDatalakeElementPayload`,
    });
    const parsedWebEventsDataSourceDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.DataSource as const,
      {msg: `WebEventsDataSourceDatalakeElementPayload.group`}
    );
    const parsedWebEventsDataSourceDatalakeElementPayloadPartitionType = asConstantOrThrow(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['partitionType'],
      DatalakePartitionType.Hourly as const,
      {msg: `WebEventsDataSourceDatalakeElementPayload.partitionType`}
    );
    const parsedWebEventsDataSourceDatalakeElementPayloadDomainName = asStringOrThrow<DomainName>(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['domainName'],
      {msg: `WebEventsDataSourceDatalakeElementPayload.domainName`}
    );
    const parsedWebEventsDataSourceDatalakeElementPayloadDomainNames =
      parsedWebEventsDataSourceDatalakeElementPayloadMap['domainNames'] === undefined
        ? undefined
        : asArrayOrThrow(parsedWebEventsDataSourceDatalakeElementPayloadMap['domainNames'], {
            msg: `WebEventsDataSourceDatalakeElementPayload.domainNames`,
          }).map((element, i) => {
            const parsedElement = asStringOrThrow<DomainName>(element, {
              msg: `WebEventsDataSourceDatalakeElementPayload.domainNames[${i}]`,
            });
            return parsedElement;
          });
    const parsedWebEventsDataSourceDatalakeElementPayloadAnalyticsBufferAddresses = asArrayOrThrow(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['analyticsBufferAddresses'],
      {msg: `WebEventsDataSourceDatalakeElementPayload.analyticsBufferAddresses`}
    ).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {
        msg: `WebEventsDataSourceDatalakeElementPayload.analyticsBufferAddresses[${i}]`,
      });
      const parsedElementIp = asStringOrThrow(parsedElementMap['ip'], {
        msg: `WebEventsDataSourceDatalakeElementPayload.analyticsBufferAddresses[${i}].ip`,
      });
      const parsedElementPort = asNumberOrThrow(parsedElementMap['port'], {
        msg: `WebEventsDataSourceDatalakeElementPayload.analyticsBufferAddresses[${i}].port`,
      });
      const parsedElement = {
        ip: parsedElementIp,
        port: parsedElementPort,
      };
      return parsedElement;
    });
    const parsedWebEventsDataSourceDatalakeElementPayload = {
      group: parsedWebEventsDataSourceDatalakeElementPayloadGroup,
      partitionType: parsedWebEventsDataSourceDatalakeElementPayloadPartitionType,
      domainName: parsedWebEventsDataSourceDatalakeElementPayloadDomainName,
      domainNames: parsedWebEventsDataSourceDatalakeElementPayloadDomainNames,
      analyticsBufferAddresses:
        parsedWebEventsDataSourceDatalakeElementPayloadAnalyticsBufferAddresses,
    };
    return parsedWebEventsDataSourceDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface FileDropDataSourceDatalakeElementPayload {
  group: DatalakeElementTypeGroup.DataSource;
  partitionType: DatalakePartitionType.Daily;
}

export const FileDropDataSourceDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'DataSource',
  },
  partitionType: {
    type: 'enum-constant',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
    enumValue: 'Daily',
  },
};

export function parseFileDropDataSourceDatalakeElementPayload(
  json: Record<string, unknown>
): FileDropDataSourceDatalakeElementPayload {
  try {
    const parsedFileDropDataSourceDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `FileDropDataSourceDatalakeElementPayload`,
    });
    const parsedFileDropDataSourceDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedFileDropDataSourceDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.DataSource as const,
      {msg: `FileDropDataSourceDatalakeElementPayload.group`}
    );
    const parsedFileDropDataSourceDatalakeElementPayloadPartitionType = asConstantOrThrow(
      parsedFileDropDataSourceDatalakeElementPayloadMap['partitionType'],
      DatalakePartitionType.Daily as const,
      {msg: `FileDropDataSourceDatalakeElementPayload.partitionType`}
    );
    const parsedFileDropDataSourceDatalakeElementPayload = {
      group: parsedFileDropDataSourceDatalakeElementPayloadGroup,
      partitionType: parsedFileDropDataSourceDatalakeElementPayloadPartitionType,
    };
    return parsedFileDropDataSourceDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface QontoDataSourceDatalakeElementPayload {
  group: DatalakeElementTypeGroup.DataSource;
  partitionType: DatalakePartitionType.None;
  iban: string;
  login: string;
  secretKey: string;
}

export const QontoDataSourceDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'DataSource',
  },
  partitionType: {
    type: 'enum-constant',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
    enumValue: 'None',
  },
  iban: {type: 'string'},
  login: {type: 'string'},
  secretKey: {type: 'string'},
};

export function parseQontoDataSourceDatalakeElementPayload(
  json: Record<string, unknown>
): QontoDataSourceDatalakeElementPayload {
  try {
    const parsedQontoDataSourceDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `QontoDataSourceDatalakeElementPayload`,
    });
    const parsedQontoDataSourceDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.DataSource as const,
      {msg: `QontoDataSourceDatalakeElementPayload.group`}
    );
    const parsedQontoDataSourceDatalakeElementPayloadPartitionType = asConstantOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['partitionType'],
      DatalakePartitionType.None as const,
      {msg: `QontoDataSourceDatalakeElementPayload.partitionType`}
    );
    const parsedQontoDataSourceDatalakeElementPayloadIban = asStringOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['iban'],
      {msg: `QontoDataSourceDatalakeElementPayload.iban`}
    );
    const parsedQontoDataSourceDatalakeElementPayloadLogin = asStringOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['login'],
      {msg: `QontoDataSourceDatalakeElementPayload.login`}
    );
    const parsedQontoDataSourceDatalakeElementPayloadSecretKey = asStringOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['secretKey'],
      {msg: `QontoDataSourceDatalakeElementPayload.secretKey`}
    );
    const parsedQontoDataSourceDatalakeElementPayload = {
      group: parsedQontoDataSourceDatalakeElementPayloadGroup,
      partitionType: parsedQontoDataSourceDatalakeElementPayloadPartitionType,
      iban: parsedQontoDataSourceDatalakeElementPayloadIban,
      login: parsedQontoDataSourceDatalakeElementPayloadLogin,
      secretKey: parsedQontoDataSourceDatalakeElementPayloadSecretKey,
    };
    return parsedQontoDataSourceDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface IpGeolocTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  sourceColumn: number;
}

export const IpGeolocTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number'},
};

export function parseIpGeolocTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): IpGeolocTransformationDatalakeElementPayload {
  try {
    const parsedIpGeolocTransformationDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `IpGeolocTransformationDatalakeElementPayload`,
    });
    const parsedIpGeolocTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedIpGeolocTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const,
      {msg: `IpGeolocTransformationDatalakeElementPayload.group`}
    );
    const parsedIpGeolocTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedIpGeolocTransformationDatalakeElementPayloadMap['source'],
        {msg: `IpGeolocTransformationDatalakeElementPayload.source`}
      );
    const parsedIpGeolocTransformationDatalakeElementPayloadSourceColumn = asNumberOrThrow(
      parsedIpGeolocTransformationDatalakeElementPayloadMap['sourceColumn'],
      {msg: `IpGeolocTransformationDatalakeElementPayload.sourceColumn`}
    );
    const parsedIpGeolocTransformationDatalakeElementPayload = {
      group: parsedIpGeolocTransformationDatalakeElementPayloadGroup,
      source: parsedIpGeolocTransformationDatalakeElementPayloadSource,
      sourceColumn: parsedIpGeolocTransformationDatalakeElementPayloadSourceColumn,
    };
    return parsedIpGeolocTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface CalculationsTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  firstColumnIndex: number;
  secondColumnIndex: number;
  operator: DatalakeCalculationOperator;
  outputName: string;
}

export const CalculationsTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  firstColumnIndex: {type: 'number'},
  secondColumnIndex: {type: 'number'},
  operator: {type: 'enum', enum: [{label: 'Multiply', value: 10}]},
  outputName: {type: 'string'},
};

export function parseCalculationsTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): CalculationsTransformationDatalakeElementPayload {
  try {
    const parsedCalculationsTransformationDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `CalculationsTransformationDatalakeElementPayload`,
    });
    const parsedCalculationsTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const,
      {msg: `CalculationsTransformationDatalakeElementPayload.group`}
    );
    const parsedCalculationsTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedCalculationsTransformationDatalakeElementPayloadMap['source'],
        {msg: `CalculationsTransformationDatalakeElementPayload.source`}
      );
    const parsedCalculationsTransformationDatalakeElementPayloadFirstColumnIndex = asNumberOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['firstColumnIndex'],
      {msg: `CalculationsTransformationDatalakeElementPayload.firstColumnIndex`}
    );
    const parsedCalculationsTransformationDatalakeElementPayloadSecondColumnIndex = asNumberOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['secondColumnIndex'],
      {msg: `CalculationsTransformationDatalakeElementPayload.secondColumnIndex`}
    );
    const parsedCalculationsTransformationDatalakeElementPayloadOperator =
      parseDatalakeCalculationOperator(
        asNumberOrThrow(parsedCalculationsTransformationDatalakeElementPayloadMap['operator'], {
          msg: `CalculationsTransformationDatalakeElementPayload.operator`,
        })
      );
    const parsedCalculationsTransformationDatalakeElementPayloadOutputName = asStringOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['outputName'],
      {msg: `CalculationsTransformationDatalakeElementPayload.outputName`}
    );
    const parsedCalculationsTransformationDatalakeElementPayload = {
      group: parsedCalculationsTransformationDatalakeElementPayloadGroup,
      source: parsedCalculationsTransformationDatalakeElementPayloadSource,
      firstColumnIndex: parsedCalculationsTransformationDatalakeElementPayloadFirstColumnIndex,
      secondColumnIndex: parsedCalculationsTransformationDatalakeElementPayloadSecondColumnIndex,
      operator: parsedCalculationsTransformationDatalakeElementPayloadOperator,
      outputName: parsedCalculationsTransformationDatalakeElementPayloadOutputName,
    };
    return parsedCalculationsTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface ExtractStringTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  sourceColumn: number;
  startsWith: string;
  endsWith: string;
  outputName: string;
}

export const ExtractStringTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number'},
  startsWith: {type: 'string'},
  endsWith: {type: 'string'},
  outputName: {type: 'string'},
};

export function parseExtractStringTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): ExtractStringTransformationDatalakeElementPayload {
  try {
    const parsedExtractStringTransformationDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `ExtractStringTransformationDatalakeElementPayload`,
    });
    const parsedExtractStringTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const,
      {msg: `ExtractStringTransformationDatalakeElementPayload.group`}
    );
    const parsedExtractStringTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedExtractStringTransformationDatalakeElementPayloadMap['source'],
        {msg: `ExtractStringTransformationDatalakeElementPayload.source`}
      );
    const parsedExtractStringTransformationDatalakeElementPayloadSourceColumn = asNumberOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['sourceColumn'],
      {msg: `ExtractStringTransformationDatalakeElementPayload.sourceColumn`}
    );
    const parsedExtractStringTransformationDatalakeElementPayloadStartsWith = asStringOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['startsWith'],
      {msg: `ExtractStringTransformationDatalakeElementPayload.startsWith`}
    );
    const parsedExtractStringTransformationDatalakeElementPayloadEndsWith = asStringOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['endsWith'],
      {msg: `ExtractStringTransformationDatalakeElementPayload.endsWith`}
    );
    const parsedExtractStringTransformationDatalakeElementPayloadOutputName = asStringOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['outputName'],
      {msg: `ExtractStringTransformationDatalakeElementPayload.outputName`}
    );
    const parsedExtractStringTransformationDatalakeElementPayload = {
      group: parsedExtractStringTransformationDatalakeElementPayloadGroup,
      source: parsedExtractStringTransformationDatalakeElementPayloadSource,
      sourceColumn: parsedExtractStringTransformationDatalakeElementPayloadSourceColumn,
      startsWith: parsedExtractStringTransformationDatalakeElementPayloadStartsWith,
      endsWith: parsedExtractStringTransformationDatalakeElementPayloadEndsWith,
      outputName: parsedExtractStringTransformationDatalakeElementPayloadOutputName,
    };
    return parsedExtractStringTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface LabelTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  sourceColumn: number;
  labels: {regex: string; label: string}[];
  defaultLabel: string;
  outputName: string;
}

export const LabelTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number'},
  labels: {
    type: 'array',
    schema: {type: 'object', schema: {regex: {type: 'string'}, label: {type: 'string'}}},
  },
  defaultLabel: {type: 'string'},
  outputName: {type: 'string'},
};

export function parseLabelTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): LabelTransformationDatalakeElementPayload {
  try {
    const parsedLabelTransformationDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `LabelTransformationDatalakeElementPayload`,
    });
    const parsedLabelTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const,
      {msg: `LabelTransformationDatalakeElementPayload.group`}
    );
    const parsedLabelTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedLabelTransformationDatalakeElementPayloadMap['source'],
        {msg: `LabelTransformationDatalakeElementPayload.source`}
      );
    const parsedLabelTransformationDatalakeElementPayloadSourceColumn = asNumberOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['sourceColumn'],
      {msg: `LabelTransformationDatalakeElementPayload.sourceColumn`}
    );
    const parsedLabelTransformationDatalakeElementPayloadLabels = asArrayOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['labels'],
      {msg: `LabelTransformationDatalakeElementPayload.labels`}
    ).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {
        msg: `LabelTransformationDatalakeElementPayload.labels[${i}]`,
      });
      const parsedElementRegex = asStringOrThrow(parsedElementMap['regex'], {
        msg: `LabelTransformationDatalakeElementPayload.labels[${i}].regex`,
      });
      const parsedElementLabel = asStringOrThrow(parsedElementMap['label'], {
        msg: `LabelTransformationDatalakeElementPayload.labels[${i}].label`,
      });
      const parsedElement = {
        regex: parsedElementRegex,
        label: parsedElementLabel,
      };
      return parsedElement;
    });
    const parsedLabelTransformationDatalakeElementPayloadDefaultLabel = asStringOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['defaultLabel'],
      {msg: `LabelTransformationDatalakeElementPayload.defaultLabel`}
    );
    const parsedLabelTransformationDatalakeElementPayloadOutputName = asStringOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['outputName'],
      {msg: `LabelTransformationDatalakeElementPayload.outputName`}
    );
    const parsedLabelTransformationDatalakeElementPayload = {
      group: parsedLabelTransformationDatalakeElementPayloadGroup,
      source: parsedLabelTransformationDatalakeElementPayloadSource,
      sourceColumn: parsedLabelTransformationDatalakeElementPayloadSourceColumn,
      labels: parsedLabelTransformationDatalakeElementPayloadLabels,
      defaultLabel: parsedLabelTransformationDatalakeElementPayloadDefaultLabel,
      outputName: parsedLabelTransformationDatalakeElementPayloadOutputName,
    };
    return parsedLabelTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface SumsAggregationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Aggregation;
  source: DatalakeElementItemId;
  sourceColumn?: number;
  sourcePrimaryKeyColumn: number;
}

export const SumsAggregationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Aggregation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number', optional: true},
  sourcePrimaryKeyColumn: {type: 'number'},
};

export function parseSumsAggregationDatalakeElementPayload(
  json: Record<string, unknown>
): SumsAggregationDatalakeElementPayload {
  try {
    const parsedSumsAggregationDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `SumsAggregationDatalakeElementPayload`,
    });
    const parsedSumsAggregationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedSumsAggregationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Aggregation as const,
      {msg: `SumsAggregationDatalakeElementPayload.group`}
    );
    const parsedSumsAggregationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedSumsAggregationDatalakeElementPayloadMap['source'],
        {msg: `SumsAggregationDatalakeElementPayload.source`}
      );
    const parsedSumsAggregationDatalakeElementPayloadSourceColumn =
      parsedSumsAggregationDatalakeElementPayloadMap['sourceColumn'] === undefined
        ? undefined
        : asNumberOrThrow(parsedSumsAggregationDatalakeElementPayloadMap['sourceColumn'], {
            msg: `SumsAggregationDatalakeElementPayload.sourceColumn`,
          });
    const parsedSumsAggregationDatalakeElementPayloadSourcePrimaryKeyColumn = asNumberOrThrow(
      parsedSumsAggregationDatalakeElementPayloadMap['sourcePrimaryKeyColumn'],
      {msg: `SumsAggregationDatalakeElementPayload.sourcePrimaryKeyColumn`}
    );
    const parsedSumsAggregationDatalakeElementPayload = {
      group: parsedSumsAggregationDatalakeElementPayloadGroup,
      source: parsedSumsAggregationDatalakeElementPayloadSource,
      sourceColumn: parsedSumsAggregationDatalakeElementPayloadSourceColumn,
      sourcePrimaryKeyColumn: parsedSumsAggregationDatalakeElementPayloadSourcePrimaryKeyColumn,
    };
    return parsedSumsAggregationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface GraphOutputDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Output;
  source: DatalakeElementItemId;
  breakdown: DatalakeTimeBreakdown;
  xAxisColumnIndex: number;
  yAxisColumnIndex: number;
}

export const GraphOutputDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Output',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  breakdown: {type: 'enum', enum: [{label: 'Hourly', value: 10}]},
  xAxisColumnIndex: {type: 'number'},
  yAxisColumnIndex: {type: 'number'},
};

export function parseGraphOutputDatalakeElementPayload(
  json: Record<string, unknown>
): GraphOutputDatalakeElementPayload {
  try {
    const parsedGraphOutputDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `GraphOutputDatalakeElementPayload`,
    });
    const parsedGraphOutputDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedGraphOutputDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Output as const,
      {msg: `GraphOutputDatalakeElementPayload.group`}
    );
    const parsedGraphOutputDatalakeElementPayloadSource = asStringOrThrow<DatalakeElementItemId>(
      parsedGraphOutputDatalakeElementPayloadMap['source'],
      {msg: `GraphOutputDatalakeElementPayload.source`}
    );
    const parsedGraphOutputDatalakeElementPayloadBreakdown = parseDatalakeTimeBreakdown(
      asNumberOrThrow(parsedGraphOutputDatalakeElementPayloadMap['breakdown'], {
        msg: `GraphOutputDatalakeElementPayload.breakdown`,
      })
    );
    const parsedGraphOutputDatalakeElementPayloadXAxisColumnIndex = asNumberOrThrow(
      parsedGraphOutputDatalakeElementPayloadMap['xAxisColumnIndex'],
      {msg: `GraphOutputDatalakeElementPayload.xAxisColumnIndex`}
    );
    const parsedGraphOutputDatalakeElementPayloadYAxisColumnIndex = asNumberOrThrow(
      parsedGraphOutputDatalakeElementPayloadMap['yAxisColumnIndex'],
      {msg: `GraphOutputDatalakeElementPayload.yAxisColumnIndex`}
    );
    const parsedGraphOutputDatalakeElementPayload = {
      group: parsedGraphOutputDatalakeElementPayloadGroup,
      source: parsedGraphOutputDatalakeElementPayloadSource,
      breakdown: parsedGraphOutputDatalakeElementPayloadBreakdown,
      xAxisColumnIndex: parsedGraphOutputDatalakeElementPayloadXAxisColumnIndex,
      yAxisColumnIndex: parsedGraphOutputDatalakeElementPayloadYAxisColumnIndex,
    };
    return parsedGraphOutputDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface RestApiOutputDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Output;
  source: DatalakeElementItemId;
  breakdown: DatalakeTimeBreakdown;
  format: DatalakeRestApiFormat;
}

export const RestApiOutputDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Output',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  breakdown: {type: 'enum', enum: [{label: 'Hourly', value: 10}]},
  format: {
    type: 'enum',
    enum: [
      {label: 'Json', value: 10},
      {label: 'Csv', value: 20},
    ],
  },
};

export function parseRestApiOutputDatalakeElementPayload(
  json: Record<string, unknown>
): RestApiOutputDatalakeElementPayload {
  try {
    const parsedRestApiOutputDatalakeElementPayloadMap = asMapOrThrow(json, {
      msg: `RestApiOutputDatalakeElementPayload`,
    });
    const parsedRestApiOutputDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedRestApiOutputDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Output as const,
      {msg: `RestApiOutputDatalakeElementPayload.group`}
    );
    const parsedRestApiOutputDatalakeElementPayloadSource = asStringOrThrow<DatalakeElementItemId>(
      parsedRestApiOutputDatalakeElementPayloadMap['source'],
      {msg: `RestApiOutputDatalakeElementPayload.source`}
    );
    const parsedRestApiOutputDatalakeElementPayloadBreakdown = parseDatalakeTimeBreakdown(
      asNumberOrThrow(parsedRestApiOutputDatalakeElementPayloadMap['breakdown'], {
        msg: `RestApiOutputDatalakeElementPayload.breakdown`,
      })
    );
    const parsedRestApiOutputDatalakeElementPayloadFormat = parseDatalakeRestApiFormat(
      asNumberOrThrow(parsedRestApiOutputDatalakeElementPayloadMap['format'], {
        msg: `RestApiOutputDatalakeElementPayload.format`,
      })
    );
    const parsedRestApiOutputDatalakeElementPayload = {
      group: parsedRestApiOutputDatalakeElementPayloadGroup,
      source: parsedRestApiOutputDatalakeElementPayloadSource,
      breakdown: parsedRestApiOutputDatalakeElementPayloadBreakdown,
      format: parsedRestApiOutputDatalakeElementPayloadFormat,
    };
    return parsedRestApiOutputDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type DatalakeElementPayload<T extends DatalakeElementType> =
  T extends DatalakeElementType.WebEventsDataSource
    ? WebEventsDataSourceDatalakeElementPayload
    : T extends DatalakeElementType.FileDropDataSource
      ? FileDropDataSourceDatalakeElementPayload
      : T extends DatalakeElementType.QontoDataSource
        ? QontoDataSourceDatalakeElementPayload
        : T extends DatalakeElementType.IpGeolocTransformation
          ? IpGeolocTransformationDatalakeElementPayload
          : T extends DatalakeElementType.CalculationsTransformation
            ? CalculationsTransformationDatalakeElementPayload
            : T extends DatalakeElementType.ExtractStringTransformation
              ? ExtractStringTransformationDatalakeElementPayload
              : T extends DatalakeElementType.SumsAggregation
                ? SumsAggregationDatalakeElementPayload
                : T extends DatalakeElementType.GraphOutput
                  ? GraphOutputDatalakeElementPayload
                  : T extends DatalakeElementType.RestApiOutput
                    ? RestApiOutputDatalakeElementPayload
                    : T extends DatalakeElementType.LabelTransformation
                      ? LabelTransformationDatalakeElementPayload
                      : never;

export interface DatalakeElementItemByGroup {
  [DatalakeElementTypeGroup.DataSource]:
    | WebEventsDataSourceDatalakeElementItem
    | FileDropDataSourceDatalakeElementItem
    | QontoDataSourceDatalakeElementItem;
  [DatalakeElementTypeGroup.Transformation]:
    | IpGeolocTransformationDatalakeElementItem
    | CalculationsTransformationDatalakeElementItem
    | ExtractStringTransformationDatalakeElementItem
    | LabelTransformationDatalakeElementItem;
  [DatalakeElementTypeGroup.Aggregation]: SumsAggregationDatalakeElementItem;
  [DatalakeElementTypeGroup.Output]:
    | GraphOutputDatalakeElementItem
    | RestApiOutputDatalakeElementItem;
}

export const DatalakeElementTypeByGroup: Record<DatalakeElementTypeGroup, DatalakeElementType[]> = {
  [DatalakeElementTypeGroup.DataSource]: [
    DatalakeElementType.WebEventsDataSource,
    DatalakeElementType.FileDropDataSource,
    DatalakeElementType.QontoDataSource,
  ],
  [DatalakeElementTypeGroup.Transformation]: [
    DatalakeElementType.IpGeolocTransformation,
    DatalakeElementType.CalculationsTransformation,
    DatalakeElementType.ExtractStringTransformation,
    DatalakeElementType.LabelTransformation,
  ],
  [DatalakeElementTypeGroup.Aggregation]: [DatalakeElementType.SumsAggregation],
  [DatalakeElementTypeGroup.Output]: [
    DatalakeElementType.GraphOutput,
    DatalakeElementType.RestApiOutput,
  ],
  /* {
  "group": {
    "type": "enum-constant",
    "enum": "DatalakeElementTypeGroup",
    "enumValue": "DataSource"
  }
} */
};

const datalakeElementPayloadParsers = {
  [DatalakeElementType.WebEventsDataSource]: parseWebEventsDataSourceDatalakeElementPayload,
  [DatalakeElementType.FileDropDataSource]: parseFileDropDataSourceDatalakeElementPayload,
  [DatalakeElementType.QontoDataSource]: parseQontoDataSourceDatalakeElementPayload,
  [DatalakeElementType.IpGeolocTransformation]: parseIpGeolocTransformationDatalakeElementPayload,
  [DatalakeElementType.CalculationsTransformation]:
    parseCalculationsTransformationDatalakeElementPayload,
  [DatalakeElementType.ExtractStringTransformation]:
    parseExtractStringTransformationDatalakeElementPayload,
  [DatalakeElementType.LabelTransformation]: parseLabelTransformationDatalakeElementPayload,
  [DatalakeElementType.SumsAggregation]: parseSumsAggregationDatalakeElementPayload,
  [DatalakeElementType.GraphOutput]: parseGraphOutputDatalakeElementPayload,
  [DatalakeElementType.RestApiOutput]: parseRestApiOutputDatalakeElementPayload,
};

export function parseDatalakeElementPayload(
  type: DatalakeElementType,
  json: Record<string, unknown>
): DatalakeElementPayload<DatalakeElementType> {
  return datalakeElementPayloadParsers[type](json);
}

export const datalakeElementPayloadMetadata = {
  [DatalakeElementType.WebEventsDataSource]: WebEventsDataSourceDatalakeElementPayloadMetadata,
  [DatalakeElementType.FileDropDataSource]: FileDropDataSourceDatalakeElementPayloadMetadata,
  [DatalakeElementType.QontoDataSource]: QontoDataSourceDatalakeElementPayloadMetadata,
  [DatalakeElementType.IpGeolocTransformation]:
    IpGeolocTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.CalculationsTransformation]:
    CalculationsTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.ExtractStringTransformation]:
    ExtractStringTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.LabelTransformation]: LabelTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.SumsAggregation]: SumsAggregationDatalakeElementPayloadMetadata,
  [DatalakeElementType.GraphOutput]: GraphOutputDatalakeElementPayloadMetadata,
  [DatalakeElementType.RestApiOutput]: RestApiOutputDatalakeElementPayloadMetadata,
};

export interface DatalakeElementItemGeneric<T extends DatalakeElementType> {
  elementId: DatalakeElementItemId;
  type: T;
  outputs: DatalakeElementOutputs;
  params: DatalakeElementPayload<T>;
  datalakeId: DataLakeId;
  accountId: AccountId;
  layout: DatalakeElementLayout;
}

export type WebEventsDataSourceDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.WebEventsDataSource>;
export type FileDropDataSourceDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.FileDropDataSource>;
export type QontoDataSourceDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.QontoDataSource>;
export type IpGeolocTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.IpGeolocTransformation>;
export type CalculationsTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.CalculationsTransformation>;
export type ExtractStringTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.ExtractStringTransformation>;
export type SumsAggregationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.SumsAggregation>;
export type GraphOutputDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.GraphOutput>;
export type RestApiOutputDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.RestApiOutput>;
export type LabelTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.LabelTransformation>;

export type DatalakeElementItem =
  | WebEventsDataSourceDatalakeElementItem
  | FileDropDataSourceDatalakeElementItem
  | QontoDataSourceDatalakeElementItem
  | IpGeolocTransformationDatalakeElementItem
  | CalculationsTransformationDatalakeElementItem
  | ExtractStringTransformationDatalakeElementItem
  | SumsAggregationDatalakeElementItem
  | GraphOutputDatalakeElementItem
  | RestApiOutputDatalakeElementItem
  | LabelTransformationDatalakeElementItem;

export interface DatalakeApiTokenItem {
  token: DatalakeApiToken; // a
  accountId: AccountId; // b
  datalakeId: DataLakeId; // c
  elementId: DatalakeElementItemId; // d
}

export interface DatalakeApiStatsItem {
  elementId: DatalakeElementItemId; // a
  minuteTs: number; // b
  counter: number; // c
  expiresAt: number; // d
  datalakeId: DataLakeId; // e
}

export interface DashboardItem {
  id: DashboardId; // a
  accountId: AccountId; // b
  jsonStr: string; // c
}

export interface ServiceAdArtisanItem {
  serviceAdArtisanId: ServiceAdArtisanId; // a
  name: string; // b
  phone: string; // c
}

export interface PendingAccountValidationServiceAdCustomerPayload {}

export const PendingAccountValidationServiceAdCustomerPayloadMetadata = {};

export function parsePendingAccountValidationServiceAdCustomerPayload(
  json: Record<string, unknown>
): PendingAccountValidationServiceAdCustomerPayload {
  try {
    const parsedPendingAccountValidationServiceAdCustomerPayload = {};
    return parsedPendingAccountValidationServiceAdCustomerPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface AccountValidatedServiceAdCustomerPayload {
  name: string;
  email: string;
}

export const AccountValidatedServiceAdCustomerPayloadMetadata = {
  name: {type: 'string'},
  email: {type: 'string'},
};

export function parseAccountValidatedServiceAdCustomerPayload(
  json: Record<string, unknown>
): AccountValidatedServiceAdCustomerPayload {
  try {
    const parsedAccountValidatedServiceAdCustomerPayloadMap = asMapOrThrow(json, {
      msg: `AccountValidatedServiceAdCustomerPayload`,
    });
    const parsedAccountValidatedServiceAdCustomerPayloadName = asStringOrThrow(
      parsedAccountValidatedServiceAdCustomerPayloadMap['name'],
      {msg: `AccountValidatedServiceAdCustomerPayload.name`}
    );
    const parsedAccountValidatedServiceAdCustomerPayloadEmail = asStringOrThrow(
      parsedAccountValidatedServiceAdCustomerPayloadMap['email'],
      {msg: `AccountValidatedServiceAdCustomerPayload.email`}
    );
    const parsedAccountValidatedServiceAdCustomerPayload = {
      name: parsedAccountValidatedServiceAdCustomerPayloadName,
      email: parsedAccountValidatedServiceAdCustomerPayloadEmail,
    };
    return parsedAccountValidatedServiceAdCustomerPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type ServiceAdCustomerPayload<T extends ServiceAdCustomerStatus> =
  T extends ServiceAdCustomerStatus.PendingAccountValidation
    ? PendingAccountValidationServiceAdCustomerPayload
    : T extends ServiceAdCustomerStatus.AccountValidated
      ? AccountValidatedServiceAdCustomerPayload
      : never;

const serviceAdCustomerPayloadParsers = {
  [ServiceAdCustomerStatus.PendingAccountValidation]:
    parsePendingAccountValidationServiceAdCustomerPayload,
  [ServiceAdCustomerStatus.AccountValidated]: parseAccountValidatedServiceAdCustomerPayload,
};

export function parseServiceAdCustomerPayload(
  type: ServiceAdCustomerStatus,
  json: Record<string, unknown>
): ServiceAdCustomerPayload<ServiceAdCustomerStatus> {
  return serviceAdCustomerPayloadParsers[type](json);
}

export const serviceAdCustomerPayloadMetadata = {
  [ServiceAdCustomerStatus.PendingAccountValidation]:
    PendingAccountValidationServiceAdCustomerPayloadMetadata,
  [ServiceAdCustomerStatus.AccountValidated]: AccountValidatedServiceAdCustomerPayloadMetadata,
};

export interface ServiceAdCustomerItemGeneric<T extends ServiceAdCustomerStatus> {
  serviceAdCustomerId: ServiceAdCustomerId;
  domainId: string;
  phone: string;
  status: T;
  payload: ServiceAdCustomerPayload<T>;
  createdAt: string;
  updatedAt: string;
}

export type PendingAccountValidationServiceAdCustomerItem =
  ServiceAdCustomerItemGeneric<ServiceAdCustomerStatus.PendingAccountValidation>;
export type AccountValidatedServiceAdCustomerItem =
  ServiceAdCustomerItemGeneric<ServiceAdCustomerStatus.AccountValidated>;

export type ServiceAdCustomerItem =
  | PendingAccountValidationServiceAdCustomerItem
  | AccountValidatedServiceAdCustomerItem;

export interface ServiceAdRequestStatusHistory {
  content: {status: ServiceAdRequestStatus; updatedAt: string; artisanId?: ServiceAdArtisanId}[];
}

export function parseServiceAdRequestStatusHistory(
  json: Record<string, unknown>
): ServiceAdRequestStatusHistory {
  try {
    const parsedServiceAdRequestStatusHistoryMap = asMapOrThrow(json, {
      msg: `ServiceAdRequestStatusHistory`,
    });
    const parsedServiceAdRequestStatusHistoryContent = asArrayOrThrow(
      parsedServiceAdRequestStatusHistoryMap['content'],
      {msg: `ServiceAdRequestStatusHistory.content`}
    ).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {
        msg: `ServiceAdRequestStatusHistory.content[${i}]`,
      });
      const parsedElementStatus = parseServiceAdRequestStatus(
        asNumberOrThrow(parsedElementMap['status'], {
          msg: `ServiceAdRequestStatusHistory.content[${i}].status`,
        })
      );
      const parsedElementUpdatedAt = asStringOrThrow(parsedElementMap['updatedAt'], {
        msg: `ServiceAdRequestStatusHistory.content[${i}].updatedAt`,
      });
      const parsedElementArtisanId =
        parsedElementMap['artisanId'] === undefined
          ? undefined
          : asStringOrThrow<ServiceAdArtisanId>(parsedElementMap['artisanId'], {
              msg: `ServiceAdRequestStatusHistory.content[${i}].artisanId`,
            });
      const parsedElement = {
        status: parsedElementStatus,
        updatedAt: parsedElementUpdatedAt,
        artisanId: parsedElementArtisanId,
      };
      return parsedElement;
    });
    const parsedServiceAdRequestStatusHistory = {
      content: parsedServiceAdRequestStatusHistoryContent,
    };
    return parsedServiceAdRequestStatusHistory;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse ServiceAdRequestStatusHistory payload: ${errorAsString(
        err
      )}. Payload for ServiceAdRequestStatusHistory was ${JSON.stringify(json)}`
    );
  }
}

export interface PendingPhoneValidationServiceAdRequestPayload {
  phoneValidationToken: string;
}

export const PendingPhoneValidationServiceAdRequestPayloadMetadata = {
  phoneValidationToken: {type: 'string'},
};

export function parsePendingPhoneValidationServiceAdRequestPayload(
  json: Record<string, unknown>
): PendingPhoneValidationServiceAdRequestPayload {
  try {
    const parsedPendingPhoneValidationServiceAdRequestPayloadMap = asMapOrThrow(json, {
      msg: `PendingPhoneValidationServiceAdRequestPayload`,
    });
    const parsedPendingPhoneValidationServiceAdRequestPayloadPhoneValidationToken = asStringOrThrow(
      parsedPendingPhoneValidationServiceAdRequestPayloadMap['phoneValidationToken'],
      {msg: `PendingPhoneValidationServiceAdRequestPayload.phoneValidationToken`}
    );
    const parsedPendingPhoneValidationServiceAdRequestPayload = {
      phoneValidationToken: parsedPendingPhoneValidationServiceAdRequestPayloadPhoneValidationToken,
    };
    return parsedPendingPhoneValidationServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface PendingArtisanAcceptationServiceAdRequestPayload {
  artisanId: ServiceAdArtisanId;
}

export const PendingArtisanAcceptationServiceAdRequestPayloadMetadata = {
  artisanId: {type: 'string', brand: 'ServiceAdArtisanId'},
};

export function parsePendingArtisanAcceptationServiceAdRequestPayload(
  json: Record<string, unknown>
): PendingArtisanAcceptationServiceAdRequestPayload {
  try {
    const parsedPendingArtisanAcceptationServiceAdRequestPayloadMap = asMapOrThrow(json, {
      msg: `PendingArtisanAcceptationServiceAdRequestPayload`,
    });
    const parsedPendingArtisanAcceptationServiceAdRequestPayloadArtisanId =
      asStringOrThrow<ServiceAdArtisanId>(
        parsedPendingArtisanAcceptationServiceAdRequestPayloadMap['artisanId'],
        {msg: `PendingArtisanAcceptationServiceAdRequestPayload.artisanId`}
      );
    const parsedPendingArtisanAcceptationServiceAdRequestPayload = {
      artisanId: parsedPendingArtisanAcceptationServiceAdRequestPayloadArtisanId,
    };
    return parsedPendingArtisanAcceptationServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface ArtisanAcceptedServiceAdRequestPayload {
  artisanId: ServiceAdArtisanId;
  artisanName: string;
}

export const ArtisanAcceptedServiceAdRequestPayloadMetadata = {
  artisanId: {type: 'string', brand: 'ServiceAdArtisanId'},
  artisanName: {type: 'string'},
};

export function parseArtisanAcceptedServiceAdRequestPayload(
  json: Record<string, unknown>
): ArtisanAcceptedServiceAdRequestPayload {
  try {
    const parsedArtisanAcceptedServiceAdRequestPayloadMap = asMapOrThrow(json, {
      msg: `ArtisanAcceptedServiceAdRequestPayload`,
    });
    const parsedArtisanAcceptedServiceAdRequestPayloadArtisanId =
      asStringOrThrow<ServiceAdArtisanId>(
        parsedArtisanAcceptedServiceAdRequestPayloadMap['artisanId'],
        {msg: `ArtisanAcceptedServiceAdRequestPayload.artisanId`}
      );
    const parsedArtisanAcceptedServiceAdRequestPayloadArtisanName = asStringOrThrow(
      parsedArtisanAcceptedServiceAdRequestPayloadMap['artisanName'],
      {msg: `ArtisanAcceptedServiceAdRequestPayload.artisanName`}
    );
    const parsedArtisanAcceptedServiceAdRequestPayload = {
      artisanId: parsedArtisanAcceptedServiceAdRequestPayloadArtisanId,
      artisanName: parsedArtisanAcceptedServiceAdRequestPayloadArtisanName,
    };
    return parsedArtisanAcceptedServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface ArtisanDeniedServiceAdRequestPayload {}

export const ArtisanDeniedServiceAdRequestPayloadMetadata = {};

export function parseArtisanDeniedServiceAdRequestPayload(
  json: Record<string, unknown>
): ArtisanDeniedServiceAdRequestPayload {
  try {
    const parsedArtisanDeniedServiceAdRequestPayload = {};
    return parsedArtisanDeniedServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type ServiceAdRequestPayload<T extends ServiceAdRequestStatus> =
  T extends ServiceAdRequestStatus.PendingPhoneValidation
    ? PendingPhoneValidationServiceAdRequestPayload
    : T extends ServiceAdRequestStatus.PendingArtisanAcceptation
      ? PendingArtisanAcceptationServiceAdRequestPayload
      : T extends ServiceAdRequestStatus.ArtisanAccepted
        ? ArtisanAcceptedServiceAdRequestPayload
        : T extends ServiceAdRequestStatus.ArtisanDenied
          ? ArtisanDeniedServiceAdRequestPayload
          : never;

const serviceAdRequestPayloadParsers = {
  [ServiceAdRequestStatus.PendingPhoneValidation]:
    parsePendingPhoneValidationServiceAdRequestPayload,
  [ServiceAdRequestStatus.PendingArtisanAcceptation]:
    parsePendingArtisanAcceptationServiceAdRequestPayload,
  [ServiceAdRequestStatus.ArtisanAccepted]: parseArtisanAcceptedServiceAdRequestPayload,
  [ServiceAdRequestStatus.ArtisanDenied]: parseArtisanDeniedServiceAdRequestPayload,
};

export function parseServiceAdRequestPayload(
  type: ServiceAdRequestStatus,
  json: Record<string, unknown>
): ServiceAdRequestPayload<ServiceAdRequestStatus> {
  return serviceAdRequestPayloadParsers[type](json);
}

export const serviceAdRequestPayloadMetadata = {
  [ServiceAdRequestStatus.PendingPhoneValidation]:
    PendingPhoneValidationServiceAdRequestPayloadMetadata,
  [ServiceAdRequestStatus.PendingArtisanAcceptation]:
    PendingArtisanAcceptationServiceAdRequestPayloadMetadata,
  [ServiceAdRequestStatus.ArtisanAccepted]: ArtisanAcceptedServiceAdRequestPayloadMetadata,
  [ServiceAdRequestStatus.ArtisanDenied]: ArtisanDeniedServiceAdRequestPayloadMetadata,
};

export interface ServiceAdRequestItemGeneric<T extends ServiceAdRequestStatus> {
  serviceAdRequestId: ServiceAdRequestId;
  address: string;
  phone: string;
  description: string;
  status: T;
  payload: ServiceAdRequestPayload<T>;
  createdAt: string;
  updatedAt: string;
  statusHistory: ServiceAdRequestStatusHistory;
  domainId: string;
}

export type PendingPhoneValidationServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.PendingPhoneValidation>;
export type PendingArtisanAcceptationServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.PendingArtisanAcceptation>;
export type ArtisanAcceptedServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.ArtisanAccepted>;
export type ArtisanDeniedServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.ArtisanDenied>;

export type ServiceAdRequestItem =
  | PendingPhoneValidationServiceAdRequestItem
  | PendingArtisanAcceptationServiceAdRequestItem
  | ArtisanAcceptedServiceAdRequestItem
  | ArtisanDeniedServiceAdRequestItem;

export interface HoobiizStripeEventItem {
  id: StripePaymentIntentId; // a
  body: string; // b
}

export function parseHoobiizMediaVersions(
  json: Record<string, unknown>
): {bytes: number; mimeType: string; width: number; height: number; ext: string}[] {
  try {
    const parsedHoobiizMediaVersions = asArrayOrThrow(json, {msg: `HoobiizMediaVersions`}).map(
      (element, i) => {
        const parsedElementMap = asMapOrThrow(element, {msg: `HoobiizMediaVersions[${i}]`});
        const parsedElementBytes = asNumberOrThrow(parsedElementMap['bytes'], {
          msg: `HoobiizMediaVersions[${i}].bytes`,
        });
        const parsedElementMimeType = asStringOrThrow(parsedElementMap['mimeType'], {
          msg: `HoobiizMediaVersions[${i}].mimeType`,
        });
        const parsedElementWidth = asNumberOrThrow(parsedElementMap['width'], {
          msg: `HoobiizMediaVersions[${i}].width`,
        });
        const parsedElementHeight = asNumberOrThrow(parsedElementMap['height'], {
          msg: `HoobiizMediaVersions[${i}].height`,
        });
        const parsedElementExt = asStringOrThrow(parsedElementMap['ext'], {
          msg: `HoobiizMediaVersions[${i}].ext`,
        });
        const parsedElement = {
          bytes: parsedElementBytes,
          mimeType: parsedElementMimeType,
          width: parsedElementWidth,
          height: parsedElementHeight,
          ext: parsedElementExt,
        };
        return parsedElement;
      }
    );
    return parsedHoobiizMediaVersions;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizMediaVersions payload: ${errorAsString(
        err
      )}. Payload for HoobiizMediaVersions was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizMediaPlaceholder {
  width: number;
  height: number;
  base64: string;
}

export function parseHoobiizMediaPlaceholder(
  json: Record<string, unknown>
): HoobiizMediaPlaceholder {
  try {
    const parsedHoobiizMediaPlaceholderMap = asMapOrThrow(json, {msg: `HoobiizMediaPlaceholder`});
    const parsedHoobiizMediaPlaceholderWidth = asNumberOrThrow(
      parsedHoobiizMediaPlaceholderMap['width'],
      {msg: `HoobiizMediaPlaceholder.width`}
    );
    const parsedHoobiizMediaPlaceholderHeight = asNumberOrThrow(
      parsedHoobiizMediaPlaceholderMap['height'],
      {msg: `HoobiizMediaPlaceholder.height`}
    );
    const parsedHoobiizMediaPlaceholderBase64 = asStringOrThrow(
      parsedHoobiizMediaPlaceholderMap['base64'],
      {msg: `HoobiizMediaPlaceholder.base64`}
    );
    const parsedHoobiizMediaPlaceholder = {
      width: parsedHoobiizMediaPlaceholderWidth,
      height: parsedHoobiizMediaPlaceholderHeight,
      base64: parsedHoobiizMediaPlaceholderBase64,
    };
    return parsedHoobiizMediaPlaceholder;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizMediaPlaceholder payload: ${errorAsString(
        err
      )}. Payload for HoobiizMediaPlaceholder was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizMediaItem {
  id: HoobiizMediaId; // a
  hash?: string; // b
  versions: {bytes: number; mimeType: string; width: number; height: number; ext: string}[]; // c
  placeholder: HoobiizMediaPlaceholder; // h
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizCat1Item {
  id: HoobiizCat1Id; // a
  name: string; // b
  description: string; // c
  mediaId: HoobiizMediaId; // d
  order: number; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizCat2Item {
  id: HoobiizCat2Id; // a
  name: string; // b
  description: string; // c
  mediaId: HoobiizMediaId; // d
  cat1Id: HoobiizCat1Id; // e
  order: number; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizCat3Item {
  id: HoobiizCat3Id; // a
  name: string; // b
  description: string; // c
  mediaId: HoobiizMediaId; // d
  cat2Id: HoobiizCat2Id; // e
  order: number; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizVendorAddresses(
  json: Record<string, unknown>
): HoobiizVendorLocation[] {
  try {
    const parsedHoobiizVendorAddresses = asArrayOrThrow(json, {msg: `HoobiizVendorAddresses`}).map(
      (element, i) => {
        const parsedElement = parseHoobiizVendorLocation(
          asMapOrThrow(element, {msg: `HoobiizVendorAddresses[${i}]`})
        );
        return parsedElement;
      }
    );
    return parsedHoobiizVendorAddresses;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorAddresses payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorAddresses was ${JSON.stringify(json)}`
    );
  }
}

export function parseHoobiizVendorMediaIds(json: Record<string, unknown>): HoobiizMediaId[] {
  try {
    const parsedHoobiizVendorMediaIds = asArrayOrThrow(json, {msg: `HoobiizVendorMediaIds`}).map(
      (element, i) => {
        const parsedElement = asStringOrThrow<HoobiizMediaId>(element, {
          msg: `HoobiizVendorMediaIds[${i}]`,
        });
        return parsedElement;
      }
    );
    return parsedHoobiizVendorMediaIds;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorMediaIds payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorMediaIds was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizVendorItem {
  id: HoobiizVendorId; // a
  name: string; // b
  slugName: string; // c
  addresses: HoobiizVendorLocation[]; // d
  contact: HoobiizVendorContact; // e
  markdown: Markdown; // f
  mediaIds: HoobiizMediaId[]; // g
  visibility: HoobiizVisibility; // h
  comments: string; // i
  externalUrl?: string; // j
  logoMediaId?: HoobiizMediaId; // k
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizActivityCatIds(json: Record<string, unknown>): string[] {
  try {
    const parsedHoobiizActivityCatIds = asArrayOrThrow(json, {msg: `HoobiizActivityCatIds`}).map(
      (element, i) => {
        const parsedElement = asStringOrThrow(element, {msg: `HoobiizActivityCatIds[${i}]`});
        return parsedElement;
      }
    );
    return parsedHoobiizActivityCatIds;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizActivityCatIds payload: ${errorAsString(
        err
      )}. Payload for HoobiizActivityCatIds was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizActivityAddressVendor {
  type: HoobiizActivityAddressType.Vendor;
}

export function parseHoobiizActivityAddressVendor(): Omit<HoobiizActivityAddressVendor, 'type'> {
  return {};
}

export interface HoobiizActivityAddressAtHome {
  type: HoobiizActivityAddressType.AtHome;
  vendorLocationIndex?: number;
  rangeInKm?: number;
}

export function parseHoobiizActivityAddressAtHome(
  json: Record<string, unknown>
): Omit<HoobiizActivityAddressAtHome, 'type'> {
  try {
    const parsedHoobiizActivityAddressAtHomeMap = asMapOrThrow(json, {
      msg: `HoobiizActivityAddressAtHome`,
    });
    const parsedHoobiizActivityAddressAtHomeVendorLocationIndex =
      parsedHoobiizActivityAddressAtHomeMap['vendorLocationIndex'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizActivityAddressAtHomeMap['vendorLocationIndex'], {
            msg: `HoobiizActivityAddressAtHome.vendorLocationIndex`,
          });
    const parsedHoobiizActivityAddressAtHomeRangeInKm =
      parsedHoobiizActivityAddressAtHomeMap['rangeInKm'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizActivityAddressAtHomeMap['rangeInKm'], {
            msg: `HoobiizActivityAddressAtHome.rangeInKm`,
          });
    const parsedHoobiizActivityAddressAtHome = {
      vendorLocationIndex: parsedHoobiizActivityAddressAtHomeVendorLocationIndex,
      rangeInKm: parsedHoobiizActivityAddressAtHomeRangeInKm,
    };
    return parsedHoobiizActivityAddressAtHome;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizActivityAddressAtHome: ${errorAsString(err)}`);
  }
}

export interface HoobiizActivityAddressWebsite {
  type: HoobiizActivityAddressType.Website;
  websiteUrl: WebsiteUrl;
}

export function parseHoobiizActivityAddressWebsite(
  json: Record<string, unknown>
): Omit<HoobiizActivityAddressWebsite, 'type'> {
  try {
    const parsedHoobiizActivityAddressWebsiteMap = asMapOrThrow(json, {
      msg: `HoobiizActivityAddressWebsite`,
    });
    const parsedHoobiizActivityAddressWebsiteWebsiteUrl = asStringOrThrow<WebsiteUrl>(
      parsedHoobiizActivityAddressWebsiteMap['websiteUrl'],
      {msg: `HoobiizActivityAddressWebsite.websiteUrl`}
    );
    const parsedHoobiizActivityAddressWebsite = {
      websiteUrl: parsedHoobiizActivityAddressWebsiteWebsiteUrl,
    };
    return parsedHoobiizActivityAddressWebsite;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizActivityAddressWebsite: ${errorAsString(err)}`);
  }
}

export interface HoobiizActivityAddressManual {
  type: HoobiizActivityAddressType.Manual;
  address?: AddressLookupResult;
  location?: HoobiizVendorLocation;
}

export function parseHoobiizActivityAddressManual(
  json: Record<string, unknown>
): Omit<HoobiizActivityAddressManual, 'type'> {
  try {
    const parsedHoobiizActivityAddressManualMap = asMapOrThrow(json, {
      msg: `HoobiizActivityAddressManual`,
    });
    const parsedHoobiizActivityAddressManualAddress =
      parsedHoobiizActivityAddressManualMap['address'] === undefined
        ? undefined
        : parseAddressLookupResult(
            asMapOrThrow(parsedHoobiizActivityAddressManualMap['address'], {
              msg: `HoobiizActivityAddressManual.address`,
            })
          );
    const parsedHoobiizActivityAddressManualLocation =
      parsedHoobiizActivityAddressManualMap['location'] === undefined
        ? undefined
        : parseHoobiizVendorLocation(
            asMapOrThrow(parsedHoobiizActivityAddressManualMap['location'], {
              msg: `HoobiizActivityAddressManual.location`,
            })
          );
    const parsedHoobiizActivityAddressManual = {
      address: parsedHoobiizActivityAddressManualAddress,
      location: parsedHoobiizActivityAddressManualLocation,
    };
    return parsedHoobiizActivityAddressManual;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizActivityAddressManual: ${errorAsString(err)}`);
  }
}

export type HoobiizActivityAddress =
  | HoobiizActivityAddressVendor
  | HoobiizActivityAddressAtHome
  | HoobiizActivityAddressWebsite
  | HoobiizActivityAddressManual;

export function parseHoobiizActivityAddress(json: Record<string, unknown>): HoobiizActivityAddress {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizActivityAddress.type'});
    if (type === HoobiizActivityAddressType.Vendor) {
      return {type, ...parseHoobiizActivityAddressVendor()};
    } else if (type === HoobiizActivityAddressType.AtHome) {
      return {type, ...parseHoobiizActivityAddressAtHome(json)};
    } else if (type === HoobiizActivityAddressType.Website) {
      return {type, ...parseHoobiizActivityAddressWebsite(json)};
    } else if (type === HoobiizActivityAddressType.Manual) {
      return {type, ...parseHoobiizActivityAddressManual(json)};
    }
    throw new Error(`Failure to parse HoobiizActivityAddress: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizActivityAddress: ${errorAsString(err)}.`);
  }
}

export function parseHoobiizActivityMediaIds(json: Record<string, unknown>): HoobiizMediaId[] {
  try {
    const parsedHoobiizActivityMediaIds = asArrayOrThrow(json, {
      msg: `HoobiizActivityMediaIds`,
    }).map((element, i) => {
      const parsedElement = asStringOrThrow<HoobiizMediaId>(element, {
        msg: `HoobiizActivityMediaIds[${i}]`,
      });
      return parsedElement;
    });
    return parsedHoobiizActivityMediaIds;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizActivityMediaIds payload: ${errorAsString(
        err
      )}. Payload for HoobiizActivityMediaIds was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizActivityItem {
  id: HoobiizActivityId; // a
  vendorId: HoobiizVendorId; // b
  catIds: string[]; // c
  label: string; // d
  slugName: string; // e
  address: HoobiizActivityAddress; // f
  mediaIds: HoobiizMediaId[]; // g
  markdown: Markdown; // h
  visibility: HoobiizVisibility; // i
  comments: string; // j
  externalUrl?: string; // k
  cse?: boolean; // l
  headerOverride?: string; // m
  promoCodeStock?: HoobiizPromoCodeStock; // n
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizStockEntryContentPdfFile {
  type: HoobiizStockEntryContentType.PdfFile;
  ticketFileIds?: HoobiizTicketFileId[];
  qrCodes?: string[];
}

export function parseHoobiizStockEntryContentPdfFile(
  json: Record<string, unknown>
): Omit<HoobiizStockEntryContentPdfFile, 'type'> {
  try {
    const parsedHoobiizStockEntryContentPdfFileMap = asMapOrThrow(json, {
      msg: `HoobiizStockEntryContentPdfFile`,
    });
    const parsedHoobiizStockEntryContentPdfFileTicketFileIds =
      parsedHoobiizStockEntryContentPdfFileMap['ticketFileIds'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizStockEntryContentPdfFileMap['ticketFileIds'], {
            msg: `HoobiizStockEntryContentPdfFile.ticketFileIds`,
          }).map((element, i) => {
            const parsedElement = asStringOrThrow<HoobiizTicketFileId>(element, {
              msg: `HoobiizStockEntryContentPdfFile.ticketFileIds[${i}]`,
            });
            return parsedElement;
          });
    const parsedHoobiizStockEntryContentPdfFileQrCodes =
      parsedHoobiizStockEntryContentPdfFileMap['qrCodes'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizStockEntryContentPdfFileMap['qrCodes'], {
            msg: `HoobiizStockEntryContentPdfFile.qrCodes`,
          }).map((element, i) => {
            const parsedElement = asStringOrThrow(element, {
              msg: `HoobiizStockEntryContentPdfFile.qrCodes[${i}]`,
            });
            return parsedElement;
          });
    const parsedHoobiizStockEntryContentPdfFile = {
      ticketFileIds: parsedHoobiizStockEntryContentPdfFileTicketFileIds,
      qrCodes: parsedHoobiizStockEntryContentPdfFileQrCodes,
    };
    return parsedHoobiizStockEntryContentPdfFile;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockEntryContentPdfFile: ${errorAsString(err)}`);
  }
}

export type HoobiizStockEntryContent = HoobiizStockEntryContentPdfFile;

export function parseHoobiizStockEntryContent(
  json: Record<string, unknown>
): HoobiizStockEntryContent {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizStockEntryContent.type'});
    if (type === HoobiizStockEntryContentType.PdfFile) {
      return {type, ...parseHoobiizStockEntryContentPdfFile(json)};
    }
    throw new Error(`Failure to parse HoobiizStockEntryContent: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockEntryContent: ${errorAsString(err)}.`);
  }
}

export function parseHoobiizStockEntryHistory(
  json: Record<string, unknown> | undefined
): {ts: number; event: string; source: string}[] | undefined {
  if (json === undefined) {
    return undefined;
  }
  try {
    const parsedHoobiizStockEntryHistory = asArrayOrThrow(json, {
      msg: `HoobiizStockEntryHistory`,
    }).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {msg: `HoobiizStockEntryHistory[${i}]`});
      const parsedElementTs = asNumberOrThrow(parsedElementMap['ts'], {
        msg: `HoobiizStockEntryHistory[${i}].ts`,
      });
      const parsedElementEvent = asStringOrThrow(parsedElementMap['event'], {
        msg: `HoobiizStockEntryHistory[${i}].event`,
      });
      const parsedElementSource = asStringOrThrow(parsedElementMap['source'], {
        msg: `HoobiizStockEntryHistory[${i}].source`,
      });
      const parsedElement = {
        ts: parsedElementTs,
        event: parsedElementEvent,
        source: parsedElementSource,
      };
      return parsedElement;
    });
    return parsedHoobiizStockEntryHistory;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizStockEntryHistory payload: ${errorAsString(
        err
      )}. Payload for HoobiizStockEntryHistory was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizStockEntryItem {
  id: HoobiizStockEntryId; // a
  stockId: HoobiizStockId; // b
  orderId: HoobiizOrderItemId; // c
  status: HoobiizStockEntryStatus; // d
  content?: HoobiizStockEntryContent; // e
  history?: {ts: number; event: string; source: string}[]; // f
}

export function parseHoobiizStockTicketInfo(json: Record<string, unknown>): HoobiizTicketInfo[] {
  try {
    const parsedHoobiizStockTicketInfo = asArrayOrThrow(json, {msg: `HoobiizStockTicketInfo`}).map(
      (element, i) => {
        const parsedElement = parseHoobiizTicketInfo(
          asMapOrThrow(element, {msg: `HoobiizStockTicketInfo[${i}]`})
        );
        return parsedElement;
      }
    );
    return parsedHoobiizStockTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizStockTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizStockTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

/**
 * Tickets from the stock can be used at any time within a period.
 */
export interface HoobiizStockReservationFlexible {
  type: HoobiizStockReservationType.Flexible;
  schedule: HoobiizFlexibleStockSchedule;
  period: HoobiizTimePeriod;
}

export function parseHoobiizStockReservationFlexible(
  json: Record<string, unknown>
): Omit<HoobiizStockReservationFlexible, 'type'> {
  try {
    const parsedHoobiizStockReservationFlexibleMap = asMapOrThrow(json, {
      msg: `HoobiizStockReservationFlexible`,
    });
    const parsedHoobiizStockReservationFlexibleSchedule = parseHoobiizFlexibleStockSchedule(
      asMapOrThrow(parsedHoobiizStockReservationFlexibleMap['schedule'], {
        msg: `HoobiizStockReservationFlexible.schedule`,
      })
    );
    const parsedHoobiizStockReservationFlexiblePeriod = parseHoobiizTimePeriod(
      asMapOrThrow(parsedHoobiizStockReservationFlexibleMap['period'], {
        msg: `HoobiizStockReservationFlexible.period`,
      })
    );
    const parsedHoobiizStockReservationFlexible = {
      schedule: parsedHoobiizStockReservationFlexibleSchedule,
      period: parsedHoobiizStockReservationFlexiblePeriod,
    };
    return parsedHoobiizStockReservationFlexible;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockReservationFlexible: ${errorAsString(err)}`);
  }
}

/**
 * Tickets from the stock can only be used at a specific day and time.
 */
export interface HoobiizStockReservationFixed {
  type: HoobiizStockReservationType.Fixed;
  period: HoobiizTimePeriod;
}

export function parseHoobiizStockReservationFixed(
  json: Record<string, unknown>
): Omit<HoobiizStockReservationFixed, 'type'> {
  try {
    const parsedHoobiizStockReservationFixedMap = asMapOrThrow(json, {
      msg: `HoobiizStockReservationFixed`,
    });
    const parsedHoobiizStockReservationFixedPeriod = parseHoobiizTimePeriod(
      asMapOrThrow(parsedHoobiizStockReservationFixedMap['period'], {
        msg: `HoobiizStockReservationFixed.period`,
      })
    );
    const parsedHoobiizStockReservationFixed = {
      period: parsedHoobiizStockReservationFixedPeriod,
    };
    return parsedHoobiizStockReservationFixed;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockReservationFixed: ${errorAsString(err)}`);
  }
}

export type HoobiizStockReservation =
  | HoobiizStockReservationFlexible
  | HoobiizStockReservationFixed;

export function parseHoobiizStockReservation(
  json: Record<string, unknown>
): HoobiizStockReservation {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizStockReservation.type'});
    if (type === HoobiizStockReservationType.Flexible) {
      return {type, ...parseHoobiizStockReservationFlexible(json)};
    } else if (type === HoobiizStockReservationType.Fixed) {
      return {type, ...parseHoobiizStockReservationFixed(json)};
    }
    throw new Error(`Failure to parse HoobiizStockReservation: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockReservation: ${errorAsString(err)}.`);
  }
}

export interface HoobiizStockItem {
  id: HoobiizStockId; // a
  activityId: HoobiizActivityId; // b
  batchId: HoobiizStockBatchId; // c
  quantity: number; // d
  reserved: number; // e
  bought: number; // f
  remaining: number; // l
  ticketInfo: HoobiizTicketInfo[]; // g
  visibility: HoobiizVisibility; // h
  reservation: HoobiizStockReservation; // i
  mode: HoobiizStockMode; // j
  weeklyTemplateId?: HoobiizStockWeeklyTemplateId; // k
  terms?: string; // m
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizExpertTicketStockTicketInfo(
  json: Record<string, unknown>
): HoobiizExpertTicketInfo[] {
  try {
    const parsedHoobiizExpertTicketStockTicketInfo = asArrayOrThrow(json, {
      msg: `HoobiizExpertTicketStockTicketInfo`,
    }).map((element, i) => {
      const parsedElement = parseHoobiizExpertTicketInfo(
        asMapOrThrow(element, {msg: `HoobiizExpertTicketStockTicketInfo[${i}]`})
      );
      return parsedElement;
    });
    return parsedHoobiizExpertTicketStockTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizExpertTicketStockTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizExpertTicketStockTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizExpertTicketStockItem {
  id: HoobiizExpertTicketStockId; // a
  activityId: HoobiizActivityId; // b
  ticketInfo: HoobiizExpertTicketInfo[]; // c
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizStockWeeklyTemplateTicketInfo(
  json: Record<string, unknown>
): HoobiizTicketInfo[] {
  try {
    const parsedHoobiizStockWeeklyTemplateTicketInfo = asArrayOrThrow(json, {
      msg: `HoobiizStockWeeklyTemplateTicketInfo`,
    }).map((element, i) => {
      const parsedElement = parseHoobiizTicketInfo(
        asMapOrThrow(element, {msg: `HoobiizStockWeeklyTemplateTicketInfo[${i}]`})
      );
      return parsedElement;
    });
    return parsedHoobiizStockWeeklyTemplateTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizStockWeeklyTemplateTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizStockWeeklyTemplateTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizStockWeeklyTemplateItem {
  id: HoobiizStockWeeklyTemplateId; // a
  activityId: HoobiizActivityId; // b
  batchId: HoobiizStockBatchId; // c
  quantity: number; // d
  ticketInfo: HoobiizTicketInfo[]; // e
  visibility: HoobiizVisibility; // f
  period: HoobiizTimePeriod; // g
  mode: HoobiizStockMode; // h
  timeOfDay: HoobiizTimeOfDay; // i
  weekday: HoobiizWeekday; // j
  terms?: string; // k
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizOfferTargetGlobal {
  type: HoobiizOfferTargetType.Global;
}

export function parseHoobiizOfferTargetGlobal(): Omit<HoobiizOfferTargetGlobal, 'type'> {
  return {};
}

export interface HoobiizOfferTargetVendor {
  type: HoobiizOfferTargetType.Vendor;
  vendorId: HoobiizVendorId;
}

export function parseHoobiizOfferTargetVendor(
  json: Record<string, unknown>
): Omit<HoobiizOfferTargetVendor, 'type'> {
  try {
    const parsedHoobiizOfferTargetVendorMap = asMapOrThrow(json, {msg: `HoobiizOfferTargetVendor`});
    const parsedHoobiizOfferTargetVendorVendorId = asStringOrThrow<HoobiizVendorId>(
      parsedHoobiizOfferTargetVendorMap['vendorId'],
      {msg: `HoobiizOfferTargetVendor.vendorId`}
    );
    const parsedHoobiizOfferTargetVendor = {
      vendorId: parsedHoobiizOfferTargetVendorVendorId,
    };
    return parsedHoobiizOfferTargetVendor;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferTargetVendor: ${errorAsString(err)}`);
  }
}

export interface HoobiizOfferTargetActivity {
  type: HoobiizOfferTargetType.Activity;
  activityId: HoobiizActivityId;
  reservation?: {period?: HoobiizTimePeriod; hours: HoobiizWeekPeriods};
}

export function parseHoobiizOfferTargetActivity(
  json: Record<string, unknown>
): Omit<HoobiizOfferTargetActivity, 'type'> {
  try {
    const parsedHoobiizOfferTargetActivityMap = asMapOrThrow(json, {
      msg: `HoobiizOfferTargetActivity`,
    });
    const parsedHoobiizOfferTargetActivityActivityId = asStringOrThrow<HoobiizActivityId>(
      parsedHoobiizOfferTargetActivityMap['activityId'],
      {msg: `HoobiizOfferTargetActivity.activityId`}
    );
    const parsedHoobiizOfferTargetActivityReservationMap =
      parsedHoobiizOfferTargetActivityMap['reservation'] === undefined
        ? undefined
        : asMapOrThrow(parsedHoobiizOfferTargetActivityMap['reservation'], {
            msg: `HoobiizOfferTargetActivity.reservation`,
          });
    const parsedHoobiizOfferTargetActivityReservation =
      parsedHoobiizOfferTargetActivityReservationMap === undefined
        ? undefined
        : (() => {
            const parsedHoobiizOfferTargetActivityReservationPeriod =
              parsedHoobiizOfferTargetActivityReservationMap['period'] === undefined
                ? undefined
                : parseHoobiizTimePeriod(
                    asMapOrThrow(parsedHoobiizOfferTargetActivityReservationMap['period'], {
                      msg: `HoobiizOfferTargetActivity.reservation.period`,
                    })
                  );
            const parsedHoobiizOfferTargetActivityReservationHours = parseHoobiizWeekPeriods(
              asMapOrThrow(parsedHoobiizOfferTargetActivityReservationMap['hours'], {
                msg: `HoobiizOfferTargetActivity.reservation.hours`,
              })
            );
            return {
              period: parsedHoobiizOfferTargetActivityReservationPeriod,
              hours: parsedHoobiizOfferTargetActivityReservationHours,
            };
          })();
    const parsedHoobiizOfferTargetActivity = {
      activityId: parsedHoobiizOfferTargetActivityActivityId,
      reservation: parsedHoobiizOfferTargetActivityReservation,
    };
    return parsedHoobiizOfferTargetActivity;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferTargetActivity: ${errorAsString(err)}`);
  }
}

export type HoobiizOfferTarget =
  | HoobiizOfferTargetGlobal
  | HoobiizOfferTargetVendor
  | HoobiizOfferTargetActivity;

export function parseHoobiizOfferTarget(json: Record<string, unknown>): HoobiizOfferTarget {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizOfferTarget.type'});
    if (type === HoobiizOfferTargetType.Global) {
      return {type, ...parseHoobiizOfferTargetGlobal()};
    } else if (type === HoobiizOfferTargetType.Vendor) {
      return {type, ...parseHoobiizOfferTargetVendor(json)};
    } else if (type === HoobiizOfferTargetType.Activity) {
      return {type, ...parseHoobiizOfferTargetActivity(json)};
    }
    throw new Error(`Failure to parse HoobiizOfferTarget: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferTarget: ${errorAsString(err)}.`);
  }
}

export interface HoobiizOfferFlashDisabled {
  type: HoobiizOfferFlashType.Disabled;
}

export function parseHoobiizOfferFlashDisabled(): Omit<HoobiizOfferFlashDisabled, 'type'> {
  return {};
}

export interface HoobiizOfferFlashPeriod {
  type: HoobiizOfferFlashType.Period;
  period: HoobiizTimePeriod;
}

export function parseHoobiizOfferFlashPeriod(
  json: Record<string, unknown>
): Omit<HoobiizOfferFlashPeriod, 'type'> {
  try {
    const parsedHoobiizOfferFlashPeriodMap = asMapOrThrow(json, {msg: `HoobiizOfferFlashPeriod`});
    const parsedHoobiizOfferFlashPeriodPeriod = parseHoobiizTimePeriod(
      asMapOrThrow(parsedHoobiizOfferFlashPeriodMap['period'], {
        msg: `HoobiizOfferFlashPeriod.period`,
      })
    );
    const parsedHoobiizOfferFlashPeriod = {
      period: parsedHoobiizOfferFlashPeriodPeriod,
    };
    return parsedHoobiizOfferFlashPeriod;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferFlashPeriod: ${errorAsString(err)}`);
  }
}

export type HoobiizOfferFlash = HoobiizOfferFlashDisabled | HoobiizOfferFlashPeriod;

export function parseHoobiizOfferFlash(json: Record<string, unknown>): HoobiizOfferFlash {
  try {
    const type = asStringOrThrow(json.type, {msg: 'HoobiizOfferFlash.type'});
    if (type === HoobiizOfferFlashType.Disabled) {
      return {type, ...parseHoobiizOfferFlashDisabled()};
    } else if (type === HoobiizOfferFlashType.Period) {
      return {type, ...parseHoobiizOfferFlashPeriod(json)};
    }
    throw new Error(`Failure to parse HoobiizOfferFlash: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferFlash: ${errorAsString(err)}.`);
  }
}

export interface HoobiizOfferItem {
  id: HoobiizOfferId; // a
  label: string; // b
  target: HoobiizOfferTarget; // c
  flash: HoobiizOfferFlash; // d
  discount: HoobiizDiscount; // e
  visibility: HoobiizVisibility; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizTicketFileItem {
  id: HoobiizTicketFileId; // a
  mimeType: string; // b
  size: number; // c
  origin?: HoobiizTicketFileOrigin; // d
  createdAt: number; // y
  updatedAt: number; // z
}

export interface TicketHoobiizCartItemPayload {
  stockIds: HoobiizStockId[];
  offerId?: HoobiizOfferId;
  ticketInfoId: HoobiizTicketInfoId;
  quantity: number;
  options: {optionId: HoobiizTicketInfoOptionId; quantity: number}[];
}

export const TicketHoobiizCartItemPayloadMetadata = {
  stockIds: {type: 'array', schema: {type: 'string', brand: 'HoobiizStockId'}},
  offerId: {type: 'string', brand: 'HoobiizOfferId', optional: true},
  ticketInfoId: {type: 'string', brand: 'HoobiizTicketInfoId'},
  quantity: {type: 'number'},
  options: {
    type: 'array',
    schema: {
      type: 'object',
      schema: {
        optionId: {type: 'string', brand: 'HoobiizTicketInfoOptionId'},
        quantity: {type: 'number'},
      },
    },
  },
};

export function parseTicketHoobiizCartItemPayload(
  json: Record<string, unknown>
): TicketHoobiizCartItemPayload {
  try {
    const parsedTicketHoobiizCartItemPayloadMap = asMapOrThrow(json, {
      msg: `TicketHoobiizCartItemPayload`,
    });
    const parsedTicketHoobiizCartItemPayloadStockIds = asArrayOrThrow(
      parsedTicketHoobiizCartItemPayloadMap['stockIds'],
      {msg: `TicketHoobiizCartItemPayload.stockIds`}
    ).map((element, i) => {
      const parsedElement = asStringOrThrow<HoobiizStockId>(element, {
        msg: `TicketHoobiizCartItemPayload.stockIds[${i}]`,
      });
      return parsedElement;
    });
    const parsedTicketHoobiizCartItemPayloadOfferId =
      parsedTicketHoobiizCartItemPayloadMap['offerId'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizOfferId>(parsedTicketHoobiizCartItemPayloadMap['offerId'], {
            msg: `TicketHoobiizCartItemPayload.offerId`,
          });
    const parsedTicketHoobiizCartItemPayloadTicketInfoId = asStringOrThrow<HoobiizTicketInfoId>(
      parsedTicketHoobiizCartItemPayloadMap['ticketInfoId'],
      {msg: `TicketHoobiizCartItemPayload.ticketInfoId`}
    );
    const parsedTicketHoobiizCartItemPayloadQuantity = asNumberOrThrow(
      parsedTicketHoobiizCartItemPayloadMap['quantity'],
      {msg: `TicketHoobiizCartItemPayload.quantity`}
    );
    const parsedTicketHoobiizCartItemPayloadOptions = asArrayOrThrow(
      parsedTicketHoobiizCartItemPayloadMap['options'],
      {msg: `TicketHoobiizCartItemPayload.options`}
    ).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {
        msg: `TicketHoobiizCartItemPayload.options[${i}]`,
      });
      const parsedElementOptionId = asStringOrThrow<HoobiizTicketInfoOptionId>(
        parsedElementMap['optionId'],
        {msg: `TicketHoobiizCartItemPayload.options[${i}].optionId`}
      );
      const parsedElementQuantity = asNumberOrThrow(parsedElementMap['quantity'], {
        msg: `TicketHoobiizCartItemPayload.options[${i}].quantity`,
      });
      const parsedElement = {
        optionId: parsedElementOptionId,
        quantity: parsedElementQuantity,
      };
      return parsedElement;
    });
    const parsedTicketHoobiizCartItemPayload = {
      stockIds: parsedTicketHoobiizCartItemPayloadStockIds,
      offerId: parsedTicketHoobiizCartItemPayloadOfferId,
      ticketInfoId: parsedTicketHoobiizCartItemPayloadTicketInfoId,
      quantity: parsedTicketHoobiizCartItemPayloadQuantity,
      options: parsedTicketHoobiizCartItemPayloadOptions,
    };
    return parsedTicketHoobiizCartItemPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface ExpertTicketHoobiizCartItemPayload {
  stockId: HoobiizExpertTicketStockId;
  ticketInfoId: HoobiizExpertTicketInfoId;
  quantity: number;
  date?: number;
}

export const ExpertTicketHoobiizCartItemPayloadMetadata = {
  stockId: {type: 'string', brand: 'HoobiizExpertTicketStockId'},
  ticketInfoId: {type: 'string', brand: 'HoobiizExpertTicketInfoId'},
  quantity: {type: 'number'},
  date: {type: 'number', optional: true},
};

export function parseExpertTicketHoobiizCartItemPayload(
  json: Record<string, unknown>
): ExpertTicketHoobiizCartItemPayload {
  try {
    const parsedExpertTicketHoobiizCartItemPayloadMap = asMapOrThrow(json, {
      msg: `ExpertTicketHoobiizCartItemPayload`,
    });
    const parsedExpertTicketHoobiizCartItemPayloadStockId =
      asStringOrThrow<HoobiizExpertTicketStockId>(
        parsedExpertTicketHoobiizCartItemPayloadMap['stockId'],
        {msg: `ExpertTicketHoobiizCartItemPayload.stockId`}
      );
    const parsedExpertTicketHoobiizCartItemPayloadTicketInfoId =
      asStringOrThrow<HoobiizExpertTicketInfoId>(
        parsedExpertTicketHoobiizCartItemPayloadMap['ticketInfoId'],
        {msg: `ExpertTicketHoobiizCartItemPayload.ticketInfoId`}
      );
    const parsedExpertTicketHoobiizCartItemPayloadQuantity = asNumberOrThrow(
      parsedExpertTicketHoobiizCartItemPayloadMap['quantity'],
      {msg: `ExpertTicketHoobiizCartItemPayload.quantity`}
    );
    const parsedExpertTicketHoobiizCartItemPayloadDate =
      parsedExpertTicketHoobiizCartItemPayloadMap['date'] === undefined
        ? undefined
        : asNumberOrThrow(parsedExpertTicketHoobiizCartItemPayloadMap['date'], {
            msg: `ExpertTicketHoobiizCartItemPayload.date`,
          });
    const parsedExpertTicketHoobiizCartItemPayload = {
      stockId: parsedExpertTicketHoobiizCartItemPayloadStockId,
      ticketInfoId: parsedExpertTicketHoobiizCartItemPayloadTicketInfoId,
      quantity: parsedExpertTicketHoobiizCartItemPayloadQuantity,
      date: parsedExpertTicketHoobiizCartItemPayloadDate,
    };
    return parsedExpertTicketHoobiizCartItemPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type HoobiizCartItemPayload<T extends HoobiizCartItemType> =
  T extends HoobiizCartItemType.Ticket
    ? TicketHoobiizCartItemPayload
    : T extends HoobiizCartItemType.ExpertTicket
      ? ExpertTicketHoobiizCartItemPayload
      : never;

const hoobiizCartItemPayloadParsers = {
  [HoobiizCartItemType.Ticket]: parseTicketHoobiizCartItemPayload,
  [HoobiizCartItemType.ExpertTicket]: parseExpertTicketHoobiizCartItemPayload,
};

export function parseHoobiizCartItemPayload(
  type: HoobiizCartItemType,
  json: Record<string, unknown>
): HoobiizCartItemPayload<HoobiizCartItemType> {
  return hoobiizCartItemPayloadParsers[type](json);
}

export const hoobiizCartItemPayloadMetadata = {
  [HoobiizCartItemType.Ticket]: TicketHoobiizCartItemPayloadMetadata,
  [HoobiizCartItemType.ExpertTicket]: ExpertTicketHoobiizCartItemPayloadMetadata,
};

export interface HoobiizCartItemItemGeneric<T extends HoobiizCartItemType> {
  id: HoobiizCartItemId;
  userId: FrontendUserId;
  itemType: T;
  itemData: HoobiizCartItemPayload<T>;
  addedAt: number;
}

export type TicketHoobiizCartItemItem = HoobiizCartItemItemGeneric<HoobiizCartItemType.Ticket>;
export type ExpertTicketHoobiizCartItemItem =
  HoobiizCartItemItemGeneric<HoobiizCartItemType.ExpertTicket>;

export type HoobiizCartItemItem = TicketHoobiizCartItemItem | ExpertTicketHoobiizCartItemItem;

export interface HoobiizOrderItemFailureItem {
  id: string; // a
  v: number; // b
  ts: number; // c
  orderId: HoobiizOrderItemId; // d
  paymentId: StripePaymentIntentId; // e
  error: string; // f
}

export interface HoobiizOrderItemTicketInfo {
  id: string;
  label: string;
  description?: string;
}

export function parseHoobiizOrderItemTicketInfo(
  json: Record<string, unknown>
): HoobiizOrderItemTicketInfo {
  try {
    const parsedHoobiizOrderItemTicketInfoMap = asMapOrThrow(json, {
      msg: `HoobiizOrderItemTicketInfo`,
    });
    const parsedHoobiizOrderItemTicketInfoId = asStringOrThrow(
      parsedHoobiizOrderItemTicketInfoMap['id'],
      {msg: `HoobiizOrderItemTicketInfo.id`}
    );
    const parsedHoobiizOrderItemTicketInfoLabel = asStringOrThrow(
      parsedHoobiizOrderItemTicketInfoMap['label'],
      {msg: `HoobiizOrderItemTicketInfo.label`}
    );
    const parsedHoobiizOrderItemTicketInfoDescription =
      parsedHoobiizOrderItemTicketInfoMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizOrderItemTicketInfoMap['description'], {
            msg: `HoobiizOrderItemTicketInfo.description`,
          });
    const parsedHoobiizOrderItemTicketInfo = {
      id: parsedHoobiizOrderItemTicketInfoId,
      label: parsedHoobiizOrderItemTicketInfoLabel,
      description: parsedHoobiizOrderItemTicketInfoDescription,
    };
    return parsedHoobiizOrderItemTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOrderItemTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizOrderItemTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export function parseHoobiizOrderItemOptions(json: Record<string, unknown>): {
  option: {id: HoobiizTicketInfoOptionId; label: string; description?: string};
  quantity: number;
  total: HoobiizPrices;
}[] {
  try {
    const parsedHoobiizOrderItemOptions = asArrayOrThrow(json, {
      msg: `HoobiizOrderItemOptions`,
    }).map((element, i) => {
      const parsedElementMap = asMapOrThrow(element, {msg: `HoobiizOrderItemOptions[${i}]`});
      const parsedElementOptionMap = asMapOrThrow(parsedElementMap['option'], {
        msg: `HoobiizOrderItemOptions[${i}].option`,
      });
      const parsedElementOptionId = asStringOrThrow<HoobiizTicketInfoOptionId>(
        parsedElementOptionMap['id'],
        {msg: `HoobiizOrderItemOptions[${i}].option.id`}
      );
      const parsedElementOptionLabel = asStringOrThrow(parsedElementOptionMap['label'], {
        msg: `HoobiizOrderItemOptions[${i}].option.label`,
      });
      const parsedElementOptionDescription =
        parsedElementOptionMap['description'] === undefined
          ? undefined
          : asStringOrThrow(parsedElementOptionMap['description'], {
              msg: `HoobiizOrderItemOptions[${i}].option.description`,
            });
      const parsedElementOption = {
        id: parsedElementOptionId,
        label: parsedElementOptionLabel,
        description: parsedElementOptionDescription,
      };
      const parsedElementQuantity = asNumberOrThrow(parsedElementMap['quantity'], {
        msg: `HoobiizOrderItemOptions[${i}].quantity`,
      });
      const parsedElementTotal = parseHoobiizPrices(
        asMapOrThrow(parsedElementMap['total'], {msg: `HoobiizOrderItemOptions[${i}].total`})
      );
      const parsedElement = {
        option: parsedElementOption,
        quantity: parsedElementQuantity,
        total: parsedElementTotal,
      };
      return parsedElement;
    });
    return parsedHoobiizOrderItemOptions;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOrderItemOptions payload: ${errorAsString(
        err
      )}. Payload for HoobiizOrderItemOptions was ${JSON.stringify(json)}`
    );
  }
}

export interface TicketHoobiizOrderItemPayload {
  stockIds: HoobiizStockId[];
  offerId?: HoobiizOfferId;
}

export const TicketHoobiizOrderItemPayloadMetadata = {
  stockIds: {type: 'array', schema: {type: 'string', brand: 'HoobiizStockId'}},
  offerId: {type: 'string', brand: 'HoobiizOfferId', optional: true},
};

export function parseTicketHoobiizOrderItemPayload(
  json: Record<string, unknown>
): TicketHoobiizOrderItemPayload {
  try {
    const parsedTicketHoobiizOrderItemPayloadMap = asMapOrThrow(json, {
      msg: `TicketHoobiizOrderItemPayload`,
    });
    const parsedTicketHoobiizOrderItemPayloadStockIds = asArrayOrThrow(
      parsedTicketHoobiizOrderItemPayloadMap['stockIds'],
      {msg: `TicketHoobiizOrderItemPayload.stockIds`}
    ).map((element, i) => {
      const parsedElement = asStringOrThrow<HoobiizStockId>(element, {
        msg: `TicketHoobiizOrderItemPayload.stockIds[${i}]`,
      });
      return parsedElement;
    });
    const parsedTicketHoobiizOrderItemPayloadOfferId =
      parsedTicketHoobiizOrderItemPayloadMap['offerId'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizOfferId>(parsedTicketHoobiizOrderItemPayloadMap['offerId'], {
            msg: `TicketHoobiizOrderItemPayload.offerId`,
          });
    const parsedTicketHoobiizOrderItemPayload = {
      stockIds: parsedTicketHoobiizOrderItemPayloadStockIds,
      offerId: parsedTicketHoobiizOrderItemPayloadOfferId,
    };
    return parsedTicketHoobiizOrderItemPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface ExpertTicketHoobiizOrderItemPayload {
  productId: HoobiizExpertTicketProductId;
  date?: number;
  reservationId: string;
  stockId: HoobiizExpertTicketStockId;
  documents?: {saleId: string; index: number; contentType?: string; contentDisposition?: string}[];
}

export const ExpertTicketHoobiizOrderItemPayloadMetadata = {
  productId: {type: 'string', brand: 'HoobiizExpertTicketProductId'},
  date: {type: 'number', optional: true},
  reservationId: {type: 'string'},
  stockId: {type: 'string', brand: 'HoobiizExpertTicketStockId'},
  documents: {
    type: 'array',
    optional: true,
    schema: {
      type: 'object',
      schema: {
        saleId: {type: 'string'},
        index: {type: 'number'},
        contentType: {type: 'string', optional: true},
        contentDisposition: {type: 'string', optional: true},
      },
    },
  },
};

export function parseExpertTicketHoobiizOrderItemPayload(
  json: Record<string, unknown>
): ExpertTicketHoobiizOrderItemPayload {
  try {
    const parsedExpertTicketHoobiizOrderItemPayloadMap = asMapOrThrow(json, {
      msg: `ExpertTicketHoobiizOrderItemPayload`,
    });
    const parsedExpertTicketHoobiizOrderItemPayloadProductId =
      asStringOrThrow<HoobiizExpertTicketProductId>(
        parsedExpertTicketHoobiizOrderItemPayloadMap['productId'],
        {msg: `ExpertTicketHoobiizOrderItemPayload.productId`}
      );
    const parsedExpertTicketHoobiizOrderItemPayloadDate =
      parsedExpertTicketHoobiizOrderItemPayloadMap['date'] === undefined
        ? undefined
        : asNumberOrThrow(parsedExpertTicketHoobiizOrderItemPayloadMap['date'], {
            msg: `ExpertTicketHoobiizOrderItemPayload.date`,
          });
    const parsedExpertTicketHoobiizOrderItemPayloadReservationId = asStringOrThrow(
      parsedExpertTicketHoobiizOrderItemPayloadMap['reservationId'],
      {msg: `ExpertTicketHoobiizOrderItemPayload.reservationId`}
    );
    const parsedExpertTicketHoobiizOrderItemPayloadStockId =
      asStringOrThrow<HoobiizExpertTicketStockId>(
        parsedExpertTicketHoobiizOrderItemPayloadMap['stockId'],
        {msg: `ExpertTicketHoobiizOrderItemPayload.stockId`}
      );
    const parsedExpertTicketHoobiizOrderItemPayloadDocuments =
      parsedExpertTicketHoobiizOrderItemPayloadMap['documents'] === undefined
        ? undefined
        : asArrayOrThrow(parsedExpertTicketHoobiizOrderItemPayloadMap['documents'], {
            msg: `ExpertTicketHoobiizOrderItemPayload.documents`,
          }).map((element, i) => {
            const parsedElementMap = asMapOrThrow(element, {
              msg: `ExpertTicketHoobiizOrderItemPayload.documents[${i}]`,
            });
            const parsedElementSaleId = asStringOrThrow(parsedElementMap['saleId'], {
              msg: `ExpertTicketHoobiizOrderItemPayload.documents[${i}].saleId`,
            });
            const parsedElementIndex = asNumberOrThrow(parsedElementMap['index'], {
              msg: `ExpertTicketHoobiizOrderItemPayload.documents[${i}].index`,
            });
            const parsedElementContentType =
              parsedElementMap['contentType'] === undefined
                ? undefined
                : asStringOrThrow(parsedElementMap['contentType'], {
                    msg: `ExpertTicketHoobiizOrderItemPayload.documents[${i}].contentType`,
                  });
            const parsedElementContentDisposition =
              parsedElementMap['contentDisposition'] === undefined
                ? undefined
                : asStringOrThrow(parsedElementMap['contentDisposition'], {
                    msg: `ExpertTicketHoobiizOrderItemPayload.documents[${i}].contentDisposition`,
                  });
            const parsedElement = {
              saleId: parsedElementSaleId,
              index: parsedElementIndex,
              contentType: parsedElementContentType,
              contentDisposition: parsedElementContentDisposition,
            };
            return parsedElement;
          });
    const parsedExpertTicketHoobiizOrderItemPayload = {
      productId: parsedExpertTicketHoobiizOrderItemPayloadProductId,
      date: parsedExpertTicketHoobiizOrderItemPayloadDate,
      reservationId: parsedExpertTicketHoobiizOrderItemPayloadReservationId,
      stockId: parsedExpertTicketHoobiizOrderItemPayloadStockId,
      documents: parsedExpertTicketHoobiizOrderItemPayloadDocuments,
    };
    return parsedExpertTicketHoobiizOrderItemPayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type HoobiizOrderItemPayload<T extends HoobiizOrderItemType> =
  T extends HoobiizOrderItemType.Ticket
    ? TicketHoobiizOrderItemPayload
    : T extends HoobiizOrderItemType.ExpertTicket
      ? ExpertTicketHoobiizOrderItemPayload
      : never;

const hoobiizOrderItemPayloadParsers = {
  [HoobiizOrderItemType.Ticket]: parseTicketHoobiizOrderItemPayload,
  [HoobiizOrderItemType.ExpertTicket]: parseExpertTicketHoobiizOrderItemPayload,
};

export function parseHoobiizOrderItemPayload(
  type: HoobiizOrderItemType,
  json: Record<string, unknown>
): HoobiizOrderItemPayload<HoobiizOrderItemType> {
  return hoobiizOrderItemPayloadParsers[type](json);
}

export const hoobiizOrderItemPayloadMetadata = {
  [HoobiizOrderItemType.Ticket]: TicketHoobiizOrderItemPayloadMetadata,
  [HoobiizOrderItemType.ExpertTicket]: ExpertTicketHoobiizOrderItemPayloadMetadata,
};

export interface HoobiizOrderItemItemGeneric<T extends HoobiizOrderItemType> {
  id: HoobiizOrderItemId;
  userId: FrontendUserId;
  itemType: T;
  itemData: HoobiizOrderItemPayload<T>;
  total: HoobiizPrices;
  fees: CurrencyAmount;
  ticketInfo: HoobiizOrderItemTicketInfo;
  ticketInfoTotal: HoobiizPrices;
  quantity: number;
  activityId: HoobiizActivityId;
  options: {
    option: {id: HoobiizTicketInfoOptionId; label: string; description?: string};
    quantity: number;
    total: HoobiizPrices;
  }[];
  inCartAt: number;
  startedAt: number;
  paymentId: StripePaymentIntentId;
  paymentClientSecret?: string;
  status: HoobiizOrderStatus;
  endedAt?: number;
  confirmationToken?: string;
  firstStockId: HoobiizStockId | HoobiizExpertTicketStockId;
}

export type TicketHoobiizOrderItemItem = HoobiizOrderItemItemGeneric<HoobiizOrderItemType.Ticket>;
export type ExpertTicketHoobiizOrderItemItem =
  HoobiizOrderItemItemGeneric<HoobiizOrderItemType.ExpertTicket>;

export type HoobiizOrderItemItem = TicketHoobiizOrderItemItem | ExpertTicketHoobiizOrderItemItem;

export interface HoobiizExpertTicketProviderItem {
  id: HoobiizExpertTicketProviderId; // a
  payload: ExpertTicketProviderPayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketProductBaseItem {
  id: HoobiizExpertTicketProductBaseId; // a
  payload: ExpertTicketProductBasePayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketProductItem {
  id: HoobiizExpertTicketProductId; // a
  payload: ExpertTicketProductPayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketTicketItem {
  id: HoobiizExpertTicketTicketId; // a
  payload: ExpertTicketTicketPayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketPriceItem {
  id: string; // a
  productId: HoobiizExpertTicketProductId; // b
  ts: number; // c
  cents: number; // d
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketMetadataItem {
  key: HoobiizExpertTicketMetadataKey; // a
  value: string; // b
}

export interface HoobiizUserGroupItem {
  hashKey: string; // a
  parentGroupId: HoobiizUserGroupId; // b
  lookupKey: string; // c
  groupProfile?: HoobiizUserProfile; // d
  groupDirectUserCount?: number; // e
  groupNestedUserCount?: number; // f
  groupRegistrationCode?: HoobiizRegistrationCode; // g
  cseDomain?: string; // h
  adminIds?: Set<FrontendUserId>; // j
}

export function parseDoNotUseBoolArr(json: Record<string, unknown>): boolean[] {
  try {
    const parsedDoNotUseBoolArr = asArrayOrThrow(json, {msg: `DoNotUseBoolArr`}).map(
      (element, i) => {
        const parsedElement = asBooleanOrThrow(element, {msg: `DoNotUseBoolArr[${i}]`});
        return parsedElement;
      }
    );
    return parsedDoNotUseBoolArr;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse DoNotUseBoolArr payload: ${errorAsString(
        err
      )}. Payload for DoNotUseBoolArr was ${JSON.stringify(json)}`
    );
  }
}

export interface FooDoNotUsePayload {
  foo: number;
}

export const FooDoNotUsePayloadMetadata = {foo: {type: 'number'}};

export function parseFooDoNotUsePayload(json: Record<string, unknown>): FooDoNotUsePayload {
  try {
    const parsedFooDoNotUsePayloadMap = asMapOrThrow(json, {msg: `FooDoNotUsePayload`});
    const parsedFooDoNotUsePayloadFoo = asNumberOrThrow(parsedFooDoNotUsePayloadMap['foo'], {
      msg: `FooDoNotUsePayload.foo`,
    });
    const parsedFooDoNotUsePayload = {
      foo: parsedFooDoNotUsePayloadFoo,
    };
    return parsedFooDoNotUsePayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export interface BarDoNotUsePayload {
  bar: string;
}

export const BarDoNotUsePayloadMetadata = {bar: {type: 'string'}};

export function parseBarDoNotUsePayload(json: Record<string, unknown>): BarDoNotUsePayload {
  try {
    const parsedBarDoNotUsePayloadMap = asMapOrThrow(json, {msg: `BarDoNotUsePayload`});
    const parsedBarDoNotUsePayloadBar = asStringOrThrow(parsedBarDoNotUsePayloadMap['bar'], {
      msg: `BarDoNotUsePayload.bar`,
    });
    const parsedBarDoNotUsePayload = {
      bar: parsedBarDoNotUsePayloadBar,
    };
    return parsedBarDoNotUsePayload;
  } catch (err: unknown) {
    throw new Error(`Failure to parse payload:
${errorAsString(err)}.
Payload was
${JSON.stringify(json, undefined, 2)}`);
  }
}

export type DoNotUsePayload<T extends DoNotUseType> = T extends DoNotUseType.Foo
  ? FooDoNotUsePayload
  : T extends DoNotUseType.Bar
    ? BarDoNotUsePayload
    : never;

const doNotUsePayloadParsers = {
  [DoNotUseType.Foo]: parseFooDoNotUsePayload,
  [DoNotUseType.Bar]: parseBarDoNotUsePayload,
};

export function parseDoNotUsePayload(
  type: DoNotUseType,
  json: Record<string, unknown>
): DoNotUsePayload<DoNotUseType> {
  return doNotUsePayloadParsers[type](json);
}

export const doNotUsePayloadMetadata = {
  [DoNotUseType.Foo]: FooDoNotUsePayloadMetadata,
  [DoNotUseType.Bar]: BarDoNotUsePayloadMetadata,
};

export interface DoNotUseItemGeneric<T extends DoNotUseType> {
  id: DoNotUseId;
  type: T;
  bool1: boolean;
  string1?: string;
  string2: string;
  number3: number;
  boolArr: boolean[];
  trusted: DoNotUseData<number>;
  payload: DoNotUsePayload<T>;
  numberSet: Set<number>;
  stringSet: Set<string>;
}

export type FooDoNotUseItem = DoNotUseItemGeneric<DoNotUseType.Foo>;
export type BarDoNotUseItem = DoNotUseItemGeneric<DoNotUseType.Bar>;

export type DoNotUseItem = FooDoNotUseItem | BarDoNotUseItem;

export interface DoNotUse2Item {
  id: number; // a
  val?: string; // b
}

export interface DoNotUse3Item {
  id: string; // a
  createdAt: number; // y
  updatedAt: number; // z
}

export type AnyItem =
  | AccountItem
  | RedisItem
  | FrontendUserItem
  | LockItem
  | MaestroDataItem
  | MonitoringItem
  | StreamShardItem
  | DetailedMonitoringItem
  | BuildItem
  | AnalyticsDataItem
  | TaskItem
  | TaskPolicyItem
  | ParameterItem
  | InstanceRequestItem
  | ReportWithFailuresItem
  | DatalakeElementItem
  | DatalakeApiTokenItem
  | DatalakeApiStatsItem
  | DashboardItem
  | ServiceAdArtisanItem
  | ServiceAdCustomerItem
  | ServiceAdRequestItem
  | HoobiizStripeEventItem
  | HoobiizMediaItem
  | HoobiizCat1Item
  | HoobiizCat2Item
  | HoobiizCat3Item
  | HoobiizVendorItem
  | HoobiizActivityItem
  | HoobiizStockEntryItem
  | HoobiizStockItem
  | HoobiizExpertTicketStockItem
  | HoobiizStockWeeklyTemplateItem
  | HoobiizOfferItem
  | HoobiizTicketFileItem
  | HoobiizCartItemItem
  | HoobiizOrderItemFailureItem
  | HoobiizOrderItemItem
  | HoobiizExpertTicketProviderItem
  | HoobiizExpertTicketProductBaseItem
  | HoobiizExpertTicketProductItem
  | HoobiizExpertTicketTicketItem
  | HoobiizExpertTicketPriceItem
  | HoobiizExpertTicketMetadataItem
  | HoobiizUserGroupItem
  | DoNotUseItem
  | DoNotUse2Item
  | DoNotUse3Item;

export type AnyPayload =
  | FrontendUserDataContent
  | AddressLookupResult
  | CurrencyAmount
  | HoobiizDiscount
  | HoobiizTimePeriod
  | HoobiizTimeOfDay
  | HoobiizWeekPeriods
  | HoobiizOpeningHours
  | HoobiizOpeningHoursOverride
  | HoobiizFlexibleStockSchedule
  | HoobiizVendorLocation
  | HoobiizVendorContact
  | HoobiizStockMode
  | HoobiizTicketInfo
  | HoobiizExpertTicketInfo
  | HoobiizTicketInfoOption
  | HoobiizPrices
  | HoobiizPermission
  | HoobiizUserProfile
  | HoobiizWhiteLabeling
  | HoobiizPromoCodeStock
  | HoobiizWhiteLabelingAuth
  | HoobiizActivityAddress
  | HoobiizStockEntryContent
  | HoobiizStockReservation
  | HoobiizOfferTarget
  | HoobiizOfferFlash;

export type DynamoTable =
  | 'Account'
  | 'Redis'
  | 'FrontendUser'
  | 'Lock'
  | 'MaestroData'
  | 'Monitoring'
  | 'StreamShard'
  | 'DetailedMonitoring'
  | 'Build'
  | 'AnalyticsData'
  | 'Task'
  | 'TaskPolicy'
  | 'Parameter'
  | 'InstanceRequest'
  | 'ReportWithFailures'
  | 'DatalakeElement'
  | 'DatalakeApiToken'
  | 'DatalakeApiStats'
  | 'Dashboard'
  | 'ServiceAdArtisan'
  | 'ServiceAdCustomer'
  | 'ServiceAdRequest'
  | 'HoobiizStripeEvent'
  | 'HoobiizMedia'
  | 'HoobiizCat1'
  | 'HoobiizCat2'
  | 'HoobiizCat3'
  | 'HoobiizVendor'
  | 'HoobiizActivity'
  | 'HoobiizStockEntry'
  | 'HoobiizStock'
  | 'HoobiizExpertTicketStock'
  | 'HoobiizStockWeeklyTemplate'
  | 'HoobiizOffer'
  | 'HoobiizTicketFile'
  | 'HoobiizCartItem'
  | 'HoobiizOrderItemFailure'
  | 'HoobiizOrderItem'
  | 'HoobiizExpertTicketProvider'
  | 'HoobiizExpertTicketProductBase'
  | 'HoobiizExpertTicketProduct'
  | 'HoobiizExpertTicketTicket'
  | 'HoobiizExpertTicketPrice'
  | 'HoobiizExpertTicketMetadata'
  | 'HoobiizUserGroup'
  | 'DoNotUse'
  | 'DoNotUse2'
  | 'DoNotUse3';
