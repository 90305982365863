import {FC, MouseEventHandler, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {CivPrefixForm} from '@src/components/civ_prefix_form';
import {CivTile} from '@src/components/civ_tile';
import {CivViewer} from '@src/components/civ_viewer';
import {useDataStore} from '@src/components/store';

interface AppHalfProps {
  currentCiv: string;
  onCivChange: (newCiv: string) => void;
}

export const AppHalf: FC<AppHalfProps> = props => {
  const {currentCiv, onCivChange} = props;
  const civs = useDataStore().civs.useData();
  const civ = useMemo(() => civs.find(c => c.name === currentCiv), [civs, currentCiv]);

  const handleCivClick = useCallback<MouseEventHandler>(
    e => onCivChange(e.currentTarget.getAttribute('data-civ') ?? 'pick'),
    [onCivChange]
  );
  const handleDismiss = useCallback(() => onCivChange('pick'), [onCivChange]);

  const [prefix, setPrefix] = useState('');
  const handlePrefixChange = useCallback(
    (newPrefix: string) => {
      // If by applying the new prefix, we only have one civ left, select it.
      const visibleCivs = civs.filter(c =>
        c.name.toUpperCase().startsWith(newPrefix.toUpperCase())
      );
      const civ = visibleCivs[0];
      if (civ && visibleCivs.length === 1) {
        onCivChange(civ.name);
      }
      // Update the prefix.
      setPrefix(newPrefix);
    },
    [civs, onCivChange]
  );

  const visibleCivs = useMemo(
    () => civs.filter(c => c.name.toUpperCase().startsWith(prefix.toUpperCase())),
    [civs, prefix]
  );

  return civ === undefined ? (
    <Wrapper>
      <CivPrefixForm prefix={prefix} onChange={handlePrefixChange} />
      <CivList>
        {visibleCivs.map(c => (
          <CivTile
            data-civ={c.name}
            key={c.name}
            onClick={handleCivClick}
            label={c.name}
            imgUrl={c.imgUrl}
          />
        ))}
      </CivList>
    </Wrapper>
  ) : (
    <Wrapper>
      <CivViewer civ={civ} onDismiss={handleDismiss} />
    </Wrapper>
  );
};
AppHalf.displayName = 'AppHalf';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3vmin;
  height: 100%;
  width: 0;
  flex-grow: 1;
  flex-basis: 1px;
`;

const CivList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vmin;
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
`;
