import {FC, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {AppHalf} from '@src/components/app_half';
import {UnitsIcon} from '@src/components/units_icon';

export const CivPage: FC = () => {
  const navigate = useNavigate();

  const leftCiv = useParams().firstCiv ?? 'pick';
  const rightCiv = useParams().secondCiv ?? 'pick';

  const handleLeftCivChange = useCallback(
    (newCiv: string) => {
      navigate(`/${newCiv}/${rightCiv}`);
    },
    [navigate, rightCiv]
  );
  const handleRightCivChange = useCallback(
    (newCiv: string) => {
      navigate(`/${leftCiv}/${newCiv}`);
    },
    [navigate, leftCiv]
  );

  return (
    <>
      <MainView>
        <AppHalf currentCiv={leftCiv} onCivChange={handleLeftCivChange} />
        <AppHalf currentCiv={rightCiv} onCivChange={handleRightCivChange} />
      </MainView>
      <UnitsIcon />
    </>
  );
};
CivPage.displayName = 'CivPage';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 8px;
  gap: 8px;
  padding-top: calc(env(safe-area-inset-top) + 8px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
`;
