import {
  FrontendUserAuthMethodType,
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingTheme,
} from '@shared/dynamo_model';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {FrontendThemeNoReact} from '@shared/frontends/frontend_theme_model_no_react';
import {background, borderColor, paddings} from '@shared/frontends/frontend_theme_utils';
import {
  HoobiizWhiteLabelingComputed,
  WhiteLabeling,
} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';
import {DeepPartial, neverHappens} from '@shared/lib/type_utils';

const buttonBase = {
  textColorLoading: 'transparent',
  textDecoration: undefined,
  ...borderColor(undefined),
  borderWidth: 0,
  loaderColor: '#ffffff',
  loaderOpacity: 1,
  loaderSize: 24,
  fontFamily: undefined,
  fontWeight: 500,
  lineHeight: 1.75,
  letterSpacing: undefined,
  textUnderlineOffset: 3,
} as const;

export const baseThemeNoReact: FrontendThemeNoReact = {
  main: {
    api: {},
    backgroundColor: '#f5f7fa',
    accentColor: '#3874ff',
    textColor: '#151823',
    accentTextColor: '#151823',
    fontFamily: 'sans-serif',
    fontSize: 16,
    lineHeight: 1.5,
  },
  auth: {
    postLoginPage: '/',
    userAuthTypes: [FrontendUserAuthMethodType.Password],
    userDataContentType: undefined,
  },
  admin: {
    navWidth: 200,
  },
  button: {
    ...buttonBase,
    textColorActive: '#ffffff',
    textColorDisabled: '#ffffff',
    textColorHover: '#ffffff',
    textDecorationHover: undefined,
    backgroundActive: '#3874ff',
    backgroundDisabled: '#85a8f8',
    backgroundHover: '#004dff',
    backgroundLoading: '#3874ff',
    focusBorderColor: '#ef8b59',
    focusBorderWidth: 2,
    focusTextDecoration: undefined,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    borderRadius: 6,
    fontSize: 15,
    enableSelect: false,
  },
  link: {
    ...buttonBase,
    textColorActive: '#004dff',
    textColorDisabled: '#004dff66',
    textColorLoading: '#004dff66',
    textColorHover: '#004dff',
    textDecorationHover: 'underline',
    ...background('transparent'),
    focusBorderColor: undefined,
    focusBorderWidth: undefined,
    focusTextDecoration: 'underline',
    ...paddings(0),
    borderRadius: undefined,
    fontSize: undefined,
    enableSelect: true,
  },
  checkbox: {
    labelPaddingTop: 8,
    labelPaddingRight: 8,
    labelPaddingBottom: 8,
    labelPaddingLeft: 8,
    labelBorderRadius: 6,
    labelHoverColor: '#ffffff11',
    size: undefined,
    backgroundColor: 'transparent',
    backgroundColorChecked: '#004dff',
    borderColor: '#004dff',
    borderColorChecked: '#004dff',
    borderRadius: 4,
    borderWidth: 2,
    marginRight: 8,
  },
  radio: {
    color: undefined,
    fontSize: 16,
    labelPaddingTop: 8,
    labelPaddingRight: 8,
    labelPaddingBottom: 8,
    labelPaddingLeft: 8,
    labelBorderRadius: 6,
    labelHoverColor: '#ffffff11',
    size: undefined,
    titleMarginBottom: '0.4em',
    inputHeight: undefined,
    alignItems: 'center',
  },
  input: {
    backgroundColor: '#ffffff',
    backgroundColorHover: '#ffffff',
    backgroundColorFocus: '#ffffff',
    backgroundColorDisabled: '#eeeeee',
    borderColor: '#dee0e3',
    textColor: '#141824',
    textColorDisabled: '#292e3d',
    borderRadius: 6,
    fontSize: 12,
    paddingRight: 16,
    paddingLeft: 16,
    height: 32,
    hoverBorderColor: 'transparent',
    focusBorderColor: '#004dff',
    focusOutlineColor: '#3874ff3f',
    focusOutlineWidth: 4,
    focusTextColor: '#141824',
    borderWidth: 1,
    focusBorderWidth: 1,
    fontFamily: undefined,
    fontWeight: 400,
    titleMarginBottom: '0.4em',
    labelOpacity: 0.6,
  },
  textarea: {
    backgroundColor: '#ffffff',
    backgroundColorHover: '#fbfbfb',
    backgroundColorFocus: '#ffffff',
    backgroundColorDisabled: '#eeeeee',
    borderColor: '#dee0e3',
    textColor: '#141824',
    textColorDisabled: '#292e3d',
    borderRadius: 6,
    fontSize: 12,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    hoverBorderColor: 'transparent',
    focusBorderColor: '#004dff',
    focusOutlineColor: '#3874ff3f',
    focusOutlineWidth: 4,
    focusTextColor: '#141824',
    borderWidth: 1,
    focusBorderWidth: 1,
    fontFamily: undefined,
    fontWeight: 400,
    titleMarginBottom: '0.4em',
    labelOpacity: 0.6,
  },
};

export function overwriteThemeWithWhiteLabeling(
  baseTheme: FrontendTheme | FrontendThemeNoReact,
  whiteLabeling?:
    | WhiteLabeling
    | DeepPartial<HoobiizWhiteLabeling>
    | HoobiizWhiteLabeling
    | HoobiizWhiteLabelingComputed
): FrontendTheme {
  // Handle the logo
  let theme = baseTheme as FrontendTheme;
  if (typeof whiteLabeling?.logo === 'object' && 'id' in whiteLabeling.logo) {
    theme = {
      ...theme,
      main: {
        ...theme.main,
        logo: typeof whiteLabeling.logo === 'object' ? whiteLabeling.logo : theme.main.logo,
      },
    };
  }

  if (
    whiteLabeling?.theme === undefined ||
    whiteLabeling.theme === HoobiizWhiteLabelingTheme.Youpiiz
  ) {
    return theme;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (whiteLabeling.theme === HoobiizWhiteLabelingTheme.Cse) {
    const mainColor = whiteLabeling.mainColor ?? theme.main.accentColor;
    const accentTextColor = whiteLabeling.accentTextColor ?? theme.main.accentTextColor;
    const disabledButtonColor =
      whiteLabeling.disabledButtonColor ?? theme.button.backgroundDisabled;
    const outlineColor = whiteLabeling.outlineColor ?? theme.input.focusOutlineColor;
    const linkTextColor = whiteLabeling.linkTextColor ?? theme.link.textColorActive;
    return {
      ...theme,
      main: {
        ...theme.main,
        accentColor: mainColor,
        accentTextColor,
      },
      button: {
        ...theme.button,
        backgroundActive: mainColor,
        backgroundHover: mainColor,
        backgroundDisabled: disabledButtonColor,
        backgroundLoading: disabledButtonColor,
        focusBorderColor: outlineColor,
      },
      link: {
        ...theme.link,
        textColorActive: linkTextColor,
        textColorDisabled: `${linkTextColor}80`,
        textColorHover: linkTextColor,
        textColorLoading: `${linkTextColor}80`,
        loaderColor: mainColor,
      },
      checkbox: {
        ...theme.checkbox,
        borderColor: mainColor,
        backgroundColorChecked: mainColor,
        borderColorChecked: mainColor,
      },
      radio: {
        ...theme.radio,
        color: linkTextColor,
      },
      input: {
        ...theme.input,
        focusOutlineColor: outlineColor,
        hoverBorderColor: mainColor,
        focusBorderColor: mainColor,
      },
      textarea: {
        ...theme.textarea,
        focusOutlineColor: outlineColor,
        hoverBorderColor: mainColor,
        focusBorderColor: mainColor,
      },
    };
  }
  return neverHappens(whiteLabeling.theme);
}
